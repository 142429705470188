import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLocalStorageItem, getRefreshToken, popup, setLocalStorageItem } from '../../../utils';
import { BUTTONS, LABEL, LOCAL_STORAGE } from '../../../constants';
import { setLoaderVisibility, setSessionPopup } from '../../../redux';

export const SessionPopup = () => {
    const sessionPopup = useSelector((state) => state.sessionPopup);
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);

    const handleClose = () => {
        dispatch(setSessionPopup(false));
        dispatch(setLoaderVisibility(true));
        setTimeout(() => {
            localStorage.clear();
            dispatch({ type: 'reset/store' });
            window.location.href = '/';
            dispatch(setLoaderVisibility(false));
        }, 1000);
    };

    const handleSession = () => {
        setIsDisabled(true);
        dispatch(setSessionPopup(true));
        const refresh_token = getLocalStorageItem(LOCAL_STORAGE.REFRESH_TOKEN);
        const user_email = getLocalStorageItem(LOCAL_STORAGE.EMAIL);
        getRefreshToken({ refresh_token, user_email })
            .then((res) => {
                dispatch(setLoaderVisibility(false));
                if (res?.data) {
                    setIsDisabled(false);
                    dispatch(setSessionPopup(false));
                    setLocalStorageItem(LOCAL_STORAGE.AUTH_TOKEN, res.data.auth_token);
                    window.location.reload();
                } else {
                    handleClose();
                }
            })
            .catch((error) => {
                popup('error', error.message);
                setIsDisabled(false);
                dispatch(setLoaderVisibility(false));
            });
    };

    return (
        <div>
            {sessionPopup && (
                <div className='popup-overlay'>
                    <div className='popup'>
                        <div className='popup-content'>{LABEL.SESSION_RESTORE}</div>
                        <div className='popup-footer'>
                            <button
                                className='ssc-primary-green-btn w-50 disabled-hover '
                                onClick={handleSession}
                                disabled={isDisabled}
                            >
                                {BUTTONS.YES}
                            </button>
                            <button className='ssc-secondary-white-btn w-50' onClick={handleClose}>
                                {BUTTONS.NO}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
