import React from 'react';
import PropTypes from 'prop-types';
import { GreenPlus } from '../../../assets/svg';

export default function CardHead({ title,onClickShowAddButton, showAddButton = false }) {
    return (
        <div
            id='CardHead'
            className='card-title-area d-flex w-100 align-items-center justify-content-between'
        >
            <h5>{title}</h5>
            {showAddButton && (
                <button className='ssc-card-btn' onClick={onClickShowAddButton}>
                    <GreenPlus />
                    <span>Add New</span>
                </button>
                
            )}
        </div>
    );
}
CardHead.propTypes = {
    title: PropTypes.string.isRequired,
    onClickShowAddButton: PropTypes.func,
    showAddButton: PropTypes.bool,
};

CardHead.defaultProps = {
    showAddButton: false,
};
