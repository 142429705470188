import * as React from 'react';
const LegendIcon = ({ size, outerFill, innerFill, ...props }) => (
    <svg
        width={size ?? 18}
        height={size ?? 18}
        viewBox='0 0 36 36'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        aria-hidden='true'
        role='img'
        preserveAspectRatio='xMidYMid meet'
        {...props}
    >
        <path
            fill={outerFill ?? '#8993A4'}
            d='M36 32a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v28z'
        />
        <path
            fill={innerFill ?? '#fff'}
            d='M12.792 9.156c0-1.55.992-2.418 2.325-2.418c1.333 0 2.325.868 2.325 2.418V24.72h5.52c1.58 0 2.263 1.179 2.232 2.232c-.061 1.025-.868 2.048-2.231 2.048H15.21c-1.519 0-2.418-.992-2.418-2.543V9.156z'
        />
    </svg>
);
export default LegendIcon;
