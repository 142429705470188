import React, {useState } from 'react';
import PropTypes from 'prop-types';;
import { useNavigate } from 'react-router-dom';

import { SCORECARD_REPORT, BUTTONS, ROUTES, ERROR, SYMBOL } from '../../../constants';
import { Arrow } from '../../../assets/svg';
import { ButtonComponent } from '../../shared';
import { popup } from '../../../utils';

export const SelectPlatformModal = ({openSelectPlatformModal, setOpenSelectPlatformModal, customer}) => {

    //declarations

    const navigate = useNavigate();
    const [platforms, setPlatforms] = useState([]);
    const [openPlatformDropdown, setOpenPlatformDropdown] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState(null);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [isScorecardCreated, setIsScorecardCreated] = useState(false);

    //functions

    const getSelectedCustomerPlatforms = (selectedCustomer) => {
        setPlatforms(selectedCustomer?.tpas);
    }

    const handlePlatformSelect = (platform) => {
        const isScorecardExits = customer.scorecards[0].some(scorecard => scorecard.platform_id === platform.id);
        setIsScorecardCreated(isScorecardExits);
        setOpenPlatformDropdown(false);
        setSelectedPlatform(platform);
    }

    const handlePlatformDropdown = () => {
        getSelectedCustomerPlatforms(customer);
        setOpenPlatformDropdown(prev => !prev);
    }

    const handleGradeSelect = (grade) => {
        setSelectedGrade(grade);
    }

    const handleScorecardButtonClick = () => {
        if (!isScorecardCreated) {
            if (selectedGrade && selectedPlatform?.id) {
                navigate(ROUTES.ADMIN.SCORE_CARD_REPORT_ADMIN, {
                    state : {
                        accountUserDetails: {
                            id: customer.id,
                            name: customer.name,
                            platform_id: selectedPlatform.id,
                            grade: selectedGrade
                        }
                    }
                });
            } else {
                const err_message = !selectedPlatform?.id ? ERROR.SELECT_PLATFORM : ERROR.SELECT_PLATFORM_GRADE;
                popup('error', err_message);
            }
        } else if (isScorecardCreated) {
            if (selectedPlatform?.id) {
                navigate(ROUTES.ADMIN.SCORE_CARD_REPORT_ADMIN, {
                    state : {
                        accountUserDetails: {
                            id: customer.id,
                            name: customer.name,
                            platform_id: selectedPlatform.id
                        }
                    }
                });
            } else {
                popup('error', ERROR.SELECT_PLATFORM);
            }
        }
    }

    // initial state

    return (
        <div
            className={`modal custom-modal-doc-cato fade ${openSelectPlatformModal ? 'show' : ''}`}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!openSelectPlatformModal}
            role='dialog'
            style={{ display: openSelectPlatformModal ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='head-title-edit'>
                            {SCORECARD_REPORT.SCORE_CARD_LISTING_PAGE.SCORECARD}
                        </span>
                        <ButtonComponent clickHandler={() => setOpenSelectPlatformModal(false)} className='btn-close'></ButtonComponent>
                    </div>

                    <React.Fragment>
                        <div className="modal-body p-0 mt-32 mb-32 ssc-scorecard-form">
                            <div className="mb-4 d-flex align-items-start flex-column">
                                <label htmlFor="">{SCORECARD_REPORT.COLUMNS.PLATFORM.NAME}</label>
                                <div className={`wrapper position-relative${openPlatformDropdown ? ' active' : ''}`} id="wrapper">
                                    <div onClick={handlePlatformDropdown} className="select-btn" id="select-btn">
                                        {selectedPlatform ? <span style={{ color: 'var(--col-primary)' }}> {selectedPlatform?.name}<sup>{SYMBOL.REGISTERED}</sup> </span> : <span>{SCORECARD_REPORT.COLUMNS.PLATFORM.PLACEHOLDER} </span>}
                                        <div><Arrow /></div>
                                    </div>
                                    <div className="content position-absolute w-100">
                                        <ul className="options mb-0 mt-0">
                                            {
                                                platforms.length ? 
                                                    (platforms?.map(platform => (
                                                        <li key={platform.id} onClick={() => handlePlatformSelect(platform)}>{platform.name}<sup>{SYMBOL.REGISTERED}</sup></li>
                                                    ))) : 
                                                    <li>{ERROR.NO_PLATFORM}</li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            { selectedPlatform && !isScorecardCreated &&
                                <>
                                    <label htmlFor="">{SCORECARD_REPORT.COLUMNS.PLATFORM_GRADE.NAME}</label>
                                    <div className='d-flex align-items-center justify-content-start gap-5 mb-0 mt-2'>
                                        <button className={`ssc-secondary-white-btn btn-grey-border ${selectedGrade === 'red' ? 'grade-btn-active' : ''}`} onClick={() => handleGradeSelect('red') }>
                                            <div className='dot-circle red me-2'></div>
                                            <div className="label-color font-16px"><span>Red</span></div>
                                        </button>
                                        <button className={`ssc-secondary-white-btn btn-grey-border ${selectedGrade === 'yellow' ? 'grade-btn-active' : ''}`} onClick={() => handleGradeSelect('yellow')}>
                                            <div className='dot-circle yellow me-2'></div>
                                            <div className="label-color font-16px"><span>Yellow</span></div>
                                        </button>
                                        <button className={`ssc-secondary-white-btn btn-grey-border ${selectedGrade === 'green' ? 'grade-btn-active' : ''}`} onClick={() => handleGradeSelect('green')}>
                                            <div className='dot-circle green me-2'></div>
                                            <div className="label-color font-16px"><span>Green</span></div>
                                        </button>
                                    </div>
                                </>
                            }
                        </div>

                        <div className='d-flex align-items-center justify-content-end modal-btn'>
                            <ButtonComponent clickHandler={() => setOpenSelectPlatformModal(false)} className='ssc-secondary-white-btn'>
                                {BUTTONS.CANCEL_BUTTON}
                            </ButtonComponent>

                            <ButtonComponent clickHandler={handleScorecardButtonClick} className='ssc-primary-green-btn py-0 w-auto'>
                                {isScorecardCreated ? BUTTONS.VIEW_SCORECARD : BUTTONS.CREATE_SCORECARD}
                            </ButtonComponent>

                        </div>
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
};

SelectPlatformModal.propTypes = {
    openSelectPlatformModal: PropTypes.bool,
    setOpenSelectPlatformModal: PropTypes.func,
    customer: PropTypes.object
};
