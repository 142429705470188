import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import userImage from '../../../assets/img/icons/user.svg';
import { getProfileImage } from '../../../utils';
export const ImageWithFallback = ({ src, height, width, className, alt, ...props }) => {

    const [previewUrl, setPreviewUrl] = useState(null);

    useEffect(() => {
        if (src) {
            getProfileImage({ profileImg: src }).then((url) => setPreviewUrl(url)).catch(() => setPreviewUrl(null));
        }
    }, [src]);
    return (
        <img
            className={className || ''}
            src={previewUrl || userImage}
            height={height}
            width={width}
            onError={(e) => (e.target.src = userImage)}
            alt={alt || ''}
            {...props}
        />
    );
};

ImageWithFallback.propTypes = {
    src: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    className: PropTypes.string,
    alt: PropTypes.string,
};

ImageWithFallback.defaultProps = {
    src: null,
};
