import { createSlice } from '@reduxjs/toolkit';
import { REDUX } from '../../constants';

const initialState = {
    openFileViewerModal: false,
    signedURL: '',
    fileName: '',
};

const fileViewerModalSlice = createSlice({
    name: REDUX.slice.FILE_VIEWER_MODAL_SLICE,
    initialState,
    reducers: {
        setFileViewModalDetails: (state, action) => {
            state.docs = action.payload.docs;
            state.signedURL = action.payload.signedURL;
            state.openFileViewerModal = action.payload.openFileViewerModal;
            state.fileName = action.payload.fileName;
        },
    },
});

export const { setFileViewModalDetails } = fileViewerModalSlice.actions;
export default fileViewerModalSlice.reducer;
