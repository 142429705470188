// In your accountsArraySlice.js file
import { createSlice } from '@reduxjs/toolkit';
import { REDUX } from '../../constants';

const initialState = {
    navigationState: null,
};

const navigationStateSlice = createSlice({
    name: REDUX.slice.NAVIGATION_STATE,
    initialState,
    reducers: {
        setNavigationState: (state, action) => {
            state.navigationState = action.payload;
        },
    },
});

export const { setNavigationState } = navigationStateSlice.actions;
export default navigationStateSlice.reducer;
