import { API, METHODS } from '../../constants';
import { fetchData } from '../common/apiCalling';
import { constructURL } from '../common';

const getAllPlatforms = (offset, limit, search) => {

    const queryParams = {
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        search: search ? encodeURIComponent(search) : ''
    }

    const url = constructURL(API.PLATFORM, queryParams);

    return fetchData({ method: METHODS.GET, url });
};

const getPlatformById = ({ accountId }) => {
    let url = `${API.PLATFORM}/${accountId}`;
    return fetchData({ method: METHODS.GET, url })
};

const updatePlatform = ({id, data}) => {
    let url = `${API.PLATFORM}/${id}`;
    return fetchData({ body: data, method: METHODS.PATCH, url });
}

export { getAllPlatforms, getPlatformById, updatePlatform};
