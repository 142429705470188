import React from 'react';

export default function Documents({ size, ...props }) {
    return (
        <svg
            width={size || '1rem'}
            height={size || '1rem'}
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M5.98723 2H3C2.44772 2 2 2.44772 2 3V12.5C2 13.6046 2.89543 14.5 4 14.5H11.8421C12.9467 14.5 13.8421 13.6046 13.8421 12.5V5.63158C13.8421 5.07929 13.3944 4.63158 12.8421 4.63158H8.53909C8.16031 4.63158 7.81405 4.41758 7.64466 4.07879L6.88166 2.55279C6.71226 2.214 6.366 2 5.98723 2Z'
                stroke='#738DA6'
                strokeWidth='1.5'
            />
            <path
                d='M7.26318 2.65771H11.8421C12.9467 2.65771 13.8421 3.55315 13.8421 4.65771V6.60508'
                stroke='#738DA6'
                strokeWidth='1.5'
            />
            <path
                d='M4.42102 11H8.36839'
                stroke='#738DA6'
                strokeWidth='1.5'
                strokeLinecap='round'
            />
        </svg>
    );
}
