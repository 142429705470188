/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import PropTypes from 'prop-types';

import { setLoaderVisibility } from '../../../redux';
import { InputBoxWithSearch } from './InputBoxWithSearch';
import { TextArea } from './TextArea';
import { RadioSelector } from './RadioSelector';
import {
    DOCUMENT_REQUEST_FORM,
    DOCUMENT_REQUEST_FORM as DRF,
    MAX_LENGTH,
    SUCCESS,
} from '../../../constants';
import {
    getAllCustomer,
    getCustomerByID,
    getHiringClients,
    createDocumentRequest,
    getAllDocumentCategory,
    validateFiles,
    signedURL,
    popup,
    getFileExtensionFromPath,
    getContentTypeByExtension,
    handleScroll,
} from '../../../utils';
import { FeedbackCross, IconPlus, DocImage } from '../../../assets/svg';
import { useDebounce } from '../../../hooks';
import { isEmpty, debounce } from 'lodash';

export const RequestBoxContainer = ({
    accountDetails,
    submitStatus,
    setSubmitStatus,
    setRes,
    cancelStatus,
    setCancelStatus,
    confirmUpload,
    setOpenConfirmationBox,
    setConfirmUpload,
}) => {
    const scrollBar = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const dispatch = useDispatch();

    // eslint-disable-next-line no-use-before-define
    const [customerData, setCustomerData] = useState([]);
    const [hiringClient, setHiringClient] = useState([]);
    const [documentCategory, setDocumentCategory] = useState([]);
    const [tpa, setTpa] = useState([]);
    const [note, setNote] = useState('');

    // set selected data
    const [selectedCustomer, setSelectedCustomer] = useState(accountDetails);
    const [selectedDocumentCategory, setSelectedDocumentCategory] = useState('');
    const [selectedHiringClient, setSelectedHiringClient] = useState('');
    const [selectedTpa, setSelectedTpa] = useState('');
    const [priority, setPriority] = useState('');
    // set searched key
    const [customerSearch, setCustomerSearch] = useState('');
    const [documentCategorySearch, setDocumentCategorySearch] = useState('');
    const [hiringClientSearch, setHiringClientSearch] = useState('');
    const [tpaSearch, setTpaSearch] = useState('');
    const [openDropdown, setOpenDropdown] = useState(null);
    const [tpaSearchResult, setTpaSearchResult] = useState({});
    const [searchingTPA, setSearchingTPA] = useState(false);
    // scrolling
    const [isScrolling, setIsScrolling] = useState(false);

    const handlePlatformSearch = debounce((e) => {
        const query = e.target.value;
        if (query) {
            setSearchingTPA(true);
            const searchResult = tpa.find((item) =>
                item.name.toLowerCase().includes(query.toLowerCase()),
            );
            console.log({ searchResult, query });
            if (searchResult && !isEmpty(searchResult)) setTpaSearchResult(searchResult);
            else setTpaSearchResult({});
        } else {
            setTpaSearchResult({});
            setSearchingTPA(false);
        }
    }, 300);

    const offset = 1,
        limit = 10;

    // get customer data and recall Api on search
    const debouncedFetchCustomerSearchData = useDebounce(async () => {
        const customers = await getAllCustomer({
            offset: offset,
            limit: limit,
            search: customerSearch || '',
        });
        setCustomerData(customers?.data);
    }, 500);

    const debouncedDocumentCategoryData = useDebounce(async () => {
        const documents = await getAllDocumentCategory({
            offset: offset,
            limit: limit,
            search: documentCategorySearch || '',
        });
        setDocumentCategory(documents?.data);
    }, 500);

    useEffect(() => {
        debouncedDocumentCategoryData();
    }, [documentCategorySearch]);

    useEffect(() => {
        debouncedFetchCustomerSearchData();
    }, [customerSearch]);

    async function fetchCustomerData() {
        setTpa([]);
        const response = await getCustomerByID({
            customerID: selectedCustomer.id,
            fetchTPA: true,
            fetchHiringClient: true,
            fetchCSM: true,
        });
        if (response?.data) {
            const { platforms } = response.data[0];

            setTpa(platforms);
        }
    }

    useEffect(() => {
        if (selectedCustomer) {
            fetchCustomerData();
        }
        setSelectedHiringClient('');
        setSelectedTpa('');
        setHiringClient([]);
        setTpa([]);
    }, [selectedCustomer?.id]);

    const debouncedFetchHiringClientData = useDebounce(async () => {
        setHiringClient([]);
        const hiringClient = await getHiringClients({
            account_id: selectedCustomer.id,
            platform_id: selectedTpa.id,
            search: hiringClientSearch,
        });
        setHiringClient(hiringClient.data);
    }, 500);

    useEffect(() => {
        if (selectedTpa) {
            debouncedFetchHiringClientData();
        }
    }, [selectedTpa, hiringClientSearch]);

    // if user confirm for upload this useEffect will Work
    useEffect(() => {
        if (confirmUpload === 1) {
            handleSubmitWithUpload();
        } else if (confirmUpload === 2) {
            setSelectedFiles([]);
            setFileNames([]);
            setConfirmUpload(0);
            popup('error', DOCUMENT_REQUEST_FORM.FILE.DECLINED);
        }
    }, [confirmUpload]);

    //  Default Handle submit in case Of no upload
    async function handleSubmit() {
        const documentRequestUuid = uuidV4();
        const requestData = {
            id: documentRequestUuid,
            account_id: selectedCustomer.id,
            hiring_client_id: selectedHiringClient.id,
            platform_id: selectedTpa.id,
            document_category_id: selectedDocumentCategory.id,
            priority: priority.toLowerCase(),
            notes: note,
        };
        if (fileNames.length) {
            setOpenConfirmationBox(true);
        } else {
            dispatch(setLoaderVisibility(true));
            const response = await createDocumentRequest({ data: requestData });
            afterSubmission(response);
        }
    }

    // IF Request Has Uploads
    async function handleSubmitWithUpload() {
        dispatch(setLoaderVisibility(true));
        const documentRequestUuid = uuidV4();
        const requestData = {
            id: documentRequestUuid,
            account_id: selectedCustomer.id,
            hiring_client_id: selectedHiringClient.id,
            platform_id: selectedTpa.id,
            document_category_id: selectedDocumentCategory.id,
            priority: priority.toLowerCase(),
            notes: note,
        };
        const filePathURL = [];
        let uploadResponse = [];

        const data = {
            account_id: selectedCustomer.id,
            doc_req_id: documentRequestUuid,
            objects: fileNames,
        };
        const response = await signedURL({ data });
        const urlData = response.data;
        for (let i = 0; i < urlData.length; i++) {
            const binaryData = await readFileAsArrayBuffer(selectedFiles[i]);
            const { preSignedUrl, filePath } = urlData[i];
            uploadResponse = await fetch(preSignedUrl, {
                method: 'PUT',
                body: binaryData,
                headers: {
                    'Content-Type': getContentTypeByExtension(getFileExtensionFromPath(filePath)),
                },
            });
            if (uploadResponse.status === 200) {
                filePathURL.push(filePath);
            } else {
                popup('error', DOCUMENT_REQUEST_FORM.FILE.FILE_UPLOAD_FAILED);
            }
        }
        setFileNames([]);

        const finalResponse = await createDocumentRequest({
            data: { ...requestData, filePathURL },
        });
        afterSubmission(finalResponse);
    }

    // Default afterSubmission to show error or success and neutralize all states
    const afterSubmission = (response) => {
        if (response.status === 'success') {
            dispatch(setLoaderVisibility(false));
            setRes(true);
            setSelectedCustomer('');
            setSelectedDocumentCategory('');
            setSelectedTpa('');
            setSelectedHiringClient('');
            setPriority('');
            setNote('');
            setSelectedFiles([]);
            setSubmitStatus(false);
            setConfirmUpload(0);
        } else {
            popup('error', response.message);
        }
    };
    useEffect(() => {
        if (submitStatus) {
            if (!selectedCustomer || !selectedDocumentCategory || !priority) {
                popup('error', DOCUMENT_REQUEST_FORM.EMPTY_FIELDS);
                setSubmitStatus(false);
                return;
            }
            handleSubmit();
        }
    }, [submitStatus]);

    const readFileAsArrayBuffer = async (file) => {
        try {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            return new Promise((resolve, reject) => {
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const onFileChange = (event) => {
        try {
            const files = event.target.files;
            validateFiles(files, selectedFiles);
            const selectedFilesArray = Array.from(files);
            const selectedFileNames = selectedFilesArray.map((file) => file.name);
            setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...selectedFilesArray]);
            setFileNames((prevFileNames) => [...prevFileNames, ...selectedFileNames]);
        } catch (error) {
            popup('error', error.message);
        }
    };

    // 1.) To dynamically creates the <input> element,
    // 2.) Don't need the input to persist in the DOM but only need its functionality.
    const openFileExplorer = () => {
        if (selectedFiles.length >= DOCUMENT_REQUEST_FORM.FILE.FILE_LIMIT) {
            popup(SUCCESS.SUCCESS_TOAST, DOCUMENT_REQUEST_FORM.FILE.LIMIT_TEXT);
        } else {
            const input = document.createElement('input');
            input.type = 'file';
            input.multiple = true;
            input.onchange = onFileChange;
            input.click();
        }
    };
    const handleRemoveFile = (indexToRemove) => {
        event.preventDefault();
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles.filter((_, index) => index !== indexToRemove),
        );
        setFileNames((prevFileNames) =>
            prevFileNames.filter((_, index) => index !== indexToRemove),
        );
    };

    useEffect(() => {
        if (cancelStatus) {
            dispatch(setLoaderVisibility(false));
            setSelectedCustomer('');
            setSelectedDocumentCategory('');
            setSelectedTpa('');
            setSelectedHiringClient('');
            setPriority('');
            setNote('');
            setSelectedFiles([]);
            setCancelStatus(false);
        }
    }, [cancelStatus]);

    const handleDropdownToggle = (id) => {
        setOpenDropdown((prev) => (prev === id ? null : id));
    };

    useEffect(() => {
        setCustomerSearch('');
    }, [selectedCustomer]);

    const handleScrolling = () => {
        handleScroll(setIsScrolling, scrollBar, 2000);
    };

    return (
        <div className='doc-request-box col-12'>
            <form
                action=''
                onScroll={handleScrolling}
                className={`${isScrolling ? 'scrolling' : 'not-scrolling'}`}
            >
                <div className='row'>
                    <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
                        <InputBoxWithSearch
                            required
                            label={DRF.CUSTOMER.LABEL}
                            placeholder={DRF.CUSTOMER.PLACEHOLDER}
                            dropdownData={customerData}
                            setSearchData={setCustomerSearch}
                            setSelectedData={setSelectedCustomer}
                            selectedData={selectedCustomer}
                            className={'mb-4'}
                            isOpen={openDropdown === 1}
                            onToggle={() => handleDropdownToggle(1)}
                            formattedDropDownData={(data) => {
                                return (
                                    <>
                                        <span>
                                            {`${data.name} - `}
                                            <span className='font-weight-normal'>{`${data.primary_user_first_name ?? 'N.A.'} (${data.primary_user_email ?? 'N.A.'})`}</span>
                                        </span>
                                    </>
                                );
                            }}
                        />
                        <InputBoxWithSearch
                            required
                            label={DRF.DOCUMENT_CATEGORY.LABEL}
                            placeholder={DRF.DOCUMENT_CATEGORY.PLACEHOLDER}
                            dropdownData={documentCategory}
                            setSearchData={setDocumentCategorySearch}
                            setSelectedData={setSelectedDocumentCategory}
                            selectedData={selectedDocumentCategory}
                            className={'mb-4'}
                            isOpen={openDropdown === 2}
                            onToggle={() => handleDropdownToggle(2)}
                        />
                        <TextArea
                            label={DRF.NOTES.LABEL}
                            placeholder={DRF.NOTES.PLACEHOLDER}
                            note={note}
                            setNote={setNote}
                            maxLength={MAX_LENGTH.DOCUMENT_NOTES}
                        />
                        <RadioSelector
                            required
                            label={DRF.RADIO.LABEL}
                            options={DRF.RADIO.OPTIONS}
                            selectedData={priority}
                            setSelectedData={setPriority}
                        />

                        <div className='d-flex align-items-center justify-content-start'>
                            {selectedFiles.length === 0 && (
                                <button
                                    className='ssc-primary-green-btn upload-file-btn'
                                    type='button'
                                    onClick={openFileExplorer}
                                    disabled={
                                        selectedFiles.length ===
                                        DOCUMENT_REQUEST_FORM.FILE.FILE_LIMIT
                                    }
                                >
                                    {DRF.FILE.UPLOAD}
                                </button>
                            )}

                            {selectedFiles.length > 0 && (
                                <div className='d-flex align-items-center file-upload'>
                                    {selectedFiles.map((file, index) => (
                                        <div
                                            key={index}
                                            className='d-flex align-items-start flex-column position-relative'
                                        >
                                            <DocImage />
                                            {file.name.length < 10 ? (
                                                <span>{file.name}</span>
                                            ) : (
                                                <span className='truncate-text'>
                                                    {file.name.slice(0, 6) +
                                                        '...' +
                                                        file.name.split('.')[1]}
                                                </span>
                                            )}
                                            <button
                                                className='remove-file-btn border-0 bg-white position-absolute'
                                                onClick={() => handleRemoveFile(index)}
                                            >
                                                <FeedbackCross />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {selectedFiles.length > 0 && (
                                <button
                                    className='ssc-primary-green-btn add-upload-file-btn'
                                    type='button'
                                    onClick={openFileExplorer}
                                    disabled={selectedFiles.length === 5}
                                >
                                    <IconPlus />
                                </button>
                            )}
                        </div>
                    </div>

                    <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
                        <InputBoxWithSearch
                            label={DRF.PLATFORM.LABEL}
                            placeholder={DRF.PLATFORM.PLACEHOLDER}
                            dropdownData={
                                searchingTPA
                                    ? isEmpty(tpaSearchResult)
                                        ? []
                                        : [tpaSearchResult]
                                    : tpa
                            }
                            setSearchData={setTpaSearch}
                            setSelectedData={setSelectedTpa}
                            selectedData={selectedTpa}
                            className={'mb-4'}
                            isOpen={openDropdown === 3}
                            onToggle={() => {
                                handleDropdownToggle(3);
                                if (openDropdown === 3) {
                                    setTpaSearchResult({}); // Clear search result on close
                                }
                            }}
                            addRegisteredSymbol={true}
                            handleClearAction={() => {
                                setTpaSearchResult({});
                            }}
                            handleSearchAction={handlePlatformSearch}
                        />
                        <InputBoxWithSearch
                            label={DRF.HIRING_CLIENT.LABEL}
                            placeholder={DRF.HIRING_CLIENT.PLACEHOLDER}
                            dropdownData={hiringClient}
                            setSearchData={setHiringClientSearch}
                            setSelectedData={setSelectedHiringClient}
                            selectedData={selectedHiringClient}
                            className={'mb-4'}
                            isOpen={openDropdown === 4}
                            onToggle={() => handleDropdownToggle(4)}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

RequestBoxContainer.propTypes = {
    accountDetails: PropTypes.object,
    submitStatus: PropTypes.bool,
    setSubmitStatus: PropTypes.func,
    setRes: PropTypes.func,
    cancelStatus: PropTypes.bool,
    setCancelStatus: PropTypes.func,
    confirmUpload: PropTypes.number,
    setOpenConfirmationBox: PropTypes.func,
    setConfirmUpload: PropTypes.func,
};
