import * as React from 'react';
const Arrow = ({ width = 16, height = 16 }) => (
    <svg
        width={width}
        height={height}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M4 6L8 10L12 6'
            stroke='#656565'
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
export default Arrow;
