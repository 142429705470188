import React from 'react';
import PropTypes from 'prop-types';
import { Delete } from '../../../assets/svg';

export const DeleteButton = ({ handleClick, width, height }) => {
    return (
        <button onClick={handleClick} className='border-0 p-0 bg-white'>
            <Delete width={width} height={height} />
        </button>
    );
};
Delete.propTypes = {
    handleClick: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
};
