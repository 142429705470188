/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import PropTypes from 'prop-types';
import { CUSTOMER_LIST, DOCUMENTS } from '../../../constants';

export const TableBody = ({
    data,
    columns,
    setTabData,
    setTabOpen,
    pageName,
    debouncedApiCall,
    setDocumentTabOpen,
    selectedRowIndex = null,
    setSelectedRowIndex,
}) => {
    const showPointer = pageName === CUSTOMER_LIST.PAGE_NAME || pageName === DOCUMENTS.PAGE_NAME;
    const handleTabOpen = (data, rowIndex) => {
        if (pageName === CUSTOMER_LIST.PAGE_NAME) {
            setTabData(data.id);
            setTabOpen(true);
            setSelectedRowIndex(rowIndex);
        } else if (pageName === DOCUMENTS.PAGE_NAME) {
            setTabData(data);
            setDocumentTabOpen(true);
            setSelectedRowIndex(rowIndex);
        }
    };
    return (
        <>
            <tbody>
                {data.map((item, index) => {
                    const rowIndex = index + 1;
                    return (
                        <tr
                            className={selectedRowIndex === rowIndex ? 'selected-table-row' : ''}
                            style={{ cursor: showPointer ? 'pointer' : 'default' }}
                            key={item?.id || rowIndex} // Use a stable key based on the item's ID or index
                            onClick={() => handleTabOpen(item, rowIndex)}
                        >
                            {columns.map((column, colIndex) => {
                                if (!column?.hideColumn)
                                    return (
                                        <td key={colIndex} className={column.classNameTD}>
                                            {column?.customFormatter ? (
                                                column.customFormatter(
                                                    column.dataField.reduce((acc, field) => {
                                                        acc[field] = item[field];
                                                        return acc;
                                                    }, {}),
                                                    debouncedApiCall,
                                                )
                                            ) : (
                                                <span key={colIndex}>
                                                    {(column.dataField[0] === 'email'
                                                        ? item[column.dataField[0]]?.toLowerCase()
                                                        : item[column.dataField[0]]) ?? '-'}
                                                </span>
                                            )}
                                        </td>
                                    );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </>
    );
};

TableBody.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    debouncedApiCall: PropTypes.func,
    setTabData: PropTypes.func,
    setTabOpen: PropTypes.func,
    pageName: PropTypes.string,
    selectedRowIndex: PropTypes.number,
    setSelectedRowIndex: PropTypes.func,
};
