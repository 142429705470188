import { API, METHODS } from '../../constants';
import { fetchData } from '../common/apiCalling';
import { constructURL } from '../common';

export const getAllScoreCardEmailLogs = ({
    offset,
    limit,
    search,
    filter,
    account_id,
    created_by,
    status,
}) => {
    const queryParams = {
        account_id: account_id ? account_id : '',
        created_by: created_by ? created_by : '',
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        filter: filter ? filter : '',
        search: search ? encodeURIComponent(search) : '',
        status: status ? status : false,
    };

    const url = constructURL(API.SCORECARD_EMAIL_LOG, queryParams);

    return fetchData({ method: METHODS.GET, url });
};
