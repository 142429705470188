import React from "react";

function Page500() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="218"
      height="288"
      fill="none"
      viewBox="0 0 218 288"
    >
      <path
        fill="#F2F2F2"
        d="M117.429 170.875c47.209 0 85.48-38.251 85.48-85.437S164.638 0 117.429 0c-47.21 0-85.48 38.252-85.48 85.438s38.27 85.437 85.48 85.437z"
      ></path>
      <path
        fill="#4B5564"
        d="M0 20.514c0 31.085 19.511 56.24 43.623 56.24L0 20.513z"
      ></path>
      <path
        fill="#17B1A4"
        d="M43.623 76.754c0-31.435 21.774-56.871 48.682-56.871L43.623 76.754zM15.806 23.332c0 29.528 12.442 53.422 27.817 53.422L15.806 23.332z"
      ></path>
      <path
        fill="#4B5564"
        d="M43.623 76.755c0-40.167 25.167-72.67 56.268-72.67l-56.268 72.67z"
      ></path>
      <path
        fill="#A8A8A8"
        d="M34.446 77.152s6.186-.19 8.05-1.518c1.865-1.327 9.517-2.911 9.98-.783.462 2.128 9.297 10.585 2.312 10.641-6.984.056-16.229-1.087-18.09-2.22-1.86-1.133-2.252-6.12-2.252-6.12z"
      ></path>
      <path
        fill="#032842"
        d="M54.913 84.75c-6.985.056-16.229-1.087-18.09-2.22-1.417-.863-1.982-3.96-2.17-5.387l-.207.008s.392 4.987 2.253 6.12c1.86 1.132 11.105 2.276 18.09 2.22 2.015-.016 2.712-.733 2.674-1.795-.28.641-1.05 1.042-2.55 1.054z"
        opacity="0.2"
      ></path>
      <path
        fill="#3F3D56"
        d="M79.704 180.477c41.447 0 75.046-4.569 75.046-10.204 0-5.636-33.599-10.205-75.046-10.205-41.446 0-75.045 4.569-75.045 10.205 0 5.635 33.599 10.204 75.045 10.204z"
      ></path>
      <path
        fill="#032842"
        d="M79.705 178.842c34.797 0 63.006-3.836 63.006-8.568 0-4.731-28.209-8.567-63.006-8.567-34.798 0-63.007 3.836-63.007 8.567 0 4.732 28.21 8.568 63.007 8.568z"
        opacity="0.1"
      ></path>
      <path
        fill="#3F3D56"
        d="M124.652 268.196c37.679 0 68.224-4.153 68.224-9.277 0-5.123-30.545-9.276-68.224-9.276s-68.223 4.153-68.223 9.276c0 5.124 30.544 9.277 68.223 9.277z"
      ></path>
      <path
        stroke="#4B5564"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M124.251 103.889s52.171-14.44 55.381 32.09c3.211 46.529-42.94 59.766-6.822 68.992M73.846 215.514s15.68-4.341 16.645 9.644c.965 13.985-12.906 17.963-2.05 20.736"
      ></path>
      <path
        fill="#4B5564"
        d="M127.06 63.777H43.586v97.07h83.474v-97.07z"
      ></path>
      <path
        fill="#3F3D56"
        d="M135.488 54.15H35.159v34.496h100.329V54.15zM135.488 95.064H35.159v34.496h100.329V95.064zM135.488 135.979H35.159v34.495h100.329v-34.495z"
      ></path>
      <path
        fill="#17B1A4"
        d="M115.422 60.168h-6.421v6.418h6.421v-6.418z"
        opacity="0.4"
      ></path>
      <path
        fill="#17B1A4"
        d="M124.652 60.168h-6.421v6.418h6.421v-6.418z"
        opacity="0.8"
      ></path>
      <path
        fill="#17B1A4"
        d="M133.882 60.168h-6.421v6.418h6.421v-6.418z"
      ></path>
      <path
        fill="#17B1A4"
        d="M115.422 100.682h-6.421v6.417h6.421v-6.417z"
        opacity="0.4"
      ></path>
      <path
        fill="#17B1A4"
        d="M124.652 100.682h-6.421v6.417h6.421v-6.417z"
        opacity="0.8"
      ></path>
      <path
        fill="#17B1A4"
        d="M133.882 100.682h-6.421v6.417h6.421v-6.417z"
      ></path>
      <path
        fill="#17B1A4"
        d="M115.422 141.191h-6.421v6.418h6.421v-6.418z"
        opacity="0.4"
      ></path>
      <path
        fill="#17B1A4"
        d="M124.652 141.191h-6.421v6.418h6.421v-6.418z"
        opacity="0.8"
      ></path>
      <path
        fill="#17B1A4"
        d="M133.882 141.191h-6.421v6.418h6.421v-6.418z"
      ></path>
      <path
        fill="#4B5564"
        d="M127.06 247.49c17.509 0 31.704-14.188 31.704-31.689 0-17.5-14.195-31.688-31.704-31.688-17.51 0-31.704 14.188-31.704 31.688 0 17.501 14.194 31.689 31.704 31.689z"
      ></path>
      <path
        fill="#4B5564"
        d="M122.244 240.67h-9.632v17.248h9.632V240.67zM141.507 240.67h-9.632v17.248h9.632V240.67z"
      ></path>
      <path
        fill="#4B5564"
        d="M120.639 261.128c4.432 0 8.026-1.347 8.026-3.008 0-1.662-3.594-3.009-8.026-3.009-4.433 0-8.027 1.347-8.027 3.009 0 1.661 3.594 3.008 8.027 3.008zM139.902 260.726c4.433 0 8.026-1.347 8.026-3.009 0-1.661-3.593-3.008-8.026-3.008s-8.027 1.347-8.027 3.008c0 1.662 3.594 3.009 8.027 3.009z"
      ></path>
      <path
        fill="#fff"
        d="M127.863 218.609c5.984 0 10.835-4.848 10.835-10.83 0-5.981-4.851-10.83-10.835-10.83-5.984 0-10.836 4.849-10.836 10.83 0 5.982 4.852 10.83 10.836 10.83z"
      ></path>
      <path
        fill="#3F3D56"
        d="M127.863 211.388a3.61 3.61 0 100-7.22 3.61 3.61 0 100 7.22z"
      ></path>
      <path
        fill="#17B1A4"
        d="M96.306 186.331c-2.56-11.458 5.623-23.037 18.278-25.861 12.654-2.825 24.987 4.175 27.547 15.634 2.56 11.459-5.826 15.685-18.48 18.51-12.655 2.824-24.785 3.176-27.345-8.283z"
      ></path>
      <path
        fill="#4B5564"
        d="M156.795 209.479c8.049-3.443 13.697-8.281 12.615-10.807-1.081-2.525-8.483-1.781-16.531 1.662-8.049 3.443-13.697 8.282-12.615 10.807 1.081 2.526 8.483 1.782 16.531-1.662zM97.4 230.739c8.049-3.443 13.697-8.281 12.615-10.807-1.081-2.525-8.483-1.781-16.531 1.662-8.049 3.443-13.697 8.281-12.615 10.807 1.081 2.525 8.483 1.781 16.531-1.662z"
      ></path>
      <path
        fill="#fff"
        d="M145.52 225.025c0 3.102-7.989 9.226-16.855 9.226-8.865 0-17.256-5.723-17.256-8.824 0-3.102 8.391-2.407 17.256-2.407 8.866 0 16.855-1.096 16.855 2.005z"
      ></path>
    </svg>
  );
}

export default Page500;
