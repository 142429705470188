import { createSlice } from '@reduxjs/toolkit';
import { REDUX } from '../../constants';

const slice = createSlice({
    name: REDUX.slice.SESSION_SLICE,
    initialState: false,
    reducers: {
        setSessionPopup: (state, action) => {
            return (state = action.payload);
        },
    },
});

export const { setSessionPopup } = slice.actions;
export default slice.reducer;
