import React from 'react';
import { OverlayTrigger, Button, ListGroup, Tooltip } from 'react-bootstrap';
import LegendIcon from '../../../assets/svg/LegendIcon';
import { LEGEND_DETAILS } from '../../../constants/strings';

const TPALegend = () => {
    // Tooltip content for the legend
    const renderTooltip = (props) => (
        <Tooltip id='legend-tooltip' {...props} className='main-tooltip'>
            <ListGroup className='tooltip-list-group'>
                {LEGEND_DETAILS.map((lg) => {
                    return (
                        <ListGroup.Item key={lg.id} className='d-flex gap-3 align-items-center'>
                            <div className={`grade-circle ${lg.color}-circle`}></div>
                            <span>{lg.description}</span>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement='bottom'
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
            trigger={'hover'}
        >
            <Button
                variant='link'
                className='text-decoration-none d-flex gap-1 justify-content-center align-items-center '
            >
                <LegendIcon size={18} /> Legend
            </Button>
        </OverlayTrigger>
    );
};

export default TPALegend;
