import React, { useEffect, useState } from 'react';
// import { GoButton } from '../../shared';
import { ROUTES } from '../../../constants';
import { popup } from '../../../utils';
import { HideIcon } from '../../../assets/svg/HideIcon';
import { Documents } from '../../../assets/svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { hideHiringClients } from '../../../utils/apiServices/hideHiringClientApi';
import { ConfirmationBox } from '../../shared';

const HiringClientsActions = ({ id, name, debouncedApiCall }) => {
    const [confirm, setConfirm] = useState(false);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
    const {
        isUserPrimary = false,
        selectedAccount,
        selectedAccountName,
    } = useSelector((s) => s.accountArray);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const hideHiringClient = async () => {
        if (selectedAccount && id && isUserPrimary) {
            try {
                dispatch(setLoaderVisibility(true));
                await hideHiringClients({
                    accountId: selectedAccount,
                    hiringClientIds: id,
                });
                debouncedApiCall();
                setOpenConfirmationBox(false);
                popup(
                    'success',
                    `Hiring client ${name} has been hidden successfully for ${selectedAccountName}.`,
                );
            } catch (error) {
                popup('error', error.message);
            } finally {
                dispatch(setLoaderVisibility(false));
                setConfirm(false);
            }
        } else {
            popup('error', 'You are not a primary user!');
        }
    };

    useEffect(() => {
        if (confirm) {
            hideHiringClient();
        }
    }, [confirm]);

    return (
        <div className='d-flex gap-3 justify-content-center align-items-center'>
            {/* <GoButton hiringClientId={id} navigateTo={ROUTES.CUSTOMER.DOCUMENTS} /> */}
            <span
                id='action-btn'
                title='Go to Document'
                onClick={() => {
                    navigate(ROUTES.CUSTOMER.DOCUMENTS, {
                        state: {
                            hiringClientId: id,
                        },
                    });
                }}
            >
                <Documents />
            </span>
            {isUserPrimary && (
                <span
                    id='action-btn'
                    title='Hide Hiring Client'
                    aria-label='Hide Hiring Client'
                    onClick={() => setOpenConfirmationBox(true)}
                >
                    <HideIcon />
                </span>
            )}
            {openConfirmationBox && (
                <ConfirmationBox
                    setConfirm={setConfirm}
                    openConfirmationBox={openConfirmationBox}
                    setOpenConfirmationBox={setOpenConfirmationBox}
                    customMessage={`Do you want to hide ${name} ?`}
                />
            )}
        </div>
    );
};

export default HiringClientsActions;
