import { API, METHODS } from '../../constants';
import { fetchData } from '../common/apiCalling';

export const getAllNewsFeed = ({ account_id }) => {
    let url = `${API.NEWS_FEED}?account_id=${account_id}`;

    return fetchData({ method: METHODS.GET, url });
};

// to get specific news feed
export const getSpecificNewsFeed = ({ id }) => {
    let url = `${API.NEWS_FEED}/${id}`;
    return fetchData({ method: METHODS.GET, url });
};

// to create news feed
export const createNewsFeed = ({ data }) => {
    let url = `${API.NEWS_FEED}`;
    return fetchData({ method: METHODS.POST, url, body: data });
};

// to update news feed
export const updateNewsFeed = ({ id, data }) => {
    let url = `${API.NEWS_FEED}/${id}`;
    return fetchData({ method: METHODS.PUT, url, body: data });
};

// to delete news feed
export const deleteNewsFeed = ({ id }) => {
    let url = `${API.NEWS_FEED}/${id}`;
    return fetchData({ method: METHODS.DELETE, url });
};

// to update order of news feed
export const updateNewsFeedOrder = ({ data }) => {
    let url = `${API.NEWS_FEED}${API.ORDER}`;
    return fetchData({ method: METHODS.PUT, url, body: { data } });
};
