import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Page500, GoBack } from '../../../assets/svg';
import { Header } from './Header';
import { SERVER_ERROR } from '../../../constants/strings';

export const ServerErrorPage = () => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <main>
            <Header isErrorPage={true} />
            <div className='w-100 h-100 row mx-0 position-relative'>
                <div className='col-lg-12 ssc-main-content-new'>
                    <div className='error-page-box2 h-100 d-flex flex-column align-items-center justify-content-center'>
                        <Page500 />
                        <h2>{SERVER_ERROR.CODE}</h2>
                        <p>{SERVER_ERROR.TITLE}</p>
                        <button className='ssc-secondary-white-btn' onClick={goBack}>
                            <GoBack />
                            <span>{SERVER_ERROR.GO_BACK}</span>
                        </button>
                    </div>
                </div>
            </div>
        </main>
    );
};
