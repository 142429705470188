import React from "react";

function GoBack() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="14"
      fill="none"
      viewBox="0 0 17 14"
    >
      <path
        stroke="#17B1A4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M.75 6.904h15M6.8 12.928L.75 6.904 6.8.879"
      ></path>
    </svg>
  );
}

export default GoBack;
