import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { DownArrow, SearchIcon } from '../../../assets/svg';
import { SYMBOL } from '../../../constants';

export const InputBoxWithSearch = ({
    label,
    required,
    placeholder,
    dropdownData = [],
    setSearchData = () => {},
    setSelectedData = () => {},
    selectedData,
    className,
    isOpen,
    onToggle,
    addRegisteredSymbol = false,
    handleSearchAction,
    handleClearAction,
    formattedDropDownData,
}) => {
    const dropdownRef = useRef(null);
    const ddInputRef = useRef(null);

    const handleDropdown = (e) => {
        e.stopPropagation();
        setTimeout(() => {
            ddInputRef?.current?.focus();
        }, 50);
        onToggle();
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            onToggle();
            setSearchData('');
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);

    const handleChange = (e) => {
        setSearchData(e.target.value?.trim());
    };

    const handleSelect = (data) => {
        setSelectedData(data);
        onToggle();
    };

    const handleClear = (e) => {
        e.stopPropagation();
        setSelectedData('');
        if (handleClearAction) handleClearAction();
    };

    const showDropdownData = (data) => {
        if (formattedDropDownData) return formattedDropDownData(data);
        const name = data.full_name || data.name;
        return (
            <>
                {' '}
                <span>{name}</span> <sup>{addRegisteredSymbol ? SYMBOL.REGISTERED : ''}</sup>
            </>
        );
    };

    return (
        <div className={`${className}`}>
            <span className='d-flex flex-row align-items-center d-flex'>
                {label && <h5>{label}</h5>}
                {required && <span className='text-danger ms-1'>*</span>}
            </span>
            <div ref={dropdownRef} className='wrapper position-relative active' id='wrapper'>
                <div className='select-btn' id='select-btn' onClick={handleDropdown}>
                    <span
                        style={
                            selectedData ? { color: 'var(--col-primary)' } : { color: '#C5D2E7' }
                        }
                    >
                        {selectedData?.name || selectedData?.full_name || placeholder}
                        {selectedData && addRegisteredSymbol && <sup>{SYMBOL.REGISTERED}</sup>}
                    </span>{' '}
                    <div className=''>
                        {selectedData ? (
                            <button
                                type='button'
                                className='btn-close w-25 me-1'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                                onClick={handleClear}
                            />
                        ) : (
                            <DownArrow width={15} height={9} />
                        )}
                    </div>
                </div>
                {isOpen && (
                    <div className='content position-absolute w-100'>
                        <div className='search position-relative'>
                            <SearchIcon className={'search-icon-svg'} width='16' height='16' />
                            <input
                                type='search'
                                name=''
                                placeholder='Search'
                                id=''
                                onChange={handleSearchAction ? handleSearchAction : handleChange}
                                onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
                                ref={ddInputRef}
                            />
                        </div>
                        <ul className='options mb-0'>
                            {dropdownData.length ? (
                                dropdownData.map((data) => {
                                    return (
                                        <li
                                            className='mb-0 height-fit-content'
                                            key={data.id}
                                            onClick={() => handleSelect(data)}
                                        >
                                            {showDropdownData(data)}
                                        </li>
                                    );
                                })
                            ) : (
                                <li>{'No data found'} </li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

InputBoxWithSearch.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    dropdownData: PropTypes.array,
    setSearchData: PropTypes.func,
    setSelectedData: PropTypes.func,
    selectedData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    handleSearchAction: PropTypes.func,
    formattedDropDownData: PropTypes.func,
};
