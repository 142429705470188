import React from 'react';

const Broadcast = ({ color = '#17B1A4', width = 26, height = 21 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 26 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6.741 19.31a11.218 11.218 0 01-5.18-9.46c0-3.473 1.575-6.577 4.052-8.636m13.65 17.806a11.213 11.213 0 004.75-9.17c0-3.595-1.69-6.795-4.318-8.85'
                stroke={color}
                strokeWidth={2}
                strokeLinecap='round'
            />
            <path
                d='M9.332 15.834a6.906 6.906 0 01-3.454-5.985c0-2.063.905-3.915 2.339-5.181m8.455 10.894a6.901 6.901 0 003.023-5.713 6.892 6.892 0 00-2.339-5.181'
                stroke={color}
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <circle cx={12.7862} cy={10.2804} r={2.45421} stroke={color} strokeWidth={2} />
        </svg>
    );
};

export default Broadcast;
