import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ProfileIcon from '../../../assets/img/icons/profile-icon.svg';
import { getProfileImage } from '../../../utils';

const CSMProfileCard = ({ csmName, csmImageLink }) => {
    const [previewUrl, setPreviewUrl] = useState(null);

    /* THE BELOW CSS HAS BEEN APPLIED UNTIL WE GET A PLACEHOLDER IMAGE */
    const custom_style = csmImageLink ? {} : { border: '1px solid var(--col-br-dark)' }; // TODO
    useEffect(() => {
        if (csmImageLink) {
            getProfileImage({ profileImg: csmImageLink })
                .then((url) => setPreviewUrl(url))
                .catch(() => setPreviewUrl(null));
        }
    }, [csmImageLink]);

    return (
        <div className='col-2 custom-col-set'>
            <div className='top-left ssc-shadow h-100 d-flex align-items-center justify-content-center flex-column'>
                <img
                    src={previewUrl || ProfileIcon}
                    alt='csm_image'
                    width={70}
                    loading='lazy'
                    height={70}
                    className='mb-3 img-fluid csm-image-strech'
                    style={custom_style}
                />

                {csmName ? <h5>CSM</h5> : ''}

                <h4 className='mb-0 text-center'>{csmName ? csmName : 'No CSM assigned'}</h4>
            </div>
        </div>
    );
};

CSMProfileCard.propTypes = {
    csmName: PropTypes.string,
    csmImageLink: PropTypes.string,
};

export default CSMProfileCard;
