import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Page404, GoBack } from '../../../assets/svg';
import { Header } from './Header';

export const PageNotFound = () => {
    const navigate = useNavigate();

    function goBack() {
        navigate(-1);
    }

    return (
        <main>
            <Header isErrorPage = {true}/>
            <div className="w-100 h-100 row mx-0 position-relative">
                <div className="col-lg-12 ssc-main-content-new">
                    <div className="error-page-box2 h-100 d-flex flex-column align-items-center justify-content-center">
                        <Page404 />
                        <h2>
                            404
                        </h2>
                        <p>Page Not Found</p>
                        <button onClick={goBack} className="ssc-secondary-white-btn">
                            <GoBack />
                            <span>Go back</span>
                        </button>
                    </div>
                </div>
            </div>
        </main>
    );
}
