import React from 'react';

export default function HiringClients() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='none'
            viewBox='0 0 16 16'
        >
            <path
                stroke='#738DA6'
                strokeWidth='1.5'
                d='M6 13.5c0-2.21 2.015-4 4.5-4s4.5 1.79 4.5 4'
            ></path>
            <path
                stroke='#738DA6'
                strokeWidth='1.5'
                d='M1 13.5c0-2.21 2.015-4 4.5-4 .925 0 1.785.248 2.5.674'
            ></path>
            <circle cx='11' cy='5.5' r='2.25' stroke='#738DA6' strokeWidth='1.5'></circle>
            <circle cx='5.5' cy='6' r='1.25' stroke='#738DA6' strokeWidth='1.5'></circle>
        </svg>
    );
}
