import * as React from 'react';
export const OptionIcon = ({ size, ...props }) => (
    <svg
        height={size || '1rem'}
        width={size || '1rem'}
        stroke='currentColor'
        fill={props.fill ?? 'currentColor'}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 32 32'
        xmlSpace='preserve'
        {...props}
    >
        <path d='M29 6H17c-.6 0-1-.4-1-1s.4-1 1-1h12c.6 0 1 .4 1 1s-.4 1-1 1m-5 4h-7c-.6 0-1-.4-1-1s.4-1 1-1h7c.6 0 1 .4 1 1s-.4 1-1 1m5 12H17c-.6 0-1-.4-1-1s.4-1 1-1h12c.6 0 1 .4 1 1s-.4 1-1 1m-5 4h-7c-.6 0-1-.4-1-1s.4-1 1-1h7c.6 0 1 .4 1 1s-.4 1-1 1M8 30c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6M8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6m0 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2' />
    </svg>
);
