import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header, SideBar } from '../../shared';
import { ContentBox } from '../Common/ContentBox';
import { ROUTES } from '../../../constants';

export const AdminDashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [currentDashboardRoute, setCurrentDashboardRoute] = useState(ROUTES.ADMIN.DASHBOARD);

    useEffect(() => {
        if(location.pathname === ROUTES.ADMIN.DEFAULT) navigate(ROUTES.ADMIN.DASHBOARD)
        setCurrentDashboardRoute(location.pathname);
    }, [location.pathname]);

    return (
        <>
            <Header isAdmin={true} />
            <main>
                <div className='w-100 h-100 row mx-0 position-relative'>
                    <SideBar
                        sidebarFor='admin'
                        currentDashboardRoute={currentDashboardRoute}
                        setCurrentDashboardRoute={setCurrentDashboardRoute}
                    />
                    <ContentBox currentRoute={currentDashboardRoute} />
                </div>
            </main>
        </>
    );
}
