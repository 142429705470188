import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

import { ROUTES, CUSTOMER_DASHBOARD } from '../../../constants';
import { ViewAll } from '../../shared';
import { handleScroll, renderWithLineBreaks } from '../../../utils';
import { CardRightArrow } from '../../../assets/svg';
import WithoutDoc from '../../../assets/img/without-doc.webp';

const CustomerDashboardDocumentRequests = ({ documentRequests, showViewAllButton = true }) => {
    const scrollBar = useRef(null);
    const navigate = useNavigate();
    const [isScrolling, setIsScrolling] = useState(false);

    const handleUploadClick = (requestID) => {
        navigate(ROUTES.CUSTOMER.DOCUMENTS, {
            state: { requestID },
        });
    };

    const handleScrolling = () => {
        handleScroll(setIsScrolling, scrollBar, 2000);
    };

    return (
        <div className='col-6 custom-col-set bottom-right'>
            <div className='h-100 card-container ssc-shadow d-flex flex-column w-100 align-items-center p-lg-4 p-3'>
                <div className='card-title-area d-flex w-100 align-items-center justify-content-between'>
                    <h5>Document Upload Requests</h5>
                </div>
                {documentRequests.length ? (
                    <div
                        onScroll={handleScrolling}
                        className={`content-set-area flex-column d-flex w-100 align-items-start justify-content-start ${isScrolling ? 'scrolling' : 'not-scrolling'}`}
                    >
                        {documentRequests.map((req) => {
                            return (
                                <div
                                    key={req.id}
                                    className='content-set d-flex w-100 align-items-center justify-content-between cursor-pointer'
                                    type='button'
                                >
                                    <div className='d-flex align-items-center '>
                                        <div className='priority-set d-flex align-items-center'>
                                            <div className={`priority ${req.priority}-priority`}>
                                                {capitalize(req.priority)}
                                            </div>
                                            <p className='mb-0 ms-2'>{`Please upload ${req.document_category_name}`}</p>
                                        </div>
                                    </div>
                                    <div
                                        className='d-flex align-items-center'
                                        onClick={() => handleUploadClick(req.id)}
                                    >
                                        <CardRightArrow width={16} height={16} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className='d-flex align-items-center justify-content-center flex-column without-doc'>
                        <img
                            src={WithoutDoc}
                            alt='empty_doc'
                            width='176'
                            height='138'
                            className='img-fluid'
                        />
                        <p className='mb-0 text-center'>
                            {renderWithLineBreaks(CUSTOMER_DASHBOARD.NO_UPLOAD_REQUESTS)}
                        </p>
                    </div>
                )}

                {showViewAllButton && (
                    <div className='view-btn d-flex justify-content-start w-100'>
                        <ViewAll className='ssc-card-btn2' navigateTo={ROUTES.CUSTOMER.DOCUMENTS} />
                    </div>
                )}
            </div>
        </div>
    );
};

CustomerDashboardDocumentRequests.propTypes = {
    documentRequests: PropTypes.array.isRequired,
    showViewAllButton: PropTypes.bool.isRequired,
};

export default CustomerDashboardDocumentRequests;
