import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import loginImage from '../../../assets/img/homepage-img.png';
import { FooterInfoLink } from './FooterInfoLink';
import { LOCAL_STORAGE, ROUTES, SSO } from '../../../constants';
import { decryptSearchString, popup, setLocalStorageItem } from '../../../utils';
import { startCase } from 'lodash';
import { setLoaderVisibility, setUserData } from '../../../redux';
export const Landing = () => {
    const encryptedSearch = window.location.search.slice(1);
    const decryptedSearch = decryptSearchString(encryptedSearch);
    const searchParams = decryptedSearch
        ? new URLSearchParams(decryptedSearch)
        : new URLSearchParams();
    const getCode = searchParams.get(SSO.GET_CODE);
    const token = searchParams.get(SSO.TOKEN);
    const { inProgress, instance } = useMsal();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoaderVisibility(true));
        const fetchData = async () => {
            try {
                if (getCode === 'true' && token && inProgress === SSO.STARTUP) {
                    const firstName = searchParams.get(SSO.FIRST_NAME);
                    const lastName = searchParams.get(SSO.LAST_NAME);
                    const userID = searchParams.get(SSO.USER_ID);
                    const refreshToken = searchParams.get(SSO.REFRESH_TOKEN);
                    const email = searchParams.get(SSO.EMAIL);
                    const role = searchParams.get(SSO.ROLE);
                    const image = searchParams.get(SSO?.PROFILE_IMAGE);
                    if (token || firstName || lastName) {
                        setLocalStorageItem(LOCAL_STORAGE.AUTH_TOKEN, token);
                        setLocalStorageItem(LOCAL_STORAGE.ROLE_CODE, role);
                        setLocalStorageItem(
                            LOCAL_STORAGE.ROLE_NAME,
                            role === 'csm' ? role.toUpperCase() : startCase(role),
                        );
                        setLocalStorageItem(LOCAL_STORAGE.USER_ID, userID);
                        setLocalStorageItem(LOCAL_STORAGE.FIRST_NAME, firstName);
                        setLocalStorageItem(LOCAL_STORAGE.LAST_NAME, lastName);
                        setLocalStorageItem(LOCAL_STORAGE.FULL_NAME, `${firstName} ${lastName}`);
                        setLocalStorageItem(LOCAL_STORAGE.REFRESH_TOKEN, refreshToken);
                        setLocalStorageItem(LOCAL_STORAGE.EMAIL, email);
                    }
                    if (image !== 'null') {
                        dispatch(
                            setUserData({
                                firstName: firstName,
                                lastName: lastName,
                                imagePath: image || null,
                                role: role,
                            }),
                        );
                    } else {
                        dispatch(
                            setUserData({
                                firstName: firstName,
                                lastName: lastName,
                                role: role,
                            }),
                        );
                    }
                    dispatch(setLoaderVisibility(false));
                    navigate(ROUTES.ADMIN.DASHBOARD);
                }
            } catch (error) {
                dispatch(setLoaderVisibility(false));
                popup('SSO ERROR: ', error);
                navigate(ROUTES.ADMIN.LOGIN);
            }
        };
        dispatch(setLoaderVisibility(false));

        fetchData();
    }, [instance, inProgress]);
    return (
        <section className='row mx-0 ssc-login'>
            <div className='col-md-6 px-0 scc-login-left flex-column'>
                <div className='col-xl-8 col-lg-10 col-11 d-flex align-items-center justify-content-center'>
                    <Outlet />
                </div>
                <FooterInfoLink />
            </div>

            <div className='col-md-6 px-0 scc-login-right'>
                <div className='col-lg-10 col-11 d-flex align-items-center justify-content-center'>
                    <img src={loginImage} alt='img' className='img-fluid' />
                </div>
            </div>
        </section>
    );
};
