import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AdminPageHeader } from '../../Admin/Common/AdminPagesHeader';
import { setLoaderVisibility } from '../../../redux';
import { HIRING_CLIENT, HIRING_CLIENT_COLUMNS, LOCAL_STORAGE } from '../../../constants';
import { getHiringClients, getLocalStorageItem, popup } from '../../../utils';
import { usePagination, useDebounce } from '../../../hooks';
import { Table } from '../../shared';
import { useSelector } from 'react-redux';
import { OptionIcon } from '../../../assets/svg/OptionIcon';
import UnhideHiringClientModal from '../../shared/Modal/UnhideHiringClientModal';

export const HiringClientListing = () => {
    const { state, pathname } = useLocation();
    const dispatch = useDispatch();
    const { isUserPrimary = false, selectedAccount } = useSelector((s) => s.accountArray);

    const [response, setResponse] = useState({ data: [], count: 0 });
    const [search, setSearch] = useState('');
    const [showHCModal, setShowHCModal] = useState(false);
    const [redirected, setRedirected] = useState(state?.id ? true : false);
    const accountId = getLocalStorageItem(LOCAL_STORAGE.CUSTOMER_ID);

    const pagination = usePagination(response.data.length, response.count);

    const debouncedApiCall = useDebounce(() => {
        getHiringClients({
            account_id: accountId,
            offset: pagination.offset,
            limit: pagination.limit,
            search,
        })
            .then((res) => {
                if (res?.data) {
                    if (redirected) {
                        const hc = res.data.filter((hc) => hc.id === state.id);
                        setResponse({ data: hc, count: hc.length });
                    } else setResponse({ data: res.data, count: res.count });
                }
                dispatch(setLoaderVisibility(false));
            })
            .catch((error) => {
                popup('error', error.message);
                dispatch(setLoaderVisibility(false));
            });
    }, 500);

    useEffect(() => {
        if (accountId) {
            !search && dispatch(setLoaderVisibility(true));
            debouncedApiCall();
        }
    }, [pagination.limit, pagination.offset, search, accountId]);

    useEffect(() => {
        setRedirected(false);
    }, [search]);

    return (
        <>
            <AdminPageHeader
                heading={HIRING_CLIENT.PAGE_HEADING}
                buttonText={HIRING_CLIENT.BUTTON_TEXT}
                searchBarPlaceholder={HIRING_CLIENT.SEARCH_BAR_PLACEHOLDER}
                setSearch={setSearch}
                count={pagination.dataCount}
                setCurrentPage={pagination.setCurrentPage}
                backbutton
            />
            {isUserPrimary && pathname === '/customer/hiring_clients' && (
                <span
                    id='action-btn'
                    title='Unhide Hiring Clients'
                    aria-label='Unhide Hiring Clients'
                    onClick={() => setShowHCModal(true)}
                    className={`position-absolute end-0 top-0`}
                    style={{
                        marginTop: `${pagination.dataCount > 0 ? '6rem' : '4rem'}`,
                        marginRight: '2rem',
                    }}
                >
                    <OptionIcon />
                </span>
            )}
            <Table
                columns={HIRING_CLIENT_COLUMNS}
                data={response.data}
                pagination={pagination}
                pageName={HIRING_CLIENT.PAGE_HEADING}
                debouncedApiCall={debouncedApiCall}
                name={'hhc'}
            />
            {showHCModal && (
                <UnhideHiringClientModal
                    showModal={showHCModal}
                    setShowModal={setShowHCModal}
                    accountId={selectedAccount}
                    debouncedApiCall={debouncedApiCall}
                />
            )}
        </>
    );
};
