import React, { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { formValidator, createCustomer, popup } from '../../../utils';
import { AUTH, REGEX, VALIDATION, MAX_LENGTH, ROLES, ROUTES, HEADERS } from '../../../constants';
import { IconPlus } from '../../../assets/svg';

export const CreateUser = ({ setAddNewUser, setNewUser }) => {
    const [isFormFocused, setIsFormFocused] = useState(false);
    const dispatch = useDispatch();

    const validationSchema = {
        first_name: {
            regex: REGEX.NAME,
            message: VALIDATION.INVALID_FIRST_NAME,
            requiredMessage: VALIDATION.FIRST_NAME_REQUIRED,
        },
        last_name: {
            regex: REGEX.NAME,
            message: VALIDATION.INVALID_LAST_NAME,
            requiredMessage: VALIDATION.LAST_NAME_REQUIRED,
        },
        phone: {
            regex: REGEX.PHONE,
            message: VALIDATION.INVALID_PHONE,
            isNotRequired: true,
        },
        email: {
            regex: REGEX.EMAIL,
            message: VALIDATION.INVALID_EMAIL,
            requiredMessage: VALIDATION.EMAIL_REQUIRED,
        },
    };

    const validateForm = () => setIsFormFocused(true);

    const debouncedApiCall = useDebounce(async (values, setSubmitting) => {
        const userId = uuidV4();
        setSubmitting(true);
        try {
            const response = await createCustomer({
                data: {
                    id: userId,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    ...(values.phone && { phone: values.phone }),
                    role_type: ROLES.USER,
                },
                headers: {
                    [HEADERS.X_FRONTEND_URL]: process.env.REACT_APP_CUSTOMER_PORTAL_LINK,
                    [HEADERS.X_RESET_PASSWORD_ROUTE]: ROUTES.CUSTOMER.RESET_PASSWORD,
                },
            });

            dispatch(setLoaderVisibility(false));
            setSubmitting(false);
            setNewUser((prev) => !prev);
            setAddNewUser({
                id: userId,
                first_name: values.first_name,
                last_name: values.last_name,
                phone: values.phone,
                email: values.email,
                full_name: `${values.first_name} ${values.last_name}`,
            });
            popup('success', response.message);
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            setSubmitting(false);
            popup('error', error.message);
        }
    }, 500);

    return (
        <div className='user-create-form'>
            <div className='text-end w-100'>
                <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => setNewUser((prev) => !prev)}
                />
            </div>

            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: '',
                }}
                validate={(values) => {
                    const errors = {};
                    formValidator({ values, errors, validationSchema });
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    dispatch(setLoaderVisibility(true));
                    debouncedApiCall(values, setSubmitting);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setTouched,
                }) => {
                    const isFormValid =
                        values.first_name &&
                        values.last_name &&
                        values.email &&
                        !errors.first_name &&
                        !errors.last_name &&
                        !errors.email;

                    return (
                        <form
                            onSubmit={handleSubmit}
                            className='d-flex justify-content-between mb-2 flex-column'
                        >
                            <div
                                onFocus={() => validateForm()}
                                className='d-flex justify-content-between align-items-center'
                            >
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='mb-1 input-set w-100'>
                                                <label htmlFor='name'>{AUTH.FIRST_NAME}</label>
                                                <input
                                                    maxLength={MAX_LENGTH.NAME}
                                                    type='text'
                                                    name='first_name'
                                                    onChange={(e) => {
                                                        if (!touched.first_name) {
                                                            setTouched({
                                                                ...touched,
                                                                first_name: true,
                                                            });
                                                        }
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.first_name}
                                                    className={`new-customer-input input-area-set form-control ${
                                                        isFormFocused &&
                                                        errors.first_name &&
                                                        touched.first_name
                                                            ? 'border border-danger-subtle border-1'
                                                            : ''
                                                    }`}
                                                    placeholder={AUTH.ENTER_FIRST_NAME}
                                                />
                                                {isFormFocused &&
                                                    touched.first_name &&
                                                    errors.first_name && (
                                                        <p className='error-message'>
                                                            {errors.first_name}
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='mb-1 input-set w-100'>
                                                <label htmlFor='name'>{AUTH.LAST_NAME}</label>
                                                <input
                                                    maxLength={MAX_LENGTH.NAME}
                                                    type='text'
                                                    name='last_name'
                                                    onChange={(e) => {
                                                        if (!touched.last_name) {
                                                            setTouched({
                                                                ...touched,
                                                                last_name: true,
                                                            });
                                                        }
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.last_name}
                                                    className={`new-customer-input input-area-set form-control ${
                                                        isFormFocused &&
                                                        errors.last_name &&
                                                        touched.last_name
                                                            ? 'border border-danger-subtle border-1'
                                                            : ''
                                                    }`}
                                                    placeholder={AUTH.ENTER_LAST_NAME}
                                                />
                                                {isFormFocused &&
                                                    touched.last_name &&
                                                    errors.last_name && (
                                                        <p className='error-message'>
                                                            {errors.last_name}
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='mb-1 input-set w-100'>
                                                <label htmlFor='emailAddress'>
                                                    {AUTH.EMAIL_ADDRESS}
                                                </label>
                                                <input
                                                    maxLength={MAX_LENGTH.EMAIL}
                                                    type='email'
                                                    name='email'
                                                    onChange={(e) => {
                                                        if (!touched.email) {
                                                            setTouched({
                                                                ...touched,
                                                                email: true,
                                                            });
                                                        }
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                    className={`new-customer-input input-area-set form-control ${
                                                        isFormFocused &&
                                                        errors.email &&
                                                        touched.email
                                                            ? 'border border-danger-subtle border-1'
                                                            : ''
                                                    }`}
                                                    placeholder={AUTH.ENTER_EMAIL_ADDRESS}
                                                />
                                                {isFormFocused && touched.email && errors.email && (
                                                    <p className='error-message'>{errors.email}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='mb-1 input-set w-100'>
                                                <label htmlFor='phoneNumber'>
                                                    {AUTH.PHONE_NUMBER}
                                                </label>
                                                <input
                                                    maxLength={MAX_LENGTH.PHONE}
                                                    type='text'
                                                    name='phone'
                                                    onChange={(e) => {
                                                        if (!touched.phone) {
                                                            setTouched({
                                                                ...touched,
                                                                phone: true,
                                                            });
                                                        }
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.phone}
                                                    className={`new-customer-input input-area-set form-control ${
                                                        isFormFocused &&
                                                        errors.phone &&
                                                        touched.phone
                                                            ? 'border border-danger-subtle border-1'
                                                            : ''
                                                    }`}
                                                    placeholder={AUTH.ENTER_PHONE}
                                                />
                                                {isFormFocused && touched.phone && errors.phone && (
                                                    <p className='error-message'>{errors.phone}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-end mb-1'>
                                    <button
                                        type='submit'
                                        disabled={isSubmitting || !isFormValid}
                                        className='ssc-primary-green-btn plus-icon-edit align-item-center'
                                    >
                                        <IconPlus />
                                    </button>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

CreateUser.propTypes = {
    setAddNewUser: PropTypes.func,
    setNewUser: PropTypes.func,
};
