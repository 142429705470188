import React, { useMemo } from 'react';
import { Edit, ViewIcon } from '../../../assets/svg';
import { ROLES, ROUTES } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const CsmProfile = ({ id }) => {
    const role = useSelector((s) => s.user.role);
    const isEdit = useMemo(() => role === ROLES.ADMIN, [role]);
    const navigate = useNavigate();
    const handleEditClick = () => {
        navigate(ROUTES.ADMIN.EDIT_PROFILE, {
            state: {
                userId: id,
            },
        });
    };

    return (
        <div className='d-flex align-items-center justify-content-center options-set'>
            <button
                className={`border-0 p-0 bg-white`}
                onClick={() => handleEditClick()}
                id='action-btn'
                title={isEdit ? 'Edit' : 'View'}
            >
                {isEdit ? <Edit /> : <ViewIcon />}
            </button>
        </div>
    );
};
