import { OVERVIEW, ROLE_NAME, ROLES } from './strings';

export const REQUEST_STATUS = [
    { id: '1', name: 'Approved', value: 'approved' },
    { id: '2', name: 'Pending', value: 'pending' },
    { id: '3', name: 'Requested', value: 'requested' },
    { id: '4', name: 'Rejected', value: 'rejected' },
];

export const registrationAndDocumentDropdownOptions = [
    { id: '1', value: 30, name: OVERVIEW.DAYS_30 },
    { id: '2', value: 60, name: OVERVIEW.DAYS_60 },
    { id: '3', value: 90, name: OVERVIEW.DAYS_90 },
    { id: '4', value: 120, name: OVERVIEW.DAYS_120 },
];

export const currentRoleOptions = [
    { id: 1, value: ROLES.ADMIN, name: ROLE_NAME.ADMIN },
    { id: 2, value: ROLES.CONTENT_MANAGER, name: ROLE_NAME.CONTENT_MANAGER },
    { id: 3, value: ROLES.CSM, name: ROLE_NAME.CSM },
];
