import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    getAllPrimaryUser,
    updateUser,
    createAccountUser,
    deleteAccountUser,
    popup,
} from '../../../utils';
import { BUTTONS, INFO, SUCCESS, CUSTOMER_LIST } from '../../../constants';
import { SearchIcon, DownArrow } from '../../../assets/svg';

export const UserEditModal = ({ userData, setData, setOpenEditModal, setShowSideIcon }) => {
    const [options, setOptions] = useState([]);
    const [openDropDown, setOpenDropDown] = useState(false);
    const [primaryUser, setPrimaryUser] = useState({
        id: userData.primary_user_id,
        name: userData.primary_user_full_name,
    });
    const [searchData, setSearchData] = useState('');
    const offset = 1;
    const limit = 10;
    useEffect(() => {
        const fetchPrimaryUser = async () => {
            getAllPrimaryUser({
                offset: offset,
                limit: limit,
                search: searchData,
            })
                .then((res) => {
                    if (res?.data) {
                        setOptions(res.data);
                    }
                })
                .catch((error) => {
                    popup('error', error.message);
                });
        };
        fetchPrimaryUser();
    }, [userData.id, searchData]);

    const handleDropdown = () => {
        setOpenDropDown((prev) => !prev);
    };
    const handleChange = (e) => {
        setSearchData(e.target.value);
    };

    const handleSelect = ({ users }) => {
        setPrimaryUser({ id: users.id, name: users.full_name });
        setOpenDropDown(false);
    };
    const handleSave = () => {
        if (!userData.primary_user_id) {
            if (primaryUser.id) {
                createAccountUser({ account_id: userData.id, user_id: primaryUser.id })
                    .then((res) => {
                        if (res?.status === 'success') {
                            setData((prev) => ({
                                ...prev,
                                primary_user_id: primaryUser.id,
                                primary_user_full_name: primaryUser.name,
                            }));
                            setOpenEditModal(false);
                            popup(SUCCESS.SUCCESS_TOAST, INFO.PRIMARY_USER_CREATED);
                            setShowSideIcon(true);
                        }

                    })
                    .catch((error) => {
                        popup('error', error.message);
                    });
            } else {
                popup(popup('error', INFO.SELECTED_NONE));
            }
        } else if (primaryUser.id !== userData.primary_user_id) {
            updateUser({
                accountId: userData.id,
                userId: userData.primary_user_id,
                data: { newUser_id: primaryUser.id },
            })
                .then((res) => {
                    if (res?.status === 'success') {
                        setData((prev) => ({
                            ...prev,
                            primary_user_id: primaryUser.id,
                            primary_user_full_name: primaryUser.name,
                        }));
                        setOpenEditModal(false);
                        popup(SUCCESS.SUCCESS_TOAST, INFO.PRIMARY_USER_UPDATED);
                        setShowSideIcon(true);
                    }

                })
                .catch((error) => {
                    popup('error', error.message);
                });
        } else {
            popup('error', INFO.SELECTED_SAME_USER);
        }
    };
    const handleDelete = () => {
        if (userData.primary_user_id) {
            deleteAccountUser({ account_id: userData.id, user_id: primaryUser.id })
                .then((res) => {
                    if (res?.status === 'success') {
                        setData((prev) => ({
                            ...prev,
                            primary_user_id: null,
                            primary_user_full_name: null,
                        }));
                        setOpenEditModal(false);
                        popup(SUCCESS.SUCCESS_TOAST, INFO.PRIMARY_USER_REMOVED);
                        setShowSideIcon(true);
                    }
                })
                .catch((error) => {
                    popup('error', error.message);
                });
        } else {
            popup('error', INFO.NOTHING_TO_DELETE);
        }
    };

    return (
        <div
            className={`modal custom-modal-doc-cato fade show `}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={true}
            role='dialog'
            style={{ display: 'block' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='head-title-edit'>{BUTTONS.EDIT_PRIMARY_USER}</span>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={() => { setOpenEditModal(false), setShowSideIcon(true) }}
                        />
                    </div>
                    <div className='mb-4 customer-primary-edit mt-3' >
                        <div className={`wrapper position-relative ${openDropDown ? 'active' : ''}`} id="wrapper">
                            <div onClick={handleDropdown} className="select-btn" id="select-btn">
                            <span className={primaryUser.name ? 'text-color' : 'placeholder-color'}
                            >{primaryUser.name ? primaryUser.name : CUSTOMER_LIST.SELECT_USER}</span>
                                <DownArrow width='15' height='9' />
                            </div>
                            <div className='content position-absolute w-100'>
                                <div className='search position-relative'>
                                    <SearchIcon
                                        className={'search-icon-svg'}
                                        width='16'
                                        height='16'
                                    />
                                    <input
                                        type='search'
                                        name=''
                                        placeholder='Search Primary User'
                                        id=''
                                        value={searchData}
                                        onChange={handleChange}
                                    />
                                </div>
                                <ul className='options '>
                                    {options.map((users) => {
                                        return (
                                            <li
                                                key={users.id}
                                                value={users.id}
                                                onClick={() => handleSelect({ users })}
                                            >
                                                {users.full_name}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end modal-btn'>
                        {((userData.primary_user_id && primaryUser.id) &&
                            userData.primary_user_id === primaryUser.id) && (
                                <button
                                    type='button'
                                    className='ssc-secondary-white-btn'
                                    onClick={() => handleDelete()}
                                >
                                    {BUTTONS.DELETE_BUTTON}
                                </button>
                            )}
                        <button
                            type='button'
                            className='ssc-primary-green-btn py-0 w-auto'
                            onClick={() => handleSave(primaryUser)}
                        >
                            {BUTTONS.SAVE_BUTTON}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

UserEditModal.propTypes = {
    userData: PropTypes.object,
    setOpenEditModal: PropTypes.func,
    setData: PropTypes.func,
};
