import React from 'react';
import { DOCUMENT_REQUEST_FORM } from '../../../constants';

export default function DocumentRequestSubmit({
    setConfirmUpload,
    openConfirmationBox,
    setOpenConfirmationBox,
    setSubmitStatus
}) {
 
    return (
        <div
            className={`modal custom-modal-doc-cato fade ${openConfirmationBox ? 'show' : ''}`}
            id='exampleModal'
            aria-hidden={!openConfirmationBox}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            style={{ display: openConfirmationBox ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content p-3 '>
                    <div className='d-flex m-1'>
                        <div className='w-25 modal-body py-0 d-flex justify-content-center align-items-center'>
                            <span className='d-flex justify-content-center align-items-center w-75 h-50 bg-warning badge height-60px'>
                                !
                            </span>
                        </div>
                        <div className='w-75 modal-body py-0'>
                            <h4 className='text-center  text-special '>
                                {DOCUMENT_REQUEST_FORM.FILE.CONFIRM_UPLOAD_1}
                                <br />
                            </h4>
                            <p className='text-center text-special placeholder-color '>
                                {DOCUMENT_REQUEST_FORM.FILE.CONFIRM_UPLOAD_2}
                                <br />
                            </p>
                        </div>
                    </div>
                    <hr />
                    <div className='pb-3 mt-2  d-flex align-items-center justify-content-center mx-5  modal-btn custom-button'>
                        <button
                            type='button'
                            className='ssc-primary-green-btn col py-0 '
                            ref={(button) => button && button.focus()}
                            onClick={() => {
                                setConfirmUpload(1);
                                setOpenConfirmationBox(false);
                            }}
                        >
                            {DOCUMENT_REQUEST_FORM.FILE.CONFIRM}
                        </button>
                        <button
                            type='button'
                            className='ssc-secondary-white-btn col py-0'
                            onClick={() => {
                                setConfirmUpload(2);
                                setOpenConfirmationBox(false);
                                setSubmitStatus(false);
                            }}
                        >
                            {DOCUMENT_REQUEST_FORM.FILE.DECLINE}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
