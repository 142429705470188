import * as React from 'react';
const Moon = ({ size, stroke, fill, ...props }) => (
    <svg
        width={size ?? 18}
        height={size ?? 18}
        stroke={stroke || 'currentColor'}
        fill={fill || 'currentColor'}
        strokeWidth={0}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path fill='none' d='M0 0h24v24H0z' />
        <path d='M12 3a9 9 0 1 0 9 9c0-.46-.04-.92-.1-1.36a5.389 5.389 0 0 1-4.4 2.26 5.403 5.403 0 0 1-3.14-9.8c-.44-.06-.9-.1-1.36-.1z' />
    </svg>
);
export default Moon;
