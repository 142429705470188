/* eslint-disable no-undef */
import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MS_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MS_TENANT_ID}`, // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: process.env.REACT_APP_MS_REDIRECT_URL, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

export const protectedResources = {
    graphMe: {
        endpoint: 'https://graph.microsoft.com/v1.0/me',
        scopes: ['User.Read'],
    },
};
