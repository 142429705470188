import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { LOCAL_STORAGE, ROLES, ROLE_NAME, ROUTES } from '../../../constants';
import { Header, SideBar, SubscriptionExpiryPopup } from '../../shared';
import ContentBox from './ContentBox';
import {
    setAccounts,
    setSelectedAccountName,
    setSelectedAccounts,
    setUserData,
} from '../../../redux';
import { getLocalStorageItem, getUserById, setLocalStorageItem } from '../../../utils';

export const CustomerDashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const isImpersonated = getLocalStorageItem(LOCAL_STORAGE.IS_IMPERSONATING);

    const { accounts, selectedAccount: selectedAccountInDropDown } = useSelector(
        (state) => state.accountArray,
    );
    const [currentDashboardRoute, setCurrentDashboardRoute] = useState(ROUTES.CUSTOMER.DASHBOARD);
    const [selectedAccount, setSelectedAccount] = useState(selectedAccountInDropDown);

    const handleNewAccountSelection = (accountArray, selectedAccountId) => {
        if (selectedAccountId) {
            const previouslySelectedAccount = accountArray.find(
                (account) => account.id === selectedAccountId,
            );
            if (!previouslySelectedAccount) setSelectedAccount(accountArray[0].id);
            else {
                const isUserPrimary = previouslySelectedAccount.is_primary_user;
                setLocalStorageItem(
                    LOCAL_STORAGE.ROLE_NAME,
                    isUserPrimary ? ROLE_NAME.PRIMARY_USER : ROLE_NAME.SECONDARY_USER,
                );
                setLocalStorageItem(
                    LOCAL_STORAGE.ROLE_CODE,
                    isUserPrimary ? ROLES.PRIMARY_USER : ROLES.SECONDARY_USER,
                );
                dispatch(setSelectedAccountName(selectedAccountId));
            }
        } else {
            setSelectedAccount(accountArray[0].id);
        }
    };

    const fetchUserDetails = async () => {
        const userID = getLocalStorageItem(LOCAL_STORAGE.USER_ID);
        const response = await getUserById({ id: userID });
        if (response.data && response.data.accounts.length > 0) {
            dispatch(setAccounts(response.data.accounts));
            setLocalStorageItem(LOCAL_STORAGE.FULL_NAME, response.data.full_name);
            setLocalStorageItem(LOCAL_STORAGE.EMAIL, response.data.email);
            setLocalStorageItem(LOCAL_STORAGE.ACCOUNT_ID, response.data.accounts[0]?.id);
            dispatch(
                setUserData({
                    firstName: response?.data?.first_name,
                    lastName: response?.data?.last_name,
                    imagePath: response?.data?.image || null,
                    role: response.data?.role_code,
                }),
            );
            dispatch;
            handleNewAccountSelection(response.data.accounts, selectedAccountInDropDown);
        }
    };

    useEffect(() => {
        if (searchParams.get(LOCAL_STORAGE.IMPERSONATE_TOKEN)) {
            setLocalStorageItem(
                LOCAL_STORAGE.AUTH_TOKEN,
                searchParams.get(LOCAL_STORAGE.IMPERSONATE_TOKEN),
            );
            setLocalStorageItem(
                LOCAL_STORAGE.REFRESH_TOKEN,
                searchParams.get(LOCAL_STORAGE.REFRESH_TOKEN),
            );
            setLocalStorageItem(LOCAL_STORAGE.USER_ID, searchParams.get(LOCAL_STORAGE.USER_ID));
            setLocalStorageItem(LOCAL_STORAGE.IS_IMPERSONATING, true);
            setSearchParams({});
        }
    }, [searchParams]);

    useEffect(() => {
        fetchUserDetails();
    }, []);

    useEffect(() => {
        if (!isEmpty(selectedAccount)) dispatch(setSelectedAccounts(selectedAccount));
        else if (accounts.length > 0) setSelectedAccount(accounts[0].id);
    }, [selectedAccount]);

    useEffect(() => {
        if (location.pathname === ROUTES.CUSTOMER.DEFAULT) navigate(ROUTES.CUSTOMER.DASHBOARD);
        setCurrentDashboardRoute(location.pathname);
    }, [location.pathname]);
    return (
        <>
            <Header
                isAdmin={false}
                accounts={accounts}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                showCustomerDropdown={true}
            />
            <SubscriptionExpiryPopup />
            <main className={`${isImpersonated && 'isImpersonated'}`}>
                <div className='w-100 h-100 row mx-0 position-relative'>
                    <SideBar
                        sidebarFor='customer'
                        currentDashboardRoute={currentDashboardRoute}
                        setCurrentDashboardRoute={setCurrentDashboardRoute}
                    />
                    <ContentBox currentRoute={currentDashboardRoute} />
                </div>
            </main>
        </>
    );
};
