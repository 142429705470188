import * as React from 'react';
const ProfileIcon = ({ width = 25, height = 24, className }) => (
    <svg
        width={width}
        height={height}
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.4847 15.3457C8.61707 15.3457 5.31421 15.9305 5.31421 18.2724C5.31421 20.6143 8.59611 21.22 12.4847 21.22C16.3523 21.22 19.6542 20.6343 19.6542 18.2933C19.6542 15.9524 16.3733 15.3457 12.4847 15.3457Z'
            stroke='#738DA6'
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.4846 12.0059C15.0227 12.0059 17.0799 9.94779 17.0799 7.40969C17.0799 4.8716 15.0227 2.81445 12.4846 2.81445C9.94655 2.81445 7.88845 4.8716 7.88845 7.40969C7.87988 9.93922 9.92369 11.9973 12.4523 12.0059H12.4846Z'
            stroke='#738DA6'
            strokeWidth={1.42857}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
export default ProfileIcon;
