import React from "react";

export const FooterInfoLink = () => {
    return (
        <div className='col-xl-8 col-lg-10 col-11 d-flex align-items-center justify-content-center form-footer'>
            <a className='ssc-footer-link' href="https://www.safetyservicescompany.com/our-privacy-policy/" target="_blank" rel="noopener noreferrer">
                Privacy Policy
            </a>
            <h5>|</h5>
            <a className='ssc-footer-link' href="https://www.safetyservicescompany.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of use</a>
        </div>
    );
};
