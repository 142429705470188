import { API } from "../../constants";
import { fetchData } from "../common/apiCalling";
import { METHODS } from "../../constants";

export const signedURL = ({data}) => {
    const url = `${API.SIGNED_URL}`;
    return fetchData({ body: data, method: METHODS.PUT, url });
};


export const getFileSignedUrl = ({ filePath }) => {
    let url = `${API.SIGNED_URL}?filePath=${filePath}`;
    return fetchData({ method: METHODS.GET, url });
};


