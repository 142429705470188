import React from 'react';

export const Loader = () => {
    return (
        <div className='loader w-100 h-100 d-flex justify-content-center  align-items-center  '>
            <div className='spinner-border spinner ' role='status'>
                <span className='sr-only'></span>
            </div>
        </div>
    );
}
