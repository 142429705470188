import React from 'react';

export default function Customer() {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6 13.5C6 11.2909 8.01472 9.5 10.5 9.5C12.9853 9.5 15 11.2909 15 13.5'
                stroke='#738DA6'
                strokeWidth='1.5'
            />
            <path
                d='M1 13.5C1 11.2909 3.01472 9.5 5.5 9.5C6.42507 9.5 7.28495 9.74812 8 10.1736'
                stroke='#738DA6'
                strokeWidth='1.5'
            />
            <circle cx='11' cy='5.5' r='2.25' stroke='#738DA6' strokeWidth='1.5' />
            <circle cx='5.5' cy='6' r='1.25' stroke='#738DA6' strokeWidth='1.5' />
        </svg>
    );
}
