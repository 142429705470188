import * as React from 'react';
const BgIcon = (props) => (
    <svg
        width={32}
        height={32}
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <rect width={32} height={32} rx={16} fill='#E4FBFA' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M17.8252 9.84108H13.3899C12.0032 9.83575 10.8665 10.9411 10.8339 12.3271V19.4691C10.8032 20.8778 11.9199 22.0451 13.3285 22.0764C13.3492 22.0764 13.3692 22.0771 13.3899 22.0764H18.7159C20.1119 22.0198 21.2119 20.8664 21.2019 19.4691V13.3584L17.8252 9.84108Z'
            stroke='#738DA6'
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M17.6501 9.83325V11.7726C17.6501 12.7193 18.4155 13.4866 19.3621 13.4893H21.1988'
            stroke='#738DA6'
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M17.5253 18.2389H13.9253'
            stroke='#738DA6'
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M16.1621 15.7371H13.9248'
            stroke='#738DA6'
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
export default BgIcon;
