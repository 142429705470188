import { createSlice } from '@reduxjs/toolkit';
import { REDUX } from '../../constants';

const slice = createSlice({
    name: REDUX.slice.LOADER,
    initialState: false,
    reducers: {
        setLoaderVisibility: (state, action) => {
            return state = action.payload;
        },
    },
});

export const { setLoaderVisibility } = slice.actions;
export default slice.reducer;
