import { createSlice } from '@reduxjs/toolkit';
import { REDUX } from '../../constants';

const slice = createSlice({
    name: REDUX.slice.NOTIFICATION_UPDATE,
    initialState: false,
    reducers: {
        setNotificationUpdate: (state, action) => {
            return state = action.payload;
        },
    },
});

export const { setNotificationUpdate } = slice.actions;
export default slice.reducer;
