import React from 'react';
const IconPlus = ({ className, width, height  }) => (
    <svg
        width={width || 24}
        height={height || 24}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
       
    >
        <path
            d='M12 5V19'
            stroke='white'
            strokeWidth={2}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M5 12H19'
            stroke='white'
            strokeWidth={2}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
export default IconPlus;
