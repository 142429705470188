import React from 'react';
import PropTypes from 'prop-types';

const LeftArrow = ({ opacity = 1 }) => {
    return (
        <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ opacity }}
        >
            <path
                d='M11 17L6 12L11 7'
                stroke='#738DA6'
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M18 17L13 12L18 7'
                stroke='#738DA6'
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default LeftArrow;

LeftArrow.propTypes = {
    opacity: PropTypes.number,
};
