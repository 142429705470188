import * as React from 'react';
const BackArrow = (props) => (
    <svg
        width={17}
        height={15}
        viewBox='0 0 17 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            d='M1.25 7.27429L16.25 7.27429'
            stroke='#738DA6'
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.2998 13.2987L1.2498 7.27469L7.2998 1.24969'
            stroke='#738DA6'
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
export default BackArrow;
