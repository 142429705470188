import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateUser, getLocalStorageItem, popup, createSubscriptionExpiryObject, getSubscriptionStatus } from "../../../utils";
import { setIsSubscriptionExpiryReadObj } from "../../../redux";
import { LOCAL_STORAGE, SUBSCRIPTION_EXPIRY } from "../../../constants";

export const SubscriptionExpiryPopup = () => {

    // declarations

    const dispatch = useDispatch();
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState('');

    const userId = getLocalStorageItem(LOCAL_STORAGE.USER_ID);
    const currentAccountId = useSelector(state => state.accountArray.selectedAccount);
    const accounts = useSelector(state => state.accountArray.accounts);
    const accountStates = useSelector(state => state.isSubscriptionExpiryRead.accountStates);

    // functions

    const updateAccountUserFunc = (accountId, isSubscriptionExpiryRead) => {
        updateUser({
            accountId,
            data: {
                user_id: userId,
                is_subscription_expiry_read: isSubscriptionExpiryRead
            }
        }).then(res => {
            if (res.status !== 'success') {
                popup('error', SUBSCRIPTION_EXPIRY.ERROR);
            }
        }).catch((error) => {
            popup('error', error.message);
        });
    }

    const closeNotificationFunc = () => {
        setShowNotification(false);
        dispatch(setIsSubscriptionExpiryReadObj({ accountId: currentAccountId, value: 1 }));
        updateAccountUserFunc(currentAccountId, 1);
    };

    function getTimestampsRange(expiryDetails) {
        const expiryTimestamp = expiryDetails.subscription_expiry;
        const daysBeforeExpiry = expiryDetails.days_before_expiry;
        const daysAfterExpiry = expiryDetails.days_after_expiry;

        const expiryTime = new Date(expiryTimestamp);
        const daysBefore = new Date(expiryTime.getTime() - daysBeforeExpiry * 24 * 60 * 60 * 1000);
        const daysAfter = new Date(expiryTime.getTime() + daysAfterExpiry * 24 * 60 * 60 * 1000);

        return {
            daysBefore: daysBefore.toISOString(),
            daysAfter: daysAfter.toISOString()
        };
    }

    function startSubscriptionExpiryCheck(expiryDetails) {
        const intervalId = setInterval(() => {
            const timeStampRange = getTimestampsRange(expiryDetails);
            const currentTime = new Date().getTime();

            const startTime = new Date(timeStampRange.daysBefore).getTime();
            const endTime = new Date(timeStampRange.daysAfter).getTime();

            const { content } = getSubscriptionStatus(expiryDetails.subscription_expiry);
            setMessage(content);

            if (currentTime > startTime) {
                setShowNotification(true);

                if (currentTime > endTime) {
                    setShowNotification(false);
                    clearInterval(intervalId);
                }
            }
        }, 30000); // Check every 30 seconds

        return intervalId;
    }

    // Initial state

    useEffect(() => {
        if (!isEmpty(accounts) && currentAccountId ) {
            const subscriptionExpiryObj = createSubscriptionExpiryObject(accounts);
            const expiryDetails = subscriptionExpiryObj[currentAccountId];

            if (!isEmpty(expiryDetails) && !expiryDetails.is_subscription_expiry_read && !accountStates?.[currentAccountId]) {
                const interval = startSubscriptionExpiryCheck(expiryDetails);

                return () => clearInterval(interval);
            } else {
                setShowNotification(false);
            }
        }
    }, [showNotification, currentAccountId]);

    return (
        <div className={'notification-bar' + (!showNotification ? ' hidden' : '')}>
            <span className="message">{message}</span>
            <span className='btn-close' onClick={closeNotificationFunc} />
        </div>
    );
};
