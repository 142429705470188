import { API, METHODS } from '../../../constants';
import { fetchData } from '../../common/apiCalling';

// to request login url for sso login
export const getAdminLoginURL = async () => {
    const url = `${API.ADMIN}${API.LOGIN}`;
    return fetchData({ method: METHODS.GET, url });
};

// to fetch code from server
export const getSpaCode = async () => {
    const url = `${API.ADMIN}${API.FETCH_CODE}`;
    return fetchData({ method: METHODS.GET, url });
};
