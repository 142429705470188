import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Edit } from '../../../assets/svg';
import { ROLES } from '../../../constants';
import { useSelector } from 'react-redux';

export const EditButton = ({ id, navigateTo }) => {
    const navigate = useNavigate();
    const role = useSelector((s) => s.user.role);
    const showButton = useMemo(
        () => [ROLES.ADMIN, ROLES.CSM, ROLES.CONTENT_MANAGER].includes(role),
        [role],
    );

    return showButton ? (
        <button
            className='border-0 p-0 bg-white'
            onClick={() => {
                navigate(navigateTo, {
                    state: {
                        id: id,
                    },
                });
            }}
            id='action-btn'
            title='Edit'
        >
            <Edit />
        </button>
    ) : (
        '-'
    );
};
EditButton.propTypes = {
    id: PropTypes.string,
    navigateTo: PropTypes.string,
};
