import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export const TooltipComponent = ({ children, tooltipText, placement, delayShow, delayHide }) => {
    const renderTooltip = (props) => (
        <Tooltip id="tooltip" {...props}>
            {tooltipText}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement={placement}
            delay={{ show: delayShow, hide: delayHide }}
            overlay={renderTooltip}
        >
            <div>{children}</div>
        </OverlayTrigger>
    );
};

TooltipComponent.propTypes = {
    children: PropTypes.node.isRequired,
    tooltipText: PropTypes.string.isRequired,
    placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    delayShow: PropTypes.number,
    delayHide: PropTypes.number,
};

TooltipComponent.defaultProps = {
    placement: 'top',
    delayShow: 250,
    delayHide: 400,
};
