/* eslint-disable no-undef */
import { popup } from '.';
import { exportStore } from '../..';
import { ERROR, HEADERS, HTTP_CODES, LOCAL_STORAGE, ROUTES } from '../../constants';
import { setSessionPopup } from '../../redux';
import { getLocalStorageItem } from './localStorage';

const DEFAULT_HEADERS = { 'Content-Type': HEADERS.APPLICATION_JSON };

const useHeaders = (customHeaders) => {
    const apiHeaders = { ...DEFAULT_HEADERS, ...customHeaders };
    const authToken = getLocalStorageItem(LOCAL_STORAGE.AUTH_TOKEN);
    if (authToken) apiHeaders['Authorization'] = `${HEADERS.AUTHORIZATION} ${authToken}`;
    return apiHeaders;
};

export const fetchData = async ({ body, method, url, headers = {} }) => {
    const customHeader = useHeaders(headers);
    const apiUrl = process.env.REACT_APP_BASE_URL + url;

    const response = await fetch(apiUrl, {
        body: JSON.stringify(body),
        method,
        headers: customHeader,
    });

    const json = await response.json();

    if(response.status === HTTP_CODES.INVALID_TOKEN) {
        const { router } = require('../../AppRouter');
        if(json.message === ERROR.JWT_EXPIRED) {
            exportStore.dispatch(setSessionPopup(true));
            setTimeout(() => {
                localStorage.clear();
                window.location.href = '/';
                window.location.reload();
            }, process.env.REACT_APP_REFRESH_TOKEN_TIME_DIFFERENCE);
        } else {
            localStorage.clear();
            popup('error', ERROR.SESSION_EXPIRED);
            router.navigate(ROUTES.HOME);
        }
    }
    else if (response.status === HTTP_CODES.INTERNAL_SERVER_ERROR) {
        const { router } = require('../../AppRouter');
        router.navigate(ROUTES.SERVER_ERROR);
    } else if (json.status === 'error') throw new Error(json.message);
    else return { ...json, statusCode: response.status };
};
