import React from 'react';

export default function DocumentCategory() {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.99195 2.00783H5.55662C4.16995 2.0025 3.03328 3.10783 3.00062 4.49383V11.6358C2.96995 13.0445 4.08662 14.2118 5.49528 14.2432C5.51595 14.2432 5.53595 14.2438 5.55662 14.2432H10.8826C12.2786 14.1865 13.3786 13.0332 13.3686 11.6358V5.52517L9.99195 2.00783Z'
                stroke='#738DA6'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.81677 2V3.93933C9.81677 4.886 10.5821 5.65333 11.5288 5.656H13.3654'
                stroke='#738DA6'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.69216 10.4056H6.09216'
                stroke='#738DA6'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.32889 7.90413H6.09155'
                stroke='#738DA6'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}
