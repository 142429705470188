import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setLoaderVisibility } from '../../../redux';
import { DeleteConfirmationModal, ButtonComponent } from '../../shared';
import { Delete } from '../../../assets/svg';
import { deleteDocsRequest, popup } from '../../../utils';
import { DOC_REQUEST } from '../../../constants';

export const DocumentRequestOperation = ({ data, debouncedApiCall }) => {
    const dispatch = useDispatch();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const handleDeleteClick = useCallback(async () => {
        try {
            dispatch(setLoaderVisibility(true));
            await deleteDocsRequest({ id: data.id });
            debouncedApiCall();
            popup('error', DOC_REQUEST.DELETED_MESSAGE);
            setIsDeleteModalOpen(false);
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    }, [data]);

    return (
        <>
            <div className='d-flex align-items-center justify-content-center options-set'>
                <ButtonComponent
                    className='border-0 p-0 bg-white'
                    type='button'
                    clickHandler={(e) => {
                        e.stopPropagation();
                        setIsDeleteModalOpen(true);
                    }}
                    id='action-btn'
                >
                    <Delete />
                </ButtonComponent>
            </div>
            {isDeleteModalOpen && (
                <DeleteConfirmationModal
                    isDeleteModal={isDeleteModalOpen}
                    setIsDeleteModal={setIsDeleteModalOpen}
                    selectedItemId={data.id}
                    handleDeleteClick={handleDeleteClick}
                    deleteButtonName={DOC_REQUEST.DELETE_BUTTON}
                />
            )}
        </>
    );
};

DocumentRequestOperation.propTypes = {
    data: PropTypes.object.isRequired,
    debouncedApiCall: PropTypes.func,
};
