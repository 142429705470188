import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {capitalize} from 'lodash'

import { ButtonComponent } from '../../shared';
import { SearchIcon, CustomDropdownArrow, BackArrow } from '../../../assets/svg';
import { setLoaderVisibility } from '../../../redux';
import { handleTPADropdownFn, handleGradeDropdownFn, handleGradeSelectFn, handleTPASelectFn } from './ScoreCardsFunctions';
import { SYMBOL } from '../../../constants';

export const AdminScoreCardHeader = ({
    platforms,
    availableGrades,
    setSelectedPlatform,
    setSearch,
    debouncedApiCall,
    grade,
    setGrade,
    handleAddClick,
    heading,
    buttonText,
    searchBarPlaceholder
}) => {

    // declarations

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showGradeDropDown, setShowGradeDropDown] = useState(false);
    const [isTPAOpen, setIsTPAOpen] = useState(false);
    const [initPlatform, setInitPlatform] = useState(null);

    // functions

    const handleTPADropdown = handleTPADropdownFn(setSearch, setShowGradeDropDown, setIsTPAOpen);
    const handleGradeDropdown = handleGradeDropdownFn(setSearch, setShowGradeDropDown, setIsTPAOpen);

    const handleGradeSelect = (item) => {
        dispatch(setLoaderVisibility(true));
        handleGradeSelectFn({
            setSearchQuery: setSearch,
            setGrade: setGrade,
            setShowGradeDropDown:setShowGradeDropDown
        })(item);
        debouncedApiCall();
    }

    const handleSearchChange = (event) => {
        const searchQuery = event.target.value.trim();
        setSearch && setSearch(searchQuery);
    };

    const handleBackButton = () => {
        navigate(-1);
    };

    const handleTPASelect = handleTPASelectFn({
        setSearchQuery: setSearch,
        setTpaValue: setInitPlatform,
        setIsTPAOpen: setIsTPAOpen,
        setSelectedTpa: setSelectedPlatform
    });

    // initial state

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest('.ms-4.grade-dropdown-custom') === null)
                setTimeout(() => {
                    setShowGradeDropDown(false);
                    setIsTPAOpen(false);
                }, 100);
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (platforms.length > 0 && !initPlatform) {
            platforms.unshift({id:'', name: 'All Platforms'});
            setInitPlatform(platforms[0]);
            setSelectedPlatform(platforms[0])
        }
    }, [platforms, setGrade]);

    useEffect(() => {
        dispatch(setLoaderVisibility(true));
        debouncedApiCall();
    }, [initPlatform, setGrade]);

    return (
        <>
            <div className="title-btn mb-4 mt-0 d-flex align-items-center justify-content-between">
                <ButtonComponent className="d-flex align-items-center border-0 bg-white" clickHandler={handleBackButton}>
                    <BackArrow />
                    <span style={{ 'marginLeft': '10px' }} className='font-16px'>{heading}</span>
                </ButtonComponent>
                <ButtonComponent
                    clickHandler={handleAddClick}
                    className="ssc-primary-green-btn w-auto"
                    type="button"
                >
                    {buttonText}
                </ButtonComponent>
            </div>
            <div className='py-2 mb-3'>
                <div className="ssc-scorecard-box">
                    <div className="p-3 name-box-set ">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-start">
                                <div className="ssc-search-box">
                                    <div className="search-box d-flex align-items-center justify-content-start">
                                        <button className="bg-white p-0 border-0 d-flex align-items-center">
                                            <SearchIcon />
                                        </button>
                                        <input onChange={handleSearchChange} maxLength={40} type="text" className="search-area border-0 w-100 py-1" placeholder={searchBarPlaceholder} />
                                    </div>
                                </div>
                                <div className="ms-4 grade-dropdown-custom">
                                    <div className="dropdown">
                                        <ButtonComponent 
                                            clickHandler={handleGradeDropdown}
                                            className="custom-btn bg-white dropdown-toggle d-flex align-items-center justify-content-center"
                                            type="button"
                                            id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <div className="text-txt">
                                                <span>{grade ? capitalize(grade) : capitalize(availableGrades[0])}</span>
                                            </div>
                                            <CustomDropdownArrow width={10} height={6} />
                                        </ButtonComponent>
                                        <ul className={`dropdown-menu custom-drop-content ${showGradeDropDown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1">
                                            {availableGrades.map(grade => (
                                                <li key={grade} onClick={() => handleGradeSelect(grade)}>
                                                    <a className="dropdown-item d-flex align-items-center justify-content-start" href="#">
                                                        {grade !== 'All Grades' && <div className={`dot-circle ${grade}`}></div>}
                                                        <div className="text-txt">
                                                            <span>{capitalize(grade)}</span>
                                                        </div>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="ms-4 grade-dropdown-custom">
                                    <div className="dropdown">
                                        <button 
                                            onClick={handleTPADropdown} 
                                            className="custom-btn bg-white dropdown-toggle d-flex align-items-center justify-content-center w-100" 
                                            type="button" 
                                            id="dropdownMenuButton1" 
                                            data-bs-toggle="dropdown" 
                                            aria-expanded="false"
                                        >
                                            <div className="text-txt">
                                                <span>{initPlatform ? initPlatform?.name : 'Not Available'}{initPlatform?.name !== 'All Platforms' && <sup>{SYMBOL.REGISTERED}</sup>}</span>
                                            </div>
                                            <CustomDropdownArrow width={10} height={6} />
                                        </button>
                                        <ul className={`dropdown-menu custom-drop-content w-100 ${(isTPAOpen && platforms.length) ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1">
                                            {platforms.map((item, index) => (
                                                <li key={index} onClick={() => handleTPASelect(item)}>
                                                    <a className="dropdown-item d-flex align-items-center justify-content-start" href="#">
                                                        <div className="text-txt">
                                                            <span>{item.name}</span>{item?.name !== 'All Platforms' && <sup>{SYMBOL.REGISTERED}</sup>}
                                                        </div>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

AdminScoreCardHeader.propTypes = {
    platforms: PropTypes.array,
    availableGrades: PropTypes.array,
    setSelectedPlatform: PropTypes.func,
    setSearch: PropTypes.func,
    debouncedApiCall: PropTypes.func,
    grade: PropTypes.string,
    setGrade: PropTypes.func,
    handleAddClick: PropTypes.func,
    heading: PropTypes.string,
    buttonText: PropTypes.string,
    searchBarPlaceholder: PropTypes.string
}
