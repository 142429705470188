import React from "react";
const EmptyDoc = ({width, height}) => (
  <svg
    width={width || 176}
    height={height ||138}
    viewBox="0 0 176 138"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
   
  >
    <g clipPath="url(#clip0_1297_33534)">
      <path
        d="M32.0894 16.2766C31.7587 16.3129 31.44 16.4208 31.1558 16.5928C30.8716 16.7648 30.6288 16.9967 30.4445 17.272C30.2602 17.5474 30.1388 17.8596 30.089 18.1867C30.0391 18.5138 30.062 18.8477 30.156 19.165L23.5762 23.84L27.4983 25.3408L33.1453 20.6712C33.671 20.4789 34.1074 20.1015 34.3719 19.6105C34.6364 19.1196 34.7106 18.5493 34.5805 18.0076C34.4503 17.4659 34.1248 16.9905 33.6657 16.6715C33.2066 16.3524 32.6457 16.212 32.0894 16.2766Z"
        fill="#FFB8B8"
      />
      <path
        d="M31.4773 23.3182L23.3157 31.4835C22.1971 32.5953 20.7222 33.2823 19.1472 33.4253L9.06206 34.3402C8.69234 34.3753 8.31929 34.3363 7.96497 34.2256C7.61065 34.1149 7.28225 33.9347 6.99916 33.6956C6.60752 33.3697 6.31629 32.9405 6.15893 32.4574C6.00157 31.9743 5.98446 31.4569 6.10956 30.9645C6.23467 30.4722 6.49692 30.025 6.86618 29.6741C7.23544 29.3233 7.69674 29.0832 8.19708 28.9814L18.5001 26.8001L27.8803 19.9251C28.0107 19.8521 28.158 19.8143 28.3077 19.8155C28.4573 19.8166 28.6041 19.8565 28.7334 19.9314L31.2843 22.0092C31.4378 22.0928 31.561 22.2221 31.6368 22.379C31.7127 22.5358 31.7372 22.7123 31.707 22.8837C31.6772 23.0486 31.597 23.2003 31.4773 23.3182Z"
        fill="#2F2E41"
      />
      <path
        d="M129.33 27.7063C129.391 27.1489 129.278 26.5864 129.006 26.0953C128.734 25.6041 128.316 25.2082 127.81 24.9613L77.017 0.277484C76.694 0.12036 76.343 0.0280306 75.9841 0.00577038C75.6252 -0.0164898 75.2654 0.0317531 74.9253 0.147745C74.5851 0.263738 74.2713 0.445209 74.0017 0.681795C73.7321 0.918381 73.512 1.20545 73.354 1.52661L73.3531 1.52851L54.3023 40.2841C53.9844 40.9333 53.9382 41.6813 54.1741 42.3642C54.41 43.0471 54.9085 43.6091 55.5605 43.9271L106.354 68.6109C107.007 68.927 107.759 68.9729 108.446 68.7384C109.133 68.5039 109.698 68.0081 110.018 67.3599L129.068 28.6043C129.208 28.3223 129.296 28.0185 129.33 27.7063Z"
        fill="#F2F2F2"
      />
      <path
        d="M99.5741 113.725H33.6673C32.8375 113.724 32.042 113.396 31.4553 112.812C30.8686 112.229 30.5385 111.438 30.5376 110.613V21.6523C30.5385 20.8273 30.8686 20.0363 31.4553 19.453C32.042 18.8696 32.8375 18.5415 33.6673 18.5405H99.5741C100.404 18.5415 101.199 18.8696 101.786 19.453C102.373 20.0363 102.703 20.8273 102.704 21.6523V110.613C102.703 111.438 102.373 112.229 101.786 112.812C101.199 113.396 100.404 113.724 99.5741 113.725Z"
        fill="white"
      />
      <path
        d="M51.1475 45.0678C53.5223 45.0678 55.4474 43.1536 55.4474 40.7924C55.4474 38.4312 53.5223 36.5171 51.1475 36.5171C48.7728 36.5171 46.8477 38.4312 46.8477 40.7924C46.8477 43.1536 48.7728 45.0678 51.1475 45.0678Z"
        fill="#E6E6E6"
      />
      <path
        d="M44.5942 52.4775C45.2018 52.8209 45.8377 53.1122 46.495 53.3484L46.6111 53.3884C47.9351 53.8506 49.3283 54.0859 50.7314 54.0842C51.0138 54.0842 51.2857 54.0745 51.5398 54.0553C52.1581 54.0177 52.7726 53.9334 53.3781 53.8033C53.7104 53.7311 54.0416 53.6447 54.3622 53.547C54.4397 53.5242 54.5114 53.5019 54.59 53.4774C55.8962 53.0563 57.1235 52.424 58.2227 51.6057C58.1345 51.4398 58.0379 51.2784 57.9333 51.1222C57.3681 50.2636 56.5965 49.5587 55.6883 49.0712C54.7801 48.5838 53.764 48.3292 52.732 48.3306H50.4689C49.2752 48.3308 48.1068 48.6721 47.1027 49.3139C46.0986 49.9557 45.3012 50.871 44.8054 51.9506C44.7261 52.1226 44.6557 52.2984 44.5942 52.4775Z"
        fill="#E6E6E6"
      />
      <path
        d="M52.607 53.6968C52.4625 53.7074 52.3235 53.7559 52.2041 53.8373C52.8109 53.7998 53.4139 53.7166 54.008 53.5884L52.607 53.6968Z"
        fill="#2F2E41"
      />
      <path
        d="M51.4082 29.1904C48.6389 29.1907 45.9471 30.0999 43.7506 31.777C41.5541 33.4541 39.9759 35.8052 39.2609 38.4654C38.5458 41.1256 38.734 43.9461 39.7961 46.4891C40.8582 49.032 42.7349 51.1553 45.1349 52.5293C45.772 52.8961 46.4408 53.2056 47.1332 53.4541L47.2509 53.4946C48.5867 53.9609 49.9925 54.1983 51.4082 54.1967C51.6821 54.1967 51.9559 54.1877 52.2252 54.1674C52.8486 54.1293 53.4682 54.0443 54.0787 53.9131C54.4204 53.8389 54.7554 53.7511 55.0722 53.6544C55.1491 53.6319 55.2215 53.6094 55.3007 53.5846C58.147 52.6628 60.5692 50.7618 62.132 48.2232C63.6949 45.6847 64.2957 42.6751 63.8267 39.7355C63.3576 36.7959 61.8494 34.1191 59.5731 32.1862C57.2968 30.2533 54.4017 29.191 51.4082 29.1904ZM58.7044 51.0599C57.6195 51.8923 56.3985 52.5324 55.0948 52.9523C55.0247 52.9748 54.9522 52.9973 54.8798 53.0198C54.5788 53.1121 54.262 53.1931 53.9361 53.2628C53.3586 53.3882 52.7722 53.4694 52.1822 53.5058C51.9265 53.5238 51.6662 53.5328 51.4082 53.5328C50.0682 53.5336 48.7376 53.3092 47.4727 52.869L47.3619 52.8308C46.6731 52.5847 46.0093 52.2742 45.3793 51.9037C43.1854 50.6195 41.4636 48.6694 40.467 46.3401C39.4703 44.0107 39.2514 41.425 39.8423 38.9627C40.4332 36.5005 41.8028 34.2917 43.7499 32.6608C45.697 31.0299 48.1188 30.0629 50.6596 29.902C53.2003 29.7411 55.726 30.3948 57.8654 31.7669C60.0048 33.139 61.6452 35.1572 62.5455 37.525C63.4458 39.8928 63.5586 42.4853 62.8672 44.9216C62.1758 47.3578 60.7167 49.5093 58.7044 51.0599Z"
        fill="#CCCCCC"
      />
      <path
        d="M99.5741 113.725H33.6673C32.8375 113.724 32.042 113.396 31.4553 112.812C30.8686 112.229 30.5385 111.438 30.5376 110.613V21.6523C30.5385 20.8273 30.8686 20.0363 31.4553 19.453C32.042 18.8696 32.8375 18.5415 33.6673 18.5405H99.5741C100.404 18.5415 101.199 18.8696 101.786 19.453C102.373 20.0363 102.703 20.8273 102.704 21.6523V110.613C102.703 111.438 102.373 112.229 101.786 112.812C101.199 113.396 100.404 113.724 99.5741 113.725ZM33.6673 18.9066C32.9351 18.9074 32.2332 19.197 31.7155 19.7117C31.1978 20.2265 30.9066 20.9244 30.9058 21.6523V110.613C30.9066 111.341 31.1978 112.039 31.7155 112.554C32.2332 113.068 32.9351 113.358 33.6673 113.359H99.5741C100.306 113.358 101.008 113.068 101.526 112.554C102.043 112.039 102.335 111.341 102.336 110.613V21.6523C102.335 20.9244 102.043 20.2265 101.526 19.7117C101.008 19.197 100.306 18.9074 99.5741 18.9066L33.6673 18.9066Z"
        fill="#CCCCCC"
      />
      <path
        d="M86.8152 41.6453H68.1658C67.7745 41.6453 67.3992 41.4908 67.1226 41.2157C66.8459 40.9406 66.6904 40.5674 66.6904 40.1784C66.6904 39.7893 66.8459 39.4162 67.1226 39.1411C67.3992 38.866 67.7745 38.7114 68.1658 38.7114H86.8152C87.2065 38.7114 87.5817 38.866 87.8584 39.1411C88.1351 39.4162 88.2905 39.7893 88.2905 40.1784C88.2905 40.5674 88.1351 40.9406 87.8584 41.2157C87.5817 41.4908 87.2065 41.6453 86.8152 41.6453Z"
        fill="#E6E6E6"
      />
      <path
        d="M92.9011 46.5965H68.1658C67.7745 46.5965 67.3992 46.442 67.1226 46.1668C66.8459 45.8917 66.6904 45.5186 66.6904 45.1296C66.6904 44.7405 66.8459 44.3674 67.1226 44.0923C67.3992 43.8172 67.7745 43.6626 68.1658 43.6626H92.9011C93.2924 43.6626 93.6677 43.8172 93.9443 44.0923C94.221 44.3674 94.3765 44.7405 94.3765 45.1296C94.3765 45.5186 94.221 45.8917 93.9443 46.1668C93.6677 46.442 93.2924 46.5965 92.9011 46.5965Z"
        fill="#E6E6E6"
      />
      <path
        d="M86.7882 65.5853H40.3401C39.9488 65.5853 39.5736 65.4307 39.2969 65.1556C39.0202 64.8805 38.8647 64.5074 38.8647 64.1183C38.8647 63.7293 39.0202 63.3561 39.2969 63.081C39.5736 62.8059 39.9488 62.6514 40.3401 62.6514H86.7882C87.1795 62.6514 87.5547 62.8059 87.8314 63.081C88.1081 63.3561 88.2635 63.7293 88.2635 64.1183C88.2635 64.5074 88.1081 64.8805 87.8314 65.1556C87.5547 65.4307 87.1795 65.5853 86.7882 65.5853Z"
        fill="#E6E6E6"
      />
      <path
        d="M92.8741 70.5364H40.3401C39.9488 70.5364 39.5736 70.3819 39.2969 70.1068C39.0202 69.8317 38.8647 69.4586 38.8647 69.0695C38.8647 68.6804 39.0202 68.3073 39.2969 68.0322C39.5736 67.7571 39.9488 67.6025 40.3401 67.6025H92.8741C93.2654 67.6025 93.6406 67.7571 93.9173 68.0322C94.194 68.3073 94.3495 68.6804 94.3495 69.0695C94.3495 69.4586 94.194 69.8317 93.9173 70.1068C93.6406 70.3819 93.2654 70.5364 92.8741 70.5364Z"
        fill="#E6E6E6"
      />
      <path
        d="M86.7882 81.9154H40.3401C39.9488 81.9154 39.5736 81.7608 39.2969 81.4857C39.0202 81.2106 38.8647 80.8375 38.8647 80.4484C38.8647 80.0593 39.0202 79.6862 39.2969 79.4111C39.5736 79.136 39.9488 78.9814 40.3401 78.9814H86.7882C87.1795 78.9814 87.5547 79.136 87.8314 79.4111C88.1081 79.6862 88.2635 80.0593 88.2635 80.4484C88.2635 80.8375 88.1081 81.2106 87.8314 81.4857C87.5547 81.7608 87.1795 81.9154 86.7882 81.9154Z"
        fill="#E6E6E6"
      />
      <path
        d="M92.8741 86.8665H40.3401C39.9488 86.8665 39.5736 86.712 39.2969 86.4369C39.0202 86.1618 38.8647 85.7886 38.8647 85.3996C38.8647 85.0105 39.0202 84.6374 39.2969 84.3623C39.5736 84.0872 39.9488 83.9326 40.3401 83.9326H92.8741C93.2654 83.9326 93.6406 84.0872 93.9173 84.3623C94.194 84.6374 94.3495 85.0105 94.3495 85.3996C94.3495 85.7886 94.194 86.1618 93.9173 86.4369C93.6406 86.712 93.2654 86.8665 92.8741 86.8665Z"
        fill="#E6E6E6"
      />
      <path
        d="M86.7882 98.2459H40.3401C39.9488 98.2459 39.5736 98.0914 39.2969 97.8163C39.0202 97.5411 38.8647 97.168 38.8647 96.779C38.8647 96.3899 39.0202 96.0168 39.2969 95.7417C39.5736 95.4666 39.9488 95.312 40.3401 95.312H86.7882C87.1795 95.312 87.5547 95.4666 87.8314 95.7417C88.1081 96.0168 88.2635 96.3899 88.2635 96.779C88.2635 97.168 88.1081 97.5411 87.8314 97.8163C87.5547 98.0914 87.1795 98.2459 86.7882 98.2459Z"
        fill="#E6E6E6"
      />
      <path
        d="M92.8741 103.197H40.3401C39.9488 103.197 39.5736 103.042 39.2969 102.767C39.0202 102.492 38.8647 102.119 38.8647 101.73C38.8647 101.341 39.0202 100.967 39.2969 100.692C39.5736 100.417 39.9488 100.263 40.3401 100.263H92.8741C93.2651 100.263 93.6399 100.418 93.9162 100.693C94.1925 100.968 94.3476 101.341 94.3476 101.73C94.3476 102.118 94.1925 102.491 93.9162 102.766C93.6399 103.041 93.2651 103.196 92.8741 103.197Z"
        fill="#E6E6E6"
      />
      <path
        d="M127.067 53.1335C127.128 52.5761 127.015 52.0136 126.743 51.5225C126.471 51.0314 126.053 50.6355 125.547 50.3885L74.7538 25.7047C74.4308 25.5476 74.0798 25.4553 73.7209 25.433C73.362 25.4108 73.0022 25.459 72.6621 25.575C72.322 25.691 72.0081 25.8725 71.7385 26.109C71.4689 26.3456 71.2488 26.6327 71.0908 26.9539L71.0899 26.9558L52.0391 65.7113C51.7212 66.3605 51.6751 67.1085 51.9109 67.7914C52.1468 68.4743 52.6454 69.0364 53.2973 69.3544L104.091 94.0381C104.743 94.3543 105.496 94.4001 106.183 94.1656C106.869 93.9311 107.435 93.4354 107.754 92.7871L126.805 54.0315C126.944 53.7496 127.033 53.4457 127.067 53.1335Z"
        fill="white"
      />
      <path
        d="M127.067 53.1335C127.128 52.5761 127.015 52.0136 126.743 51.5225C126.471 51.0314 126.053 50.6355 125.547 50.3885L74.7538 25.7047C74.4308 25.5476 74.0798 25.4553 73.7209 25.433C73.362 25.4108 73.0022 25.459 72.6621 25.575C72.322 25.691 72.0081 25.8725 71.7385 26.109C71.4689 26.3456 71.2488 26.6327 71.0908 26.9539L71.0899 26.9558L52.0391 65.7113C51.7212 66.3605 51.6751 67.1085 51.9109 67.7914C52.1468 68.4743 52.6454 69.0364 53.2973 69.3544L104.091 94.0381C104.743 94.3543 105.496 94.4001 106.183 94.1656C106.869 93.9311 107.435 93.4354 107.754 92.7871L126.805 54.0315C126.944 53.7496 127.033 53.4457 127.067 53.1335ZM107.465 92.6464C107.183 93.2185 106.684 93.656 106.078 93.8629C105.472 94.0699 104.808 94.0293 104.232 93.7503L53.4388 69.0665C52.8635 68.786 52.4235 68.2901 52.2154 67.6875C52.0072 67.0849 52.048 66.4248 52.3287 65.8521L71.3794 27.0965C71.6615 26.5244 72.1603 26.0869 72.7663 25.88C73.3724 25.673 74.0362 25.7135 74.6123 25.9926L125.405 50.6764C125.981 50.9569 126.421 51.4528 126.629 52.0554C126.837 52.658 126.796 53.3181 126.516 53.8908L107.465 92.6464Z"
        fill="#3F3D56"
      />
      <path
        d="M121.527 54.8955L108.225 81.9573C108.136 82.138 107.979 82.2762 107.788 82.3416C107.596 82.407 107.387 82.3941 107.205 82.3059L61.0019 59.8527C60.9689 59.8369 60.9373 59.8184 60.9075 59.7973C60.7559 59.6948 60.6467 59.5414 60.5997 59.3653C60.5527 59.1891 60.5711 59.0021 60.6516 58.8383L73.9542 31.7765C73.9981 31.687 74.0593 31.6071 74.1343 31.5411C74.2094 31.4752 74.2967 31.4246 74.3914 31.3923C74.4861 31.3599 74.5862 31.3464 74.6862 31.3525C74.7861 31.3586 74.8839 31.3843 74.9738 31.428L121.177 53.8812C121.359 53.9694 121.498 54.1258 121.564 54.3159C121.629 54.5061 121.616 54.7144 121.528 54.8951L121.527 54.8955Z"
        fill="#E6E6E6"
      />
      <path
        d="M80.9642 53.5214C84.6596 53.5214 87.6554 50.5427 87.6554 46.8684C87.6554 43.194 84.6596 40.2153 80.9642 40.2153C77.2687 40.2153 74.2729 43.194 74.2729 46.8684C74.2729 50.5427 77.2687 53.5214 80.9642 53.5214Z"
        fill="#17B1A4"
      />
      <path
        d="M90.7611 74.4493L61.27 60.1176C61.2328 60.1001 61.1974 60.0793 61.1641 60.0554L86.9202 42.8117C87.0789 42.7034 87.2636 42.639 87.4555 42.625C87.6474 42.611 87.8396 42.6478 88.0125 42.7318C88.1854 42.8159 88.3327 42.944 88.4395 43.1032C88.5463 43.2623 88.6086 43.4468 88.6202 43.6378L90.0568 64.3074L90.1261 65.2967L90.7611 74.4493Z"
        fill="#3F3D56"
      />
      <path
        opacity={0.2}
        d="M90.7613 74.4494L80.6924 69.5562L89.1444 64.8195L89.7529 64.4784L90.0571 64.3076L90.1263 65.2968L90.7613 74.4494Z"
        fill="black"
      />
      <path
        d="M107.182 82.4292L81.6743 70.0335L90.1262 65.2968L90.7347 64.9554L101.749 58.7825C102.471 58.378 103.505 58.8337 103.74 59.6076C103.755 59.6601 103.767 59.7136 103.776 59.7677L107.182 82.4292Z"
        fill="#3F3D56"
      />
      <path
        d="M28.4797 84.7913L31.254 84.791L32.5741 74.1504L28.479 74.1506L28.4797 84.7913Z"
        fill="#FFB8B8"
      />
      <path
        d="M27.772 83.8904L33.2359 83.8901C33.6932 83.8901 34.1462 83.9797 34.5687 84.1537C34.9912 84.3276 35.375 84.5827 35.6984 84.9041C36.0217 85.2256 36.2782 85.6073 36.4532 86.0274C36.6282 86.4474 36.7183 86.8976 36.7183 87.3523V87.4648L27.7721 87.4651L27.772 83.8904Z"
        fill="#2F2E41"
      />
      <path
        d="M6.91406 83.0345L9.78864 83.5935L13.3254 72.8354L9.08301 72.0103L6.91406 83.0345Z"
        fill="#FFB8B8"
      />
      <path
        d="M6.36436 81.959L12.0253 83.06L12.0256 83.0601C12.4993 83.1522 12.9502 83.3362 13.3524 83.6016C13.7547 83.8671 14.1004 84.2086 14.3698 84.6069C14.6393 85.0051 14.8272 85.4523 14.9229 85.9227C15.0185 86.3932 15.0201 86.8778 14.9274 87.3489L14.9044 87.4654L5.63574 85.6627L6.36436 81.959Z"
        fill="#2F2E41"
      />
      <path
        d="M32.516 48.0862C32.4221 47.7689 32.2593 47.4759 32.0392 47.2278C31.819 46.9798 31.5469 46.7828 31.2419 46.6507C30.9369 46.5186 30.6064 46.4545 30.2738 46.4631C29.9413 46.4717 29.6146 46.5527 29.3169 46.7004L23.5338 41.0796L22.7358 45.1821L28.3499 49.8907C28.6326 50.3715 29.0828 50.7325 29.6153 50.9054C30.1478 51.0782 30.7256 51.0508 31.2391 50.8283C31.7526 50.6059 32.1662 50.2039 32.4016 49.6985C32.6369 49.1931 32.6776 48.6195 32.516 48.0862Z"
        fill="#FFB8B8"
      />
      <path
        d="M11.0599 19.1549C14.1297 19.1549 16.6182 16.6805 16.6182 13.6282C16.6182 10.5759 14.1297 8.10156 11.0599 8.10156C7.99004 8.10156 5.50146 10.5759 5.50146 13.6282C5.50146 16.6805 7.99004 19.1549 11.0599 19.1549Z"
        fill="#FFB8B8"
      />
      <path
        d="M9.89279 19.2397C10.1881 19.3682 10.7929 17.0284 10.522 16.5604C10.1191 15.8645 10.1429 15.8695 9.87546 15.409C9.60801 14.9486 9.54817 14.3183 9.89451 13.9132C10.2409 13.508 11.0423 13.5618 11.2004 14.0697C11.0987 13.1049 12.0586 12.3293 13.0093 12.1114C13.9601 11.8934 14.9629 12.0288 15.9189 11.8348C17.0284 11.6097 18.1827 10.685 17.74 9.47196C17.6641 9.26999 17.5503 9.08415 17.4049 8.92424C16.8934 8.35249 16.178 8.10835 15.4785 7.87229C14.0233 7.3812 12.5283 6.88394 10.9926 6.93815C9.72721 7.00393 8.50856 7.43506 7.48578 8.17877C6.46299 8.92248 5.68043 9.94652 5.23387 11.1256C5.12803 11.4205 5.04604 11.7234 4.98868 12.0313C4.46155 14.8495 6.10747 17.6293 8.78076 18.7025L9.89279 19.2397Z"
        fill="#2F2E41"
      />
      <path
        d="M8.52806 49.6001C8.3863 49.5149 8.27268 49.3903 8.2012 49.2417C8.12972 49.0931 8.10348 48.927 8.12572 48.7637L5.61753 36.41L3.07509 26.9289C2.79598 25.89 2.88033 24.7876 3.31421 23.8026C3.74809 22.8176 4.50579 22.0085 5.463 21.508L7.1438 19.9268L10.8105 21.4975L12.5529 23.2589C13.8026 23.864 14.9111 24.7223 15.8073 25.7787C16.7035 26.8351 17.3674 28.0663 17.7565 29.3931L20.551 38.9012L27.0048 48.448C27.1125 48.6071 27.1629 48.7977 27.1477 48.989C27.1325 49.1802 27.0527 49.3606 26.9212 49.501C26.0233 50.4613 8.52806 49.6001 8.52806 49.6001Z"
        fill="#2F2E41"
      />
      <path
        d="M25.4364 48.7221L15.9199 42.1651C14.6228 41.2653 13.6838 39.942 13.266 38.4253L10.591 28.7137C10.4914 28.358 10.4646 27.986 10.512 27.6197C10.5595 27.2534 10.6803 26.9004 10.8674 26.5813C11.1214 26.1408 11.4952 25.7804 11.946 25.5416C12.3967 25.3029 12.906 25.1953 13.4155 25.2314C13.9249 25.2675 14.4138 25.4458 14.8259 25.7457C15.238 26.0456 15.5567 26.4551 15.7453 26.927L19.7124 36.6298L28.1651 44.6058C28.2602 44.7207 28.3235 44.8582 28.3486 45.0049C28.3738 45.1516 28.3599 45.3022 28.3084 45.4419L26.6986 48.3034C26.6428 48.4683 26.5364 48.6117 26.3944 48.7134C26.2524 48.8151 26.0819 48.8701 25.9069 48.8706C25.7384 48.8704 25.5741 48.8185 25.4364 48.7221Z"
        fill="#2F2E41"
      />
      <path
        d="M5.66607 81.3876C5.58827 81.2767 5.53845 81.1489 5.52082 81.0148C5.50319 80.8807 5.51828 80.7444 5.56479 80.6174L9.96733 64.7042L10.2928 49.1116L25.1129 48.101L25.1849 48.0962L29.6486 56.1403C31.0461 58.6554 31.9017 61.4322 32.1607 64.2939L33.6317 80.3218C33.654 80.5397 33.5894 80.7575 33.4518 80.9285C33.3142 81.0996 33.1147 81.2101 32.8961 81.2364L28.4274 81.659C28.2121 81.685 27.9951 81.6264 27.8227 81.4956C27.6502 81.3648 27.536 81.1722 27.5044 80.9589L25.6898 64.6715L19.1609 53.7029L16.9693 65.2505L12.4289 81.1973C12.3695 81.3565 12.2628 81.4939 12.1231 81.5914C11.9833 81.6889 11.817 81.7418 11.6462 81.7431L6.35056 81.7453C6.21574 81.746 6.08281 81.7137 5.96346 81.6513C5.84412 81.5889 5.742 81.4984 5.66607 81.3876Z"
        fill="#2F2E41"
      />
      <path
        d="M119.144 133.725H7.46821C5.4882 133.722 3.58993 132.939 2.18986 131.547C0.789781 130.155 0.00223661 128.268 0 126.299V65.3191C0.00223661 63.3504 0.789781 61.463 2.18986 60.0709C3.58993 58.6788 5.4882 57.8958 7.46821 57.8936H21.1605C23.0184 57.892 24.8098 58.5805 26.1835 59.8241L41.032 70.3664C42 71.246 43.2643 71.7333 44.5757 71.7321H119.837C120.827 71.7321 121.807 71.9278 122.72 72.3078C123.634 72.6878 124.462 73.2445 125.157 73.9456C125.851 74.6466 126.399 75.4781 126.768 76.3915C127.137 77.3049 127.319 78.2821 127.305 79.2663L126.611 126.407C126.575 128.355 125.773 130.211 124.377 131.579C122.982 132.946 121.103 133.717 119.144 133.725Z"
        fill="white"
      />
      <path
        d="M118.152 130.125H8.12908C7.17391 130.124 6.25817 129.746 5.58277 129.074C4.90736 128.403 4.52745 127.492 4.52637 126.542V64.5656C4.52745 63.6158 4.90737 62.7053 5.58277 62.0338C6.25818 61.3622 7.17391 60.9845 8.12908 60.9834C8.97392 60.9843 9.78388 61.3185 10.3813 61.9124C10.9787 62.5064 11.3147 63.3118 11.3156 64.1518V122.267C11.3167 123.157 11.6727 124.01 12.3057 124.64C12.9387 125.269 13.7969 125.623 14.6921 125.624H117.78C118.292 125.619 118.792 125.784 119.198 126.093C119.605 126.403 119.896 126.839 120.024 127.332C120.168 127.799 120.15 128.301 119.973 128.757C119.797 129.213 119.471 129.597 119.05 129.848C118.782 130.021 118.471 130.117 118.152 130.125Z"
        fill="#F2F2F2"
      />
      <path
        d="M64.1417 100.361L63.852 100.073C63.792 100.017 63.7568 99.9397 63.7541 99.858C63.7514 99.7763 63.7814 99.6968 63.8376 99.6372C63.8423 99.6322 63.8471 99.6275 63.852 99.6229L66.3935 97.0936C66.4213 97.0641 66.4547 97.0403 66.4918 97.0236C66.5289 97.0069 66.5689 96.9976 66.6096 96.9963C66.6503 96.9949 66.6909 97.0016 66.729 97.0158C66.7671 97.0301 66.802 97.0517 66.8317 97.0793C66.8367 97.084 66.8415 97.0887 66.8461 97.0936L69.3875 99.6206C69.4173 99.6491 69.441 99.6832 69.4575 99.7209C69.474 99.7585 69.4829 99.7991 69.4836 99.8401C69.4843 99.8812 69.4769 99.922 69.4617 99.9602C69.4466 99.9984 69.4241 100.033 69.3954 100.063L69.3875 100.071L69.0979 100.359C69.0692 100.388 69.0349 100.412 68.997 100.428C68.9591 100.445 68.9184 100.453 68.8771 100.454C68.8358 100.455 68.7947 100.447 68.7563 100.432C68.7179 100.417 68.6828 100.395 68.6531 100.366L68.6453 100.359L67.1426 98.7835V102.521C67.1429 102.562 67.1351 102.602 67.1197 102.64C67.1042 102.678 67.0815 102.712 67.0527 102.741C67.0239 102.77 66.9896 102.793 66.9519 102.809C66.9141 102.825 66.8735 102.834 66.8325 102.834L66.8303 102.834H66.4116C66.3281 102.834 66.2481 102.801 66.1891 102.742C66.1302 102.684 66.097 102.604 66.097 102.521V98.7881L64.5943 100.363C64.5361 100.423 64.4565 100.457 64.3731 100.458C64.2896 100.459 64.2092 100.427 64.1495 100.369L64.1417 100.361Z"
        fill="#17B1A4"
      />
      <path
        d="M66.6153 94.7961C67.6354 94.7961 68.6326 95.0969 69.4807 95.6604C70.3289 96.2239 70.9899 97.0248 71.3803 97.9618C71.7707 98.8989 71.8728 99.93 71.6738 100.925C71.4748 101.92 70.9836 102.833 70.2623 103.55C69.541 104.268 68.622 104.756 67.6215 104.954C66.6211 105.152 65.584 105.05 64.6416 104.662C63.6992 104.274 62.8937 103.617 62.327 102.773C61.7602 101.93 61.4577 100.939 61.4577 99.9243C61.4589 98.5646 62.0027 97.2609 62.9697 96.2995C63.9367 95.338 65.2478 94.7973 66.6153 94.7961ZM66.6153 94.1211C65.461 94.1211 64.3325 94.4614 63.3727 95.0991C62.4129 95.7368 61.6648 96.6431 61.2231 97.7035C60.7813 98.7639 60.6658 99.9307 60.891 101.056C61.1162 102.182 61.672 103.216 62.4883 104.028C63.3045 104.839 64.3445 105.392 65.4767 105.616C66.6089 105.84 67.7824 105.725 68.8489 105.286C69.9153 104.847 70.8269 104.103 71.4682 103.148C72.1095 102.194 72.4518 101.072 72.4518 99.9243C72.4518 98.3852 71.8369 96.9091 70.7424 95.8208C69.6478 94.7325 68.1633 94.1211 66.6153 94.1211Z"
        fill="#3F3D56"
      />
      <path
        d="M119.144 133.725H7.46821C5.4882 133.722 3.58993 132.939 2.18986 131.547C0.789781 130.155 0.00223661 128.268 0 126.299V65.3191C0.00223661 63.3504 0.789781 61.463 2.18986 60.0709C3.58993 58.6788 5.4882 57.8958 7.46821 57.8936H21.1605C23.0184 57.892 24.8098 58.5805 26.1835 59.8241L41.032 70.3664C42 71.246 43.2643 71.7333 44.5757 71.7321H119.837C120.827 71.7321 121.807 71.9278 122.72 72.3078C123.634 72.6878 124.462 73.2445 125.157 73.9456C125.851 74.6466 126.399 75.4781 126.768 76.3915C127.137 77.3049 127.319 78.2821 127.305 79.2663L126.611 126.407C126.575 128.355 125.773 130.211 124.377 131.579C122.982 132.946 121.103 133.717 119.144 133.725ZM7.46821 58.3436C5.60821 58.3457 3.82499 59.0813 2.50977 60.389C1.19455 61.6967 0.454732 63.4698 0.452619 65.3191V126.299C0.454732 128.148 1.19455 129.921 2.50977 131.229C3.82499 132.537 5.60821 133.272 7.46821 133.274H119.144C120.984 133.267 122.749 132.544 124.06 131.259C125.371 129.974 126.124 128.231 126.159 126.401L126.852 79.2597C126.866 78.3352 126.694 77.4172 126.348 76.5591C126.001 75.701 125.487 74.92 124.834 74.2614C124.181 73.6029 123.403 73.0799 122.546 72.7229C121.688 72.366 120.767 72.1822 119.837 72.1822H44.5757C43.1561 72.1834 41.7872 71.6577 40.737 70.708L25.8885 60.1657C24.597 58.9922 22.9102 58.3421 21.1605 58.3436H7.46821Z"
        fill="#3F3D56"
      />
      <path
        d="M128.608 134.912H125.834L124.966 124.271L128.609 124.271L128.608 134.912Z"
        fill="#FFB8B8"
      />
      <path
        d="M129.316 137.586L120.37 137.586V137.473C120.37 137.019 120.46 136.569 120.635 136.148C120.81 135.728 121.066 135.347 121.389 135.025C121.713 134.704 122.097 134.449 122.519 134.275C122.942 134.101 123.394 134.011 123.852 134.011L129.316 134.011L129.316 137.586Z"
        fill="#2F2E41"
      />
      <path
        d="M159.612 134.912H156.838L155.518 124.271L159.613 124.271L159.612 134.912Z"
        fill="#FFB8B8"
      />
      <path
        d="M160.32 137.586L151.374 137.586V137.473C151.374 137.019 151.464 136.569 151.639 136.148C151.814 135.728 152.071 135.347 152.394 135.025C152.717 134.704 153.101 134.449 153.524 134.275C153.946 134.101 154.399 134.011 154.856 134.011L160.32 134.011L160.32 137.586Z"
        fill="#2F2E41"
      />
      <path
        d="M150.49 102.018C150.873 101.571 151.089 101.007 151.101 100.42C151.113 99.8337 150.92 99.2611 150.556 98.7996C150.482 98.7112 150.401 98.6291 150.313 98.554L152.403 90.8828L148.92 92.0317L147.47 99.1403C147.229 99.5786 147.122 100.077 147.163 100.575C147.204 101.073 147.391 101.548 147.7 101.942C147.862 102.154 148.069 102.328 148.308 102.45C148.546 102.572 148.809 102.639 149.076 102.646C149.344 102.653 149.61 102.601 149.855 102.492C150.1 102.383 150.317 102.221 150.49 102.018Z"
        fill="#FFB8B8"
      />
      <path
        d="M150.355 97.2988C150.312 97.2988 150.268 97.2955 150.225 97.2891L148.41 97.0169C148.269 96.9928 148.135 96.9382 148.017 96.8571C147.899 96.776 147.801 96.6702 147.728 96.5472C147.571 96.2855 147.514 95.9759 147.569 95.6758L149.833 83.1014L148.298 75.023C148.002 73.4683 148.766 71.8981 150.075 71.3705C150.463 71.2126 150.886 71.1575 151.302 71.2105C151.718 71.2635 152.113 71.4229 152.449 71.673C152.811 71.9458 153.112 72.2913 153.331 72.687C153.55 73.0828 153.683 73.5199 153.721 73.9701L154.808 84.2706L154.795 84.3154L151.297 96.5223C151.247 96.7361 151.128 96.9277 150.958 97.0679C150.788 97.2081 150.576 97.2892 150.355 97.2988Z"
        fill="#17B1A4"
      />
      <path
        d="M149.82 66.9186C152.889 66.9186 155.378 64.4442 155.378 61.3919C155.378 58.3396 152.889 55.8652 149.82 55.8652C146.75 55.8652 144.261 58.3396 144.261 61.3919C144.261 64.4442 146.75 66.9186 149.82 66.9186Z"
        fill="#FFB8B8"
      />
      <path
        d="M146.974 87.082C146.835 87.082 146.698 87.0566 146.569 87.007L137.783 83.6471C137.546 83.5569 137.345 83.3894 137.216 83.1717C137.086 82.9541 137.034 82.6991 137.068 82.4483L137.421 79.8172C137.211 78.8845 137.231 77.9154 137.477 76.9916C137.723 76.0678 138.189 75.2163 138.835 74.509L139.049 74.275L141.648 69.5622C141.824 69.2429 142.062 68.9615 142.348 68.734C142.634 68.5066 142.963 68.3377 143.315 68.2372C143.667 68.1366 144.035 68.1063 144.399 68.1481C144.763 68.1898 145.115 68.3028 145.435 68.4804L150.025 71.0308C150.629 71.3659 151.085 71.9138 151.303 72.5666C151.921 74.4144 151.875 76.4181 151.174 78.2362L148.031 86.3608C147.948 86.5726 147.803 86.7547 147.614 86.8833C147.426 87.0119 147.202 87.0811 146.974 87.082Z"
        fill="#17B1A4"
      />
      <path
        d="M123.95 132.299L123.938 132.297C123.788 132.268 123.646 132.21 123.519 132.125C123.392 132.04 123.285 131.931 123.202 131.803C123.119 131.676 123.063 131.533 123.037 131.384C123.011 131.234 123.015 131.081 123.05 130.933L129.783 101.672C130.885 97.0335 132.408 92.5037 134.332 88.1382L137.196 81.645L147.884 86.4964L149.41 89.2714C150.6 92.3621 151.211 95.6429 151.215 98.9523L162.075 130.597C162.128 130.751 162.146 130.916 162.129 131.078C162.112 131.24 162.06 131.397 161.975 131.537C161.891 131.677 161.777 131.798 161.642 131.89C161.506 131.982 161.352 132.043 161.19 132.07L155.245 132.003C155.189 132.013 155.132 132.018 155.075 132.017C154.85 132.018 154.63 131.951 154.443 131.825C154.257 131.699 154.112 131.52 154.029 131.312L141.615 99.3177C141.597 99.2752 141.567 99.2391 141.528 99.2142C141.489 99.1893 141.444 99.1768 141.398 99.1783C141.352 99.179 141.308 99.1936 141.272 99.22C141.235 99.2465 141.208 99.2835 141.193 99.326L129.414 131.48C129.324 131.73 129.147 131.94 128.916 132.072C128.684 132.204 128.412 132.25 128.15 132.202L123.95 132.299Z"
        fill="#2F2E41"
      />
      <path
        d="M132.077 96.3153C132.391 96.2045 132.676 96.0266 132.913 95.7943C133.149 95.562 133.332 95.2809 133.448 94.9708C133.564 94.6606 133.61 94.329 133.583 93.9993C133.556 93.6696 133.456 93.3499 133.291 93.0625L138.615 87.0094L134.451 86.4448L130.034 92.2795C129.567 92.5868 129.229 93.0538 129.085 93.592C128.941 94.1303 129.001 94.7023 129.253 95.1998C129.504 95.6972 129.931 96.0856 130.452 96.2912C130.972 96.4968 131.551 96.5054 132.077 96.3153Z"
        fill="#FFB8B8"
      />
      <path
        d="M134.587 92.051C134.401 92.0509 134.219 92.0057 134.055 91.9192L132.182 90.9257C132.045 90.8528 131.924 90.7525 131.827 90.631C131.73 90.5095 131.66 90.3695 131.62 90.2197C131.581 90.0699 131.573 89.9135 131.596 89.7605C131.62 89.6074 131.676 89.4609 131.759 89.3301L138.678 78.4893L139.878 70.3085C139.94 69.8857 140.088 69.4798 140.312 69.1154C140.537 68.751 140.833 68.4356 141.184 68.1884C141.534 67.9413 141.932 67.7674 142.352 67.6774C142.772 67.5873 143.206 67.583 143.628 67.6646C144.05 67.7462 144.45 67.9121 144.806 68.1522C145.161 68.3924 145.464 68.7017 145.696 69.0615C145.928 69.4214 146.083 69.8243 146.154 70.2458C146.225 70.6672 146.208 71.0986 146.107 71.5137L143.629 81.597L135.468 91.6338C135.362 91.7645 135.228 91.8699 135.075 91.9421C134.923 92.0143 134.756 92.0515 134.587 92.051Z"
        fill="#17B1A4"
      />
      <path
        d="M144.817 56.832C145.46 54.7357 149.174 53.8004 152.262 54.6702C155.349 55.54 157.568 57.8204 158.084 60.0501C159.227 64.9875 153.263 68.9946 154.344 73.9326C155.059 77.1996 159.195 80.2885 163.854 81.0345C158.72 82.1025 152.454 81.1638 147.693 78.6135C144.73 77.0262 142.244 74.631 142.282 72.215C142.304 70.7522 143.24 69.4587 144.385 68.3411C145.529 67.2236 146.899 66.2302 147.95 65.0637C149 63.8973 149.728 62.5006 149.421 61.0198C149.115 59.539 147.557 57.9948 145.462 57.5639L144.817 56.832Z"
        fill="#2F2E41"
      />
      <path
        d="M175.774 138H89.55C89.49 138 89.4324 137.976 89.39 137.934C89.3476 137.892 89.3237 137.835 89.3237 137.775C89.3237 137.715 89.3476 137.658 89.39 137.616C89.4324 137.574 89.49 137.55 89.55 137.55H175.774C175.834 137.55 175.891 137.574 175.934 137.616C175.976 137.658 176 137.715 176 137.775C176 137.835 175.976 137.892 175.934 137.934C175.891 137.976 175.834 138 175.774 138Z"
        fill="#CCCCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1297_33534">
        <rect width={176} height={138} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default EmptyDoc;
