import { API, METHODS } from '../../constants';
import { fetchData } from '../common/apiCalling';

export const createDocumentCategory = ({ data }) => {
    const url = API.DOCUMENT_CATEGORY;
    return fetchData({ body: data, method: METHODS.POST, url });
};

export const getAllDocumentCategory = ({ offset, limit, search }) => {
    let url = `${API.DOCUMENT_CATEGORY}?offset=${offset}&limit=${limit}`;
    if (search) {
        url = `${url}&search=${encodeURIComponent(search)}`;
    }

    return fetchData({ method: METHODS.GET, url });
};

export const deleteDocumentCategory = (id) => {
    const url = `${API.DOCUMENT_CATEGORY}/${id}`; 
    return fetchData({ method: METHODS.DELETE, url });
};

export const getOneDocumentCategory = (id) => {
    const url = `${API.DOCUMENT_CATEGORY}/${id}`;
    return fetchData({ method: METHODS.GET, url });
};
export const editDocumentCategory = ({id, data}) => {
    const url = `${API.DOCUMENT_CATEGORY}/${id}`;
    return fetchData({ body: data, method: METHODS.PUT, url });
};

export const changeDocumentCategoryStatus = ({ name, status }) => {
    const url = `${API.DOCUMENT_CATEGORY}${API.CHANGE_STATUS}/${name}`;
    return fetchData({ body: { status }, method: METHODS.PUT, url });
}