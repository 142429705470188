import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AdminPageHeader } from '../Common/AdminPagesHeader';
import { DOCUMENT_CATEGORY, DOCUMENT_CATEGORY_COLUMNS } from '../../../constants';
import { Table, DocumentCategoryAddEdit } from '../../shared';
import { usePagination, useDebounce } from '../../../hooks';
import { getAllDocumentCategory, popup, getOneDocumentCategory } from '../../../utils';
import { setLoaderVisibility } from '../../../redux';

export const DocumentCategoryListing = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    let { state } = location;
    const [response, setResponse] = useState({ data: [], count: 0 });
    const [search, setSearch] = useState('');
    const pagination = usePagination(response.data.length, response.count);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [redirected, setRedirected] = useState(state?.id ? true : false);

    const handleAddClick = () => {
        setOpenEditModal(true);
    };

    const getAllDocumentCategoryApi = async () => {
        try {
            const categories = await getAllDocumentCategory({ offset: pagination.offset, limit: pagination.limit, search: search, });
            if (categories?.data) setResponse({ data: categories.data, count: categories.count });
            dispatch(setLoaderVisibility(false));
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    };

    const getOneDocumentCategoryApi = async (id) => {
        try {
            const category = await getOneDocumentCategory(id);
            if (category?.data) setResponse({ data: [{...category?.data, id: id}], count: category.count || 1 });
            dispatch(setLoaderVisibility(false));
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    };

    const debouncedApiCall = useDebounce(() => {
        try {
            if (redirected) {
                getOneDocumentCategoryApi(state?.id);
            } else {
                getAllDocumentCategoryApi();
            }
        } catch (err) {
            dispatch(setLoaderVisibility(false));
            popup('error', err.message);
        }

    }, 500);

    useEffect(() => {
        !search && dispatch(setLoaderVisibility(true));
        debouncedApiCall();
    }, [pagination.limit, pagination.offset, search]);

    useEffect(() => {
        setRedirected(false);
    }, [search]);

    return (
        <>
            <AdminPageHeader
                heading={DOCUMENT_CATEGORY.PAGE_HEADING}
                buttonText={DOCUMENT_CATEGORY.BUTTON_TEXT}
                searchBarPlaceholder={DOCUMENT_CATEGORY.SEARCH_BAR_PLACEHOLDER}
                setSearch={setSearch}
                count={pagination.dataCount}
                showAddButton
                setResponse={setResponse}
                debouncedApiCall={debouncedApiCall}
                handleAddClick={handleAddClick}
                AddModal={
                    openEditModal ? (
                        <DocumentCategoryAddEdit
                            openEditModal={openEditModal}
                            setOpenEditModal={setOpenEditModal}
                            isEdit={false}
                            setResponse={setResponse}
                            debouncedApiCall={getAllDocumentCategoryApi}
                        />
                    ) : (
                        ''
                    )
                }
                setCurrentPage={pagination.setCurrentPage}
                backbutton
            />

            <Table
                columns={DOCUMENT_CATEGORY_COLUMNS}
                data={response.data}
                pagination={pagination}
                pageName={DOCUMENT_CATEGORY.PAGE_NAME}
                debouncedApiCall={debouncedApiCall}
            />
        </>
    );
};
