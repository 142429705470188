import React from 'react';

const Delete = ({ width, height }) => {
    return (
        <svg
            width={width || 14}
            height={height || 14}
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M11.8833 5.3125C11.8833 5.3125 11.5213 9.8025 11.3113 11.6938C11.2113 12.5972 10.6533 13.1265 9.73927 13.1432C7.99994 13.1745 6.25861 13.1765 4.51994 13.1398C3.64061 13.1218 3.09194 12.5858 2.99394 11.6985C2.78261 9.7905 2.42261 5.3125 2.42261 5.3125'
                stroke='#8993A4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.8056 3.15999H1.50024'
                stroke='#8993A4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.6271 3.15998C10.1038 3.15998 9.65313 2.78998 9.55047 2.27732L9.38847 1.46665C9.28847 1.09265 8.9498 0.833984 8.5638 0.833984H5.7418C5.3558 0.833984 5.01713 1.09265 4.91713 1.46665L4.75513 2.27732C4.65247 2.78998 4.2018 3.15998 3.67847 3.15998'
                stroke='#8993A4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default Delete;
