import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

import { BUTTONS } from "../../../constants";

export const GoButton = ({hiringClientId, navigateTo}) => {

    const navigate = useNavigate();

    return (
        <div className='d-flex align-items-center justify-content-center options-set'>
            <button className='ssc-primary-green-btn unique-btn' onClick={()=>{
                navigate(navigateTo, {
                state: {
                    hiringClientId: hiringClientId
                }
            })}}>{BUTTONS.GO}</button>
        </div>
    );
}

GoButton.propTypes = {
    hiringClientId: PropTypes.string,
    navigateTo: PropTypes.string
}
