import React from 'react';

export default function Admin() {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M3 14C3 11.2386 5.23858 9 8 9C10.7614 9 13 11.2386 13 14'
                stroke='#738DA6'
                strokeWidth='1.5'
            />
            <path
                d='M2.25 3C2.25 2.30964 2.80964 1.75 3.5 1.75H12.5C13.1904 1.75 13.75 2.30964 13.75 3V13C13.75 13.6904 13.1904 14.25 12.5 14.25H3.5C2.80964 14.25 2.25 13.6904 2.25 13V3Z'
                stroke='#738DA6'
                strokeWidth='1.5'
            />
            <circle cx='8.5' cy='5.5' r='1.75' stroke='#738DA6' strokeWidth='1.5' />
        </svg>
    );
}
