import React from 'react';
import PropTypes from 'prop-types';
import { EDIT_CUSTOMER as EDIT } from '../../../constants';

export const ConfirmationBox = ({
    setConfirm,
    openConfirmationBox,
    setOpenConfirmationBox,
    customMessage = '',
}) => {
    return (
        <div
            className={`modal custom-modal-doc-cato fade ${openConfirmationBox ? 'show' : ''}`}
            id='exampleModal'
            tabIndex={-1}
            // aria-hidden={!openConfirmationBox}
            aria-labelledby='exampleModalLabel'
            style={{ display: openConfirmationBox ? 'block' : 'none', zIndex: 999999 }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content p-3 '>
                    <div className='d-flex m-1 mx-4'>
                        <div className='w-25 modal-body py-0 d-flex justify-content-center align-items-center'>
                            <span className='d-flex justify-content-center align-items-center bg-warning badge'>
                                !
                            </span>
                        </div>
                        <div className='w-75 modal-body py-0'>
                            <h4 className=' text-center text-special mb-3 '>
                                {EDIT.POPUP_BOX.SURE}
                            </h4>
                            <p className='text-center text-special placeholder-color '>
                                {customMessage === '' ? EDIT.POPUP_BOX.CHANGES_INFO : customMessage}
                                <br />
                            </p>
                        </div>
                    </div>
                    <hr />
                    <div className='pb-3 mt-2 d-flex align-items-center justify-content-between mx-4  modal-btn custom-button'>
                        <button
                            type='button'
                            className='ssc-primary-green-btn col py-0 '
                            ref={(button) => button && button.focus()}
                            onClick={() => {
                                setConfirm(true);
                                setOpenConfirmationBox(false);
                            }}
                        >
                            {EDIT.POPUP_BOX.CONFIRM}
                        </button>
                        <button
                            type='button'
                            className='ssc-secondary-white-btn col py-0'
                            onClick={() => {
                                setConfirm(false);
                                setOpenConfirmationBox(false);
                            }}
                        >
                            {EDIT.POPUP_BOX.CANCEL}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
ConfirmationBox.propTypes = {
    setConfirm: PropTypes.func,
    openConfirmationBox: PropTypes.bool,
    setOpenConfirmationBox: PropTypes.func,
    customMessage: PropTypes.string,
};
