import React from 'react';

export default function Csm() {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M1.75 8C1.75 4.54822 4.54822 1.75 8 1.75C11.4518 1.75 14.25 4.54822 14.25 8C14.25 11.4518 11.4518 14.25 8 14.25C4.54822 14.25 1.75 11.4518 1.75 8Z'
                stroke='#738DA6'
                strokeWidth='1.5'
            />
            <path
                d='M12.5 12C11.7994 10.2455 9.57186 9 7.5 9C5.50587 9 3.75041 10.349 3 12'
                stroke='#738DA6'
                strokeWidth='1.5'
            />
            <circle cx='8.5' cy='5.5' r='1.75' stroke='#738DA6' strokeWidth='1.5' />
        </svg>
    );
}
