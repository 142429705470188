import { API, METHODS } from '../../constants';
import { constructURL } from '../common';
import { fetchData } from '../common/apiCalling';

// POST : hiringClientIds could be a single string or an Array(bulk)
export const hideHiringClients = ({ accountId, hiringClientIds = [] }) => {
    let url = `${API.HIDDEN_HIRING_CLIENT}`;

    return fetchData({
        body: { account_id: accountId, hiring_client_ids: hiringClientIds },
        method: METHODS.POST,
        url,
    });
};

// DELETE:  hiringClientIds must be an Array
export const showHiringClients = ({ accountId, hiringClientIds = [] }) => {
    let url = `${API.HIDDEN_HIRING_CLIENT}`;
    return fetchData({
        body: { account_id: accountId, hiring_client_ids: hiringClientIds },
        method: METHODS.DELETE,
        url,
    });
};

// GET : Gives All HCs associated to an account for a given type(default : hidden)
// account_id(required).
export const getHiddenHiringClients = ({ accountId, type, offset, limit, search, filter }) => {
    const queryParams = {
        account_id: accountId,
        type: type ? type : 'hidden',
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        filter: filter ? filter : '',
        search: search ? encodeURIComponent(search) : '',
    };

    let url = constructURL(API.HIDDEN_HIRING_CLIENT, queryParams);
    return fetchData({
        method: METHODS.GET,
        url,
    });
};
