import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ScoreCardDeleteModal from './ScoreCardDeleteModal';
import { Edit, Delete, Email, History } from '../../../assets/svg';
import { SCORECARD_REPORT, ROUTES, INFO } from '../../../constants';
import { ConfirmationBox } from '../../shared';
import { popup, sendReport } from '../../../utils';
import { setLoaderVisibility } from '../../../redux';
import EmailReportModal from '../../shared/Modal/EmailReportModal';
import { useSelector } from 'react-redux';

export const AdminScoreCardListingOperation = ({ data, debouncedApiCall }) => {
    //declaration

    const navigate = useNavigate();
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [selectedData, setSelected] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);
    const dispatch = useDispatch();
    const { isUserAdmin } = useSelector((s) => s.user);

    const [confirm, setConfirm] = useState(false);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
    const [accountId, setAccountId] = useState(null);

    // functions

    const handleEditClick = (editData) => {
        navigate(ROUTES.ADMIN.SCORE_CARD_REPORT_ADMIN, {
            state: {
                accountUserDetails: {
                    id: editData.account_id,
                    name: editData.account_name,
                    platform_id: editData.platform_id,
                },
            },
        });
    };

    const handleDeleteModalClick = (data) => {
        setSelected(data);
        setIsDeleteModal(true);
    };

    const handleSendReport = async (account_id) => {
        setOpenConfirmationBox(true);
        setAccountId(account_id);
    };

    useEffect(() => {
        async function handleSendReport() {
            if (confirm) {
                setIsDisabled(true);
                try {
                    dispatch(setLoaderVisibility(true));
                    const reportResponse = await sendReport({ id: accountId });
                    if (reportResponse.status === 'success') popup('success', INFO.SEND_REPORT_CSM);
                } catch (error) {
                    popup('error', error.message || INFO.NO_PRIMARY_USER);
                } finally {
                    dispatch(setLoaderVisibility(false));
                    setIsDisabled(false);
                    setConfirm(false);
                }
            }
        }
        handleSendReport();
    }, [confirm]);

    return (
        <>
            <div className='d-flex align-items-center justify-content-start gap-2'>
                <button
                    id='action-btn'
                    className='border-0 p-0 bg-white'
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal-edit'
                    onClick={() => handleEditClick(data)}
                    title='Edit'
                >
                    <Edit />
                </button>

                <button
                    id='action-btn'
                    className='border-0 p-0 bg-white'
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal-del'
                    onClick={() => handleDeleteModalClick(data)}
                    title='Delete'
                >
                    <Delete />
                </button>
                {isUserAdmin && (
                    <button
                        id='action-btn'
                        className='border-0 p-0 bg-white'
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target='#exampleModal-del'
                        onClick={() => handleSendReport(data.account_id)}
                        disabled={isDisabled}
                        title='Send Report'
                    >
                        <Email />
                    </button>
                )}
                {isUserAdmin && (
                    <button
                        className='border-0 p-0 bg-white text-bg-light'
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target='#exampleModal-del'
                        onClick={() => setShowReportModal(true)}
                        title='View Report Archive'
                        id='action-btn'
                    >
                        <History />
                    </button>
                )}
            </div>

            {isDeleteModal && (
                <ScoreCardDeleteModal
                    isDeleteScorecardByAccountAndPlatform={true}
                    isDeleteModal={isDeleteModal}
                    setIsDeleteModal={setIsDeleteModal}
                    selectedData={selectedData}
                    deleteButtonName={SCORECARD_REPORT.SCORE_CARD_LISTING_PAGE.DELETE_BUTTON}
                    debouncedApiCall={debouncedApiCall}
                />
            )}
            {showReportModal && (
                <EmailReportModal
                    showModal={showReportModal}
                    setShowModal={setShowReportModal}
                    accountId={data?.account_id}
                />
            )}
            {openConfirmationBox && (
                <ConfirmationBox
                    setConfirm={setConfirm}
                    openConfirmationBox={openConfirmationBox}
                    setOpenConfirmationBox={setOpenConfirmationBox}
                    customMessage={INFO.CONFIRM_SEND_REPORT}
                />
            )}
        </>
    );
};

AdminScoreCardListingOperation.propTypes = {
    data: PropTypes.shape({
        account_id: PropTypes.string.isRequired,
        account_name: PropTypes.string.isRequired,
    }).isRequired,
    debouncedApiCall: PropTypes.func,
};
