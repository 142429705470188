import React from 'react';
import { ButtonComponent } from '../Buttons/ButtonComponent';
import { BackArrow } from '../../../assets/svg';
import { useNavigate } from 'react-router-dom';
import { LEGEND_DETAILS } from '../../../constants/strings';

const LegendPage = () => {
    const navigate = useNavigate();
    return (
        <main className='customer-dashboard-view'>
            {/* page header  */}
            <header
                id='AdminPageHeader01'
                className='ssc-header-title d-flex align-items-center justify-content-between mb-4'
            >
                <div className='d-flex align-items-center'>
                    <ButtonComponent
                        className='d-flex align-items-center border-0 bg-white'
                        type='button'
                        clickHandler={() => navigate(-1)}
                    >
                        <BackArrow />
                    </ButtonComponent>
                    <h1 className='title-btn mb-3 d-flex align-items-center'>
                        <span style={{ marginLeft: '10px' }}> Legend</span>
                    </h1>
                </div>
            </header>
            <section className='d-flex w-100 flex-wrap gap-4'>
                <div className='dashboard-box'>
                    <header className='dashboard-box-title'>Platform Grade Color</header>
                    <div className='divider'></div>
                    <div className='d-flex flex-column gap-2 px-3'>
                        {LEGEND_DETAILS.map((lg) => {
                            return (
                                <div key={lg.id} className='d-flex gap-3 align-items-center'>
                                    <div className={`grade-circle ${lg.color}-circle`}></div>
                                    <span>{lg.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </main>
    );
};

export default LegendPage;
