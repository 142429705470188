import React from 'react';
import PropTypes from 'prop-types';


const IconHover2 = ({ className='', width = 14, height = 12 }) => (
    <svg
        className = {className}
        width={width}
        height={height}
        viewBox='0 0 14 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.10693 6.03476C9.10693 7.19876 8.16294 8.14209 6.99893 8.14209C5.83493 8.14209 4.8916 7.19876 4.8916 6.03476C4.8916 4.87009 5.83493 3.92676 6.99893 3.92676C8.16294 3.92676 9.10693 4.87009 9.10693 6.03476Z'
            stroke='#E2F1F7'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.99834 10.903C9.53701 10.903 11.859 9.07766 13.1663 6.03499C11.859 2.99233 9.53701 1.16699 6.99834 1.16699H7.00101C4.46234 1.16699 2.14034 2.99233 0.833008 6.03499C2.14034 9.07766 4.46234 10.903 7.00101 10.903H6.99834Z'
            stroke='#E2F1F7'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

IconHover2.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
}

export default IconHover2;
