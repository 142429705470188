import * as React from 'react';
const ViewIcon = (props) => (
    <svg
        width={props.width ?? '16px'}
        height={props.height ?? '16px'}
        stroke='currentColor'
        fill={props.fill ?? 'currentColor'}
        viewBox='0 0 59.2 59.2'
        className='view-icon'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <g>
            <path d='M51.062,21.561c-5.759-5.759-13.416-8.931-21.561-8.931S13.7,15.801,7.941,21.561L0,29.501l8.138,8.138 c5.759,5.759,13.416,8.931,21.561,8.931s15.802-3.171,21.561-8.931l7.941-7.941L51.062,21.561z M49.845,36.225 c-5.381,5.381-12.536,8.345-20.146,8.345s-14.765-2.963-20.146-8.345l-6.724-6.724l6.527-6.527 c5.381-5.381,12.536-8.345,20.146-8.345s14.765,2.963,20.146,8.345l6.724,6.724L49.845,36.225z' />
            <path d='M29.572,16.57c-7.168,0-13,5.832-13,13s5.832,13,13,13s13-5.832,13-13S36.741,16.57,29.572,16.57z M29.572,24.57 c-2.757,0-5,2.243-5,5c0,0.552-0.448,1-1,1s-1-0.448-1-1c0-3.86,3.14-7,7-7c0.552,0,1,0.448,1,1S30.125,24.57,29.572,24.57z' />
        </g>
    </svg>
);
export default ViewIcon;
