/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setLoaderVisibility } from '../../../redux';
import { DeleteConfirmationModal, ButtonComponent, RegulatoryAddEdit } from '../../shared';
import { Edit, Delete } from '../../../assets/svg';
import { deleteRegulatoryIssues, editRegulatoryIssues, popup } from '../../../utils';
import { REGULATORY_ISSUE, SUCCESS } from '../../../constants';

export const RegulatoryIssueOperation = ({ data, debouncedApiCall }) => {
    const dispatch = useDispatch();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const handleDeleteClick = useCallback(async () => {
        try {
            dispatch(setLoaderVisibility(true));
            await deleteRegulatoryIssues({ id: data.id });
            debouncedApiCall();
            popup(SUCCESS.SUCCESS_TOAST, REGULATORY_ISSUE.DELETED_MESSAGE);
            setIsDeleteModalOpen(false);
        } catch (error) {
            popup('error', error.message);
            dispatch(setLoaderVisibility(false));
        }
    }, [data]);

    const handleEditClick = useCallback(
        async ({ broadcast, regulatoryIssue }) => {
            try {
                const body = {
                    broadcast,
                    title: regulatoryIssue.title,
                    content: regulatoryIssue.content,
                };
                dispatch(setLoaderVisibility(true));
                await editRegulatoryIssues({ id: data.id, body });
                debouncedApiCall();
                popup(SUCCESS.SUCCESS_TOAST, REGULATORY_ISSUE.UPDATED_MESSAGE);
                setIsEditModalOpen(false);
            } catch (error) {
                popup('error', error.message);
                dispatch(setLoaderVisibility(false));
            }
        },
        [data],
    );

    return (
        <>
            <div className='d-flex gap-2 align-items-center justify-content-start options-set margin-left-8'>
                <ButtonComponent
                    className='border-0 p-0 bg-transparent'
                    type='button'
                    clickHandler={() => setIsEditModalOpen(true)}
                    id='action-btn'
                >
                    <Edit />
                </ButtonComponent>
                <ButtonComponent
                    className='border-0 p-0 bg-transparent margin-left-8'
                    type='button'
                    clickHandler={() => setIsDeleteModalOpen(true)}
                    id='action-btn'
                >
                    <Delete />
                </ButtonComponent>
            </div>
            {isEditModalOpen && (
                <RegulatoryAddEdit
                    data={data}
                    isModalOpen={isEditModalOpen}
                    setIsModalOpen={setIsEditModalOpen}
                    debouncedApiCall={debouncedApiCall}
                    handleEditClick={handleEditClick}
                    modalType='edit'
                />
            )}
            {isDeleteModalOpen && (
                <DeleteConfirmationModal
                    isDeleteModal={isDeleteModalOpen}
                    setIsDeleteModal={setIsDeleteModalOpen}
                    selectedItemId={data.id}
                    handleDeleteClick={handleDeleteClick}
                    deleteButtonName={REGULATORY_ISSUE.PAGE_NAME}
                />
            )}
        </>
    );
};

RegulatoryIssueOperation.propTypes = {
    data: PropTypes.object.isRequired,
    debouncedApiCall: PropTypes.func,
};
