import { getHiringClients, getAllAccounts, getAllPlatforms, getPlatformById, updateScoreCardDetails, createScoreCardDetails, deleteScoreCardDetails, popup } from '../../../utils';
import { ERROR } from '../../../constants';

export const handleOutsideClickFn = (setOpenEditModal) => (e) => {
    if (e.target === e.currentTarget) {
        setOpenEditModal(false);
    }
};

export const handleSearchChangeFn = (setSearchQuery) => (event) => {
    const searchQuery = event.target.value.trim();
    setSearchQuery(searchQuery);
};

export const handleHiringClientDropdownFn = (setSearchQuery, setIsHiringClientOpen) => () => {
    setSearchQuery('');
    setIsHiringClientOpen((prev) => !prev);
};

export const handleCustomerDropdownFn = (setSearchQuery, setIsCustomerOpen, setCustomers, setOpenPlatformDropdown) => () => {
    setCustomers([])
    setSearchQuery('');
    setOpenPlatformDropdown(false);
    setIsCustomerOpen((prev) => !prev);
};

export const handleTPADropdownFn = (setSearchQuery=null, setIsHiringClientOpen, setIsTPAOpen) => () => {
    setSearchQuery && setSearchQuery('');
    setIsTPAOpen((prev) => !prev);
    setIsHiringClientOpen(false);
};
export const handleGradeDropdownFn = (setSearchQuery=null, setShowGradeDropDown, setShowTpaDropDown) => () => {
    setSearchQuery && setSearchQuery('');
    setShowGradeDropDown((prev) => !prev);
    setShowTpaDropDown(false);
};

export const handleHiringClientSelectFn = (setSearchQuery, setSelectedHiringClient, setIsHiringClientOpen, setIsSaveClicked) => (item) => {
    setSearchQuery('');
    setSelectedHiringClient(item);
    setIsHiringClientOpen(false);
    setIsSaveClicked && setIsSaveClicked(false);
};

export const handleCustomerSelectFn = (setSearchQuery, setSelectedCustomer, setIsCustomerOpen) => (item) => {
    setSearchQuery('');
    setSelectedCustomer(item);
    setIsCustomerOpen(false);
};

export const handleTPASelectFn = ({ setSearchQuery=null, setTpaValue, setIsTPAOpen, setIsSaveClicked, setGrade, setSelectedTpa }) => (item) => {
    setIsSaveClicked && setIsSaveClicked(false);
    setSearchQuery && setSearchQuery('');
    setGrade && setGrade(item.grade);
    item.id !== 'all' && setTpaValue(item)
    setIsTPAOpen(false);
    item.id !== 'all' && setSelectedTpa(item)
};
export const handleGradeSelectFn = ({ setSearchQuery=null, setGrade, setShowGradeDropDown }) => (item) =>  {
    setSearchQuery && setSearchQuery('');
    item !== 'all' ? setGrade(item) : '';
    setShowGradeDropDown(false);
};

export const handlePendingChangeFn = (setPending) => (e) => {
    setPending(e.target.value);
};

export const handleNotesChangeFn = (setNotes) => (e) => {
    setNotes(e.target.value);
};

export const handleStatusChangeFn = (setStatus, setIsSaveClicked) => (e) => {
    setStatus(e.target.value);
    setIsSaveClicked(false)
};

export const getAllHiringClients = async (customer_id, searchQuery, setHiringClients) => {
    try {
        const res = await getHiringClients({ account_user_id: customer_id });
        if (res.status === 'error') {
            popup('error', res.message);
        } else if (res?.data) {
            setHiringClients(res.data);
        }
    } catch (error) {
        console.error(error);
    }
};
export const getHiringClientsById = async ({ account_id, platform_id, setHiringClients, searchQuery }) => {
    try {
        const res = await getHiringClients({account_id: account_id, platform_id, search: searchQuery});
        if (res.status === 'error') {
            popup('error', res.message);
        } else if (res?.data) {
            setHiringClients(res.data);
        }
    } catch (error) {
        console.error(error);
    }
};

export const getAllCustomerApi = async ({ setCustomers, searchQuery }) => {
    try {
        const res = await getAllAccounts({search: searchQuery});
        if (res.status === 'error') {
            popup('error', res.message);
        } else if (res?.data) {
            setCustomers([]);
            res.data.map(item => {
                item?.platforms.length && setCustomers((prevState) => ([...prevState, item]));
            })
        }
    } catch (error) {
        console.error(error);
    }
};

export const getAllTpas = async (setTpa, searchQuery) => {
    try {
        let search = searchQuery ? searchQuery : ''
        const res = await getAllPlatforms(1, 1000, search);
        if (res.status === 'error') {
            popup('error', res.message)
        } else if (res?.data) {
            setTpa(res.data);
        }
    } catch (error) {
        console.error(error);
    }
};
export const getPlatformsById = async ({ accountId, setTpa }) => {
    try {
        const res = await getPlatformById({ accountId, account_id: true });
        if (res.status === 'error') {
            popup('error', res.message)
        } else if (res?.data) {
            setTpa(res.data);
        }
    } catch (error) {
        console.error(error);
    }
};

const checkHiringClientIdExists = (scorecards, data) => {
    return scorecards.some(scorecard => scorecard.hiring_client_id === data.hiring_client_id);
};

export const handleSaveFn = async ({
    isEdit,
    selectedPlatform,
    selectedHiringClient,
    notes,
    selectedData,
    accountId,
    scorecards,
    selectedHiringClientGrade
}) => {
    try {
        if(selectedHiringClient.id) {
            if (isEdit) {
                const data = {
                    ...(selectedHiringClient.id && { hiring_client_id: selectedHiringClient.id }),
                    id: selectedData.id,
                    platform_id: selectedData?.platform_id,
                    account_id: accountId,
                    ...(notes && { notes }),
                    hiring_client_grade: selectedHiringClientGrade ? selectedHiringClientGrade : null
                };

                const res = await updateScoreCardDetails({ updatedData: data });
                if (res.status === 'success') {
                    return { success: true, type: 'update' };
                }
                popup('error', res.message);
                return { success: false };

            } else {
                const data = {
                    ...(selectedHiringClient?.id && { hiring_client_id: selectedHiringClient.id }),
                    platform_id: selectedPlatform?.id,
                    account_id: accountId,
                    ...(notes && { notes }),
                    hiring_client_grade: selectedHiringClientGrade ? selectedHiringClientGrade : null
                };

                const isHiringClientExists = checkHiringClientIdExists(scorecards, data);

                if (!isHiringClientExists) {
                    const res = await createScoreCardDetails({ data });

                    if (res.status === 'success') {
                        return { success: true, type: 'create' };
                    }

                    popup('error', res.message);
                    return { success: false };
                } else {
                    popup('error', ERROR.HIRING_CLIENT_ALREADY_EXISTS);
                    return { sucess: false };
                }
            }
        } else {
            popup('error', ERROR.NO_HIRING_CLIENT_SELECTED);
            return { sucess: false };
        }

    } catch (error) {
        console.error(error);
        popup('error', error.message);
        return { success: false };
    };
};
export const handleDeleteFn = async (id) => {

    const res = await deleteScoreCardDetails({ id: id })
    if (res.status === 'success') {
        return true;
    }
    popup('error', res.message);
    return false;
};
