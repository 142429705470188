import React, { useRef, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { SSCLogo, EyeIcon, OpenEyeIcon } from '../../../assets/svg';
import { createCustomer, encryptPassword, formValidator, popup, handleScroll } from '../../../utils';
import { AUTH, ROUTES, MAX_LENGTH, SIGNUP_VALIDATION, SOURCE } from '../../../constants';

export function Signup() {
    const navigate = useNavigate();
    let userInputSectionRef = useRef();
    const scrollBar = useRef(null);

    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [isPasswordFieldFocused, setIsPasswordFieldFocused] = useState(false);
    const [isFormFocused, setIsFormFocused] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);

    const checkPasswordStrength = (regex, password) => regex.test(password);

    const validateForm = () => setIsFormFocused(true);

    const handleScrolling = () => {
        handleScroll(setIsScrolling, scrollBar, 2000);
    };

    // For Scrolling to the bottom of the userInputSection
    useEffect(() => {
        if (isPasswordFieldFocused)
            userInputSectionRef.current.scrollTop = userInputSectionRef.current.scrollHeight;
    }, [isPasswordFieldFocused]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest('.user-input-section') === null)
                setTimeout(() => {
                    setIsFormFocused(false);
                }, 100);
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Formik
            initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                account: '',
                password: '',
                confirmPassword: '',
            }}
            validate={(values) => {
                const errors = {};
                formValidator({ values, errors, validationSchema: SIGNUP_VALIDATION.SCHEMA });
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                createCustomer({
                    data: {
                        first_name: values.firstName,
                        last_name: values.lastName,
                        email: values.email,
                        account_name: values.account,
                        password: encryptPassword(values.password),
                        confirm_password: encryptPassword(values.confirmPassword),
                        source: SOURCE.PORTAL
                    },
                })
                    .then((response) => {
                        setSubmitting(false);
                        navigate(ROUTES.CUSTOMER.LOGIN);
                        popup('success', response.message);
                    })
                    .catch((error) => {
                        setSubmitting(false);
                        popup('error', error.message);
                    });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                dirty,
                isSubmitting,
                setTouched,
            }) => (
                <>
                    <form
                        onSubmit={handleSubmit}
                        className='w-100 cust-form d-flex align-items-center justify-content-center flex-column'
                    >
                        <SSCLogo width={233} height={44} className={'mb-4'} />
                        <h6 className='text-center'>{AUTH.CREATE_AN_ACCOUNT}</h6>
                        <div
                            onScroll={handleScrolling}
                            onMouseMove={handleScrolling}
                            className={`user-input-section w-100 ${isScrolling ? 'scrolling' : 'not-scrolling'}`}
                            ref={userInputSectionRef}
                            onFocus={() => validateForm()}
                        >
                            <div className='input-set w-100 input-set-gap'>
                                <label htmlFor=''>{AUTH.FIRST_NAME}</label>
                                <div className='position-relative'>
                                    <input
                                        maxLength={MAX_LENGTH.NAME}
                                        type='text'
                                        name='firstName'
                                        onChange={(e) => {
                                            !touched.firstName &&
                                                setTouched({ ...touched, firstName: true });
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                        className={`form-control ${isFormFocused && errors.firstName && touched.firstName && errors.firstName ? 'border border-danger-subtle border-1' : ''}`}
                                        placeholder={AUTH.ENTER_FIRST_NAME}
                                    />
                                    {isFormFocused && touched.firstName && errors.firstName && (
                                        <p className='error-message'>{errors.firstName}</p>
                                    )}
                                </div>
                            </div>
                            <div className='input-set w-100 input-set-gap'>
                                <label htmlFor=''>{AUTH.LAST_NAME}</label>
                                <div className='position-relative'>
                                    <input
                                        maxLength={MAX_LENGTH.NAME}
                                        type='text'
                                        name='lastName'
                                        onChange={(e) => {
                                            !touched.lastName &&
                                                setTouched({ ...touched, lastName: true });
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.lastName}
                                        className={`form-control ${isFormFocused && errors.lastName && touched.lastName && errors.lastName ? 'border border-danger-subtle border-1' : ''}`}
                                        placeholder={AUTH.ENTER_LAST_NAME}
                                    />
                                    {isFormFocused && touched.lastName && errors.lastName && (
                                        <p className='error-message'>{errors.lastName}</p>
                                    )}
                                </div>
                            </div>
                            <div className='input-set w-100 input-set-gap'>
                                <label htmlFor=''>{AUTH.EMAIL_ADDRESS}</label>
                                <div className='position-relative'>
                                    <input
                                        maxLength={MAX_LENGTH.EMAIL}
                                        type='email'
                                        name='email'
                                        onChange={(e) => {
                                            !touched.email &&
                                                setTouched({ ...touched, email: true });
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        className={`form-control ${isFormFocused && errors.email && touched.email && errors.email ? 'border border-danger-subtle border-1' : ''}`}
                                        placeholder={AUTH.ENTER_EMAIL_ADDRESS}
                                    />
                                    {isFormFocused && touched.email && errors.email && (
                                        <p className='error-message'>{errors.email}</p>
                                    )}
                                </div>
                            </div>
                            <div className='input-set w-100 input-set-gap'>
                                <label>{AUTH.ACCOUNT_NAME}</label>
                                <div className='position-relative'>
                                    <input
                                        maxLength={MAX_LENGTH.ACCOUNT_NAME}
                                        type='text'
                                        name='account'
                                        onChange={(e) => {
                                            !touched.account &&
                                                setTouched({ ...touched, account: true });
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.account}
                                        className={`form-control ${isFormFocused && errors.account && touched.account && errors.account ? 'border border-danger-subtle border-1' : ''}`}
                                        placeholder={AUTH.ENTER_ACCOUNT_NAME}
                                    />
                                    {isFormFocused && touched.account && errors.account && (
                                        <p className='error-message'>{errors.account}</p>
                                    )}
                                </div>
                            </div>
                            <div className='input-set w-100 input-set-gap'>
                                <div>
                                    <label htmlFor=''>{AUTH.PASSWORD}</label>
                                    <div className='position-relative'>
                                        <input
                                            maxLength={MAX_LENGTH.PASSWORD_FIELD}
                                            type={passwordShow ? 'text' : 'password'}
                                            name='password'
                                            onChange={(e) => {
                                                !touched.password &&
                                                    setTouched({ ...touched, password: true });
                                                handleChange(e);
                                            }}
                                            onMouseDown={(e) => {
                                                if (e.target.closest('.eye-icon'))
                                                    e.preventDefault();
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    !e.relatedTarget ||
                                                    !e.relatedTarget.closest('.eye-icon')
                                                ) {
                                                    handleBlur(e);
                                                    setIsPasswordFieldFocused(false);
                                                }
                                            }}
                                            onFocus={() => {
                                                setIsPasswordFieldFocused(true);
                                            }}
                                            value={values.password}
                                            className={`form-control ${isFormFocused && errors.password && touched.password && errors.password ? 'border border-danger-subtle border-1' : ''}`}
                                            placeholder={AUTH.ENTER_PASSWORD}
                                        />
                                        {isFormFocused && touched.password && errors.password && (
                                            <p className='error-message'>{errors.password}</p>
                                        )}
                                        <button
                                            type='button'
                                            onClick={() => {
                                                setPasswordShow((prev) => !prev);
                                            }}
                                            className='eye-icon top-12px border-0 position-absolute'
                                        >
                                            {passwordShow ? (
                                                <OpenEyeIcon width={16} height={16} />
                                            ) : (
                                                <EyeIcon />
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='input-set w-100'>
                                <div>
                                    <label>{AUTH.CONFIRM_PASSWORD}</label>
                                    <div className='position-relative'>
                                        <input
                                            maxLength={MAX_LENGTH.PASSWORD_FIELD}
                                            type={confirmPasswordVisible ? 'text' : 'password'}
                                            name='confirmPassword'
                                            onChange={(e) => {
                                                !touched.confirmPassword &&
                                                    setTouched({
                                                        ...touched,
                                                        confirmPassword: true,
                                                    });
                                                handleChange(e);
                                            }}
                                            onBlur={(e) => {
                                                if (
                                                    !e.relatedTarget ||
                                                    !e.relatedTarget.closest('.eye-icon')
                                                ) {
                                                    handleBlur(e);
                                                    setIsPasswordFieldFocused(false);
                                                }
                                            }}
                                            onFocus={() => {
                                                setIsPasswordFieldFocused(true);
                                            }}
                                            value={values.confirmPassword}
                                            className={`form-control ${isFormFocused && errors.confirmPassword && touched.confirmPassword && errors.confirmPassword ? 'border border-danger-subtle border-1' : ''}`}
                                            placeholder={AUTH.CONFIRM_NEW_PASSWORD}
                                        />
                                        {isFormFocused &&
                                            touched.confirmPassword &&
                                            errors.confirmPassword && (
                                                <p className='error-message'>
                                                    {errors.confirmPassword}
                                                </p>
                                            )}
                                        <button
                                            type='button'
                                            onClick={() =>
                                                setConfirmPasswordVisible((visible) => !visible)
                                            }
                                            className='eye-icon top-12px border-0 position-absolute'
                                        >
                                            {confirmPasswordVisible ? (
                                                <OpenEyeIcon width={16} height={16} />
                                            ) : (
                                                <EyeIcon />
                                            )}
                                        </button>
                                    </div>
                                </div>
                                {isFormFocused && (
                                    <div className='row new-pwd-indicator mt-3'>
                                        {SIGNUP_VALIDATION.PASSWORD_REQUIREMENT.map((requirement, index) => (
                                            <div className='col-6' key={index}>
                                                <div className='d-flex align-items-center line-set'>
                                                    <div
                                                        className={`indicator-dot ${checkPasswordStrength(requirement.regex, values.password) ? 'dot-checked' : ''}`}
                                                    />
                                                    <p className='mb-0'>{requirement.label}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='w-100 extra-spacing-rl'>
                            <button
                                type='submit'
                                className='ssc-primary-green-btn'
                                disabled={
                                    isSubmitting ||
                                    !dirty ||
                                    errors.firstName ||
                                    errors.lastName ||
                                    errors.email ||
                                    errors.account ||
                                    errors.password ||
                                    errors.confirmPassword
                                }
                            >
                                {AUTH.SIGN_UP}
                            </button>
                        </div>
                        <div className='mt-4 d-flex align-items-center justify-content-center form-bottom'>
                            <p className='mb-0'>{AUTH.ALREADY_HAVE_AN_ACCOUNT}</p>
                            <Link to={ROUTES.CUSTOMER.LOGIN} className='link'>
                                {AUTH.LOGIN}
                            </Link>
                        </div>
                    </form>
                </>
            )}
        </Formik>
    );
}