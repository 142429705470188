import * as React from 'react';
const DownloadIcon = (props) => (
    <svg
        height={props.height ?? '16px'}
        width={props.width ?? '16px'}
        strokeWidth={props.strokeWidth ?? 0}
        stroke='currentColor'
        fill={props.fill ?? 'currentColor'}
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            d='M3 14V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V14'
            stroke='currentColor'
            fill='none'
            strokeWidth={2}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12 3V17M12 17L7 11.5555M12 17L17 11.5556'
            stroke='currentColor'
            strokeWidth={2}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
export default DownloadIcon;
