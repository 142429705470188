import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BackArrow, EyeIcon, OpenEyeIcon, SSCLogo } from '../../../assets/svg';
import { MAX_LENGTH, ROUTES, VALIDATION, REGEX, AUTH } from '../../../constants';

import {
    encryptPassword,
    formValidator,
    popup,
    resetPassword,
    validateResetLink,
} from '../../../utils';

export function ResetPassword() {
    const navigation = useNavigate();
    const userInputSectionRef = useRef();
    const [isFormFocused, setIsFormFocused] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [token, setToken] = useState('');

    const validationSchema = {
        password: {
            regex: REGEX.STRONG_PASSWORD,
            message: VALIDATION.STRONG_PASSWORD_REQUIRED,
            requiredMessage: VALIDATION.PASSWORD_REQUIRED,
        },
        confirmPassword: {
            matchWith: 'password',
            regex: REGEX.STRONG_PASSWORD,
            message: VALIDATION.PASSWORD_DONT_MATCH,
            requiredMessage: VALIDATION.CONFIRM_PASSWORD_REQUIRED,
        },
    };

    // Regular expressions for password strength requirements
    const passwordStrengthRequirements = [
        { regex: REGEX.SMALL_CHARACTER, label: AUTH.SMALL_CHARACTER },
        { regex: REGEX.CAPITAL_CASE_CHARACTER, label: AUTH.CAPITAL_CASE_CHARACTER },
        { regex: REGEX.NUMERIC_CHARACTER, label: AUTH.NUMERIC_CHARACTER },
        { regex: REGEX.SPECIAL_CHARACTER, label: AUTH.SPECIAL_CHARACTER },
        { regex: REGEX.MINIMUM_LENGTH_10, label: AUTH.MINIMUM_LENGTH_10 },
        { regex: REGEX.MAXIMUM_LENGTH_16, label: AUTH.MAXIMUM_LENGTH_16 },
    ];

    const checkPasswordStrength = (regex, password) => regex.test(password);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                userInputSectionRef.current &&
                !userInputSectionRef.current.contains(event.target)
            ) {
                setTimeout(() => {
                    setIsFormFocused(false);
                }, 100);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const token = query.get('token');
        setToken(token);
        validateResetLink({ body: { token } }).catch((error) => {
            popup('error', error?.message || 'link_invalid');
            navigation(ROUTES.CUSTOMER.LOGIN);
        });
        return () => {
            setToken('');
        };
    }, []);

    const validateForm = () => setIsFormFocused(true);
    return (
        <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validate={(values) => {
                const errors = {};
                formValidator({ values, errors, validationSchema });
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                resetPassword({
                    body: {
                        new_password: encryptPassword(values.password),
                        confirm_password: encryptPassword(values.confirmPassword),
                        token,
                    },
                    headers: {},
                })
                    .then((response) => {
                        setSubmitting(false);
                        navigation(ROUTES.CUSTOMER.LOGIN);
                        popup('success', response.message);
                    })
                    .catch((error) => {
                        setSubmitting(false);
                        popup('error', error.message);
                    });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                dirty,
                isSubmitting,
                setTouched,
            }) => (
                <form
                    onSubmit={handleSubmit}
                    className='w-100 forget-form d-flex align-items-start justify-content-start flex-column'
                >
                    <div className='extra-spacing-rl'>
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigation(ROUTES.CUSTOMER.LOGIN)}
                        >
                            <SSCLogo width={233} height={44} className={'mb-4'} />
                        </span>
                        <div className='forget-title-bar mt-3'>
                            <h3 className='mb-3'>{AUTH.RESET_PASSWORD}</h3>
                            <h5 className='mb-0'>{AUTH.CREATE_A_NEW_PASSWORD}</h5>
                        </div>
                    </div>
                    <div
                        className='user-input-section w-100'
                        ref={userInputSectionRef}
                        onFocus={validateForm}
                    >
                        <div className='input-set w-100 input-set-gap'>
                            <label>{AUTH.NEW_PASSWORD}</label>
                            <div className='position-relative'>
                                <input
                                    maxLength={MAX_LENGTH.PASSWORD_FIELD}
                                    type={passwordVisible ? 'text' : 'password'}
                                    name='password'
                                    onChange={(e) => {
                                        !touched.password &&
                                            setTouched({ ...touched, password: true });
                                        handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    className={`form-control ${isFormFocused && errors.password && touched.password && errors.password ? 'border border-danger-subtle border-1' : ''}`}
                                    placeholder={AUTH.ENTER_NEW_PASSWORD}
                                />
                                {isFormFocused && touched.password && errors.password && (
                                    <p className='error-message'>{errors.password}</p>
                                )}
                                <button
                                    type='button'
                                    onClick={() => setPasswordVisible((visible) => !visible)}
                                    className='eye-icon top-12px border-0 position-absolute'
                                >
                                    {passwordVisible ? (
                                        <OpenEyeIcon width={16} height={16} />
                                    ) : (
                                        <EyeIcon />
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className='input-set w-100'>
                            <div>
                                <label>{AUTH.CONFIRM_PASSWORD}</label>
                                <div className='position-relative'>
                                    <input
                                        maxLength={MAX_LENGTH.PASSWORD_FIELD}
                                        type={confirmPasswordVisible ? 'text' : 'password'}
                                        name='confirmPassword'
                                        onChange={(e) => {
                                            !touched.confirmPassword &&
                                                setTouched({ ...touched, confirmPassword: true });
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.confirmPassword}
                                        className={`form-control ${isFormFocused && errors.confirmPassword && touched.confirmPassword && errors.confirmPassword ? 'border border-danger-subtle border-1' : ''}`}
                                        placeholder={AUTH.CONFIRM_NEW_PASSWORD}
                                    />
                                    {isFormFocused &&
                                        touched.confirmPassword &&
                                        errors.confirmPassword && (
                                            <p className='error-message'>
                                                {errors.confirmPassword}
                                            </p>
                                        )}
                                    <button
                                        type='button'
                                        onClick={() =>
                                            setConfirmPasswordVisible((visible) => !visible)
                                        }
                                        className='eye-icon top-12px border-0 position-absolute'
                                    >
                                        {confirmPasswordVisible ? (
                                            <OpenEyeIcon width={16} height={16} />
                                        ) : (
                                            <EyeIcon />
                                        )}
                                    </button>
                                </div>
                            </div>
                            {isFormFocused && (
                                <div className='row new-pwd-indicator mt-3'>
                                    {passwordStrengthRequirements.map((requirement, index) => (
                                        <div className='col-6' key={index}>
                                            <div className='d-flex align-items-center line-set'>
                                                <div
                                                    className={`indicator-dot ${checkPasswordStrength(requirement.regex, values.password) ? 'dot-checked' : ''}`}
                                                />
                                                <p className='mb-0'>{requirement.label}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='w-100 extra-spacing-rl'>
                        <button
                            type='submit'
                            className='ssc-primary-green-btn'
                            disabled={
                                !token ||
                                isSubmitting ||
                                !dirty ||
                                errors.password ||
                                errors.confirmPassword
                            }
                        >
                            {AUTH.RESET_PASSWORD}
                        </button>
                    </div>
                    <div className='mt-4 back-btn extra-spacing-rl'>
                        <Link
                            to={ROUTES.CUSTOMER.LOGIN}
                            className='d-flex align-items-center justify-content-center'
                        >
                            <BackArrow />
                            <p>{AUTH.BACK_TO_LOGIN}</p>
                        </Link>
                    </div>
                </form>
            )}
        </Formik>
    );
}
