import React, {useState} from 'react';
import PropTypes from 'prop-types';

import { MODAL } from '../../../constants';

export const GradeSelectModal = ({ isGradeChangeModal, handleUpdateClick, setGradeChangeConfirmationModalVisibility, heading }) => {

    const [selectedGrade, setSelectedGrade ] = useState('');

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            setGradeChangeConfirmationModalVisibility(prevState => !prevState);
        }
    };
    return (
        <div
            className={`modal custom-modal-doc-cato fade ${isGradeChangeModal ? 'show' : ''}`}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!isGradeChangeModal}
            role='dialog'
            style={{ display: isGradeChangeModal ? 'block' : 'none' }}
            onClick={handleOutsideClick}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-end'>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={() => {
                                setGradeChangeConfirmationModalVisibility(false);
                            }}
                        />
                    </div>
                    <div className='modal-body p-0'>
                        <h3 className='text-center del-title mt-0 font-16px modal-text'>
                            {heading}
                        </h3>
                    </div>
                    <div className='d-flex align-items-center justify-content-center gap-5 mb-4 mt-3'>
                        <button className={`ssc-secondary-white-btn btn-grey-border ${selectedGrade === 'red' ? 'grade-btn-active' : ''}`} onClick={() => setSelectedGrade('red') }>
                            <div className='dot-circle red me-2'></div>
                            <div className="label-color font-16px"><span>Red</span></div>
                        </button>
                        <button className={`ssc-secondary-white-btn btn-grey-border ${selectedGrade === 'yellow' ? 'grade-btn-active' : ''}`} onClick={() => setSelectedGrade('yellow')}>
                            <div className='dot-circle yellow me-2'></div>
                            <div className="label-color font-16px"><span>Yellow</span></div>
                        </button>
                        <button className={`ssc-secondary-white-btn btn-grey-border ${selectedGrade === 'green' ? 'grade-btn-active' : ''}`} onClick={() => setSelectedGrade('green')}>
                            <div className='dot-circle green me-2'></div>
                            <div className="label-color font-16px"><span>Green</span></div>
                        </button>
                    </div>
                    <div className='d-flex align-items-center justify-content-end modal-btn'>
                        <button
                            type='button'
                            className='ssc-secondary-white-btn '
                            onClick={(e)=>{
                                e.stopPropagation();
                                setGradeChangeConfirmationModalVisibility(false);
                                handleUpdateClick(selectedGrade);
                            }}
                        >
                            {MODAL.UPDATE_BUTTON}
                        </button>
                        <button
                            type='button'
                            className='ssc-primary-green-btn py-0 w-auto'
                            onClick={(e) => {
                                e.stopPropagation();
                                setGradeChangeConfirmationModalVisibility(false);
                            }}
                        >
                            {MODAL.CANCEL_BUTTON}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

GradeSelectModal.propTypes = {
    isConfirmModal: PropTypes.bool,
    setGradeChangeConfirmationModalVisibility: PropTypes.func,
    handleUpdateClick: PropTypes.func,
    heading: PropTypes.string
};

