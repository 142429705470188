import  React from "react";

const DownArrow = ({ width, height, rotate = false }) => (
    <svg
        width={ width || 12 }
        height={ height || 8 }
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={ rotate ? { transform: 'rotate(180deg)' } : {} }
    >
    <path
        d="M10.6663 1.6665L5.99967 6.33317L1.33301 1.6665"
        stroke="#8993A4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
    />
    </svg>
);

export default DownArrow;
