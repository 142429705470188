import React from "react";

function ImgEdit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <circle cx="12.5" cy="12" r="11.5" fill="#D9D9D9" stroke="#fff"></circle>
      <path
        fill="#6A6C77"
        fillRule="evenodd"
        d="M15.592 8.935c.02.035.055.06.1.06 1.21 0 2.2.988 2.2 2.197v2.992c0 1.21-.99 2.197-2.2 2.197h-5.689a2.2 2.2 0 01-2.199-2.197v-2.992a2.2 2.2 0 012.2-2.197c.04 0 .08-.02.095-.06l.03-.06.054-.113c.129-.272.272-.572.36-.749.232-.453.625-.705 1.115-.71h2.375c.49.005.888.257 1.12.71.08.159.2.414.317.66l.071.151.05.111zm-.369 1.936a.456.456 0 10.454-.458.456.456 0 00-.454.459zm-3.247.78c.237-.237.544-.363.872-.363.328 0 .636.126.868.358.232.231.358.539.358.866a1.23 1.23 0 01-2.093.867 1.214 1.214 0 01-.358-.867v-.005a1.183 1.183 0 01.353-.856zm2.27 2.264a1.982 1.982 0 01-3.38-1.4 1.949 1.949 0 01.575-1.397 1.978 1.978 0 011.407-.584c.53 0 1.029.207 1.402.58.373.377.58.876.58 1.4a1.996 1.996 0 01-.585 1.4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ImgEdit;
