import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROLES, ROUTES } from '../../../constants';
import { ButtonComponent } from '../Buttons/ButtonComponent';
import SliderButton from '../../../assets/img/icons/slider-btn.webp';
import {
    Admin,
    Csm,
    Customer,
    DocumentRequest,
    DashboardIcon,
    Documents,
    DocumentCategory,
    ScoreCard,
    HiringClients,
    Broadcast,
} from '../../../assets/svg';
import { useSelector } from 'react-redux';

export function SideBar({ sidebarFor, currentDashboardRoute, setCurrentDashboardRoute }) {
    const [show_sidebar, toggleSidebar] = useState(false);
    const role = useSelector((s) => s?.user?.role);
    const sidebarOptions =
        sidebarFor === 'admin'
            ? [
                  {
                      name: 'Dashboard',
                      icon: DashboardIcon,
                      route: [ROUTES.ADMIN.DASHBOARD],
                      allowedRoles: [ROLES.ADMIN, ROLES.CSM, ROLES.CONTENT_MANAGER],
                  },
                  {
                      name: 'Document Categories',
                      icon: DocumentCategory,
                      route: [ROUTES.ADMIN.DOCUMENT_CATEGORY],
                      allowedRoles: [ROLES.ADMIN, ROLES.CSM],
                  },
                  {
                      name: 'Documents',
                      icon: Documents,
                      route: [ROUTES.ADMIN.DOCUMENTS],
                      allowedRoles: [ROLES.ADMIN, ROLES.CSM],
                  },
                  {
                      name: 'Admins',
                      icon: Admin,
                      route: [ROUTES.ADMIN.ADMIN],
                      allowedRoles: [ROLES.ADMIN, ROLES.CSM],
                  },
                  {
                      name: 'CSMs',
                      icon: Csm,
                      route: [ROUTES.ADMIN.CSM],
                      allowedRoles: [ROLES.ADMIN, ROLES.CSM, ROLES.CONTENT_MANAGER],
                  },
                  {
                      name: 'Customers',
                      icon: Customer,
                      route: [
                          ROUTES.ADMIN.CUSTOMER,
                          ROUTES.ADMIN.NEWS_FEED_ADMIN,
                          ROUTES.ADMIN.CUSTOMER_EDIT,
                          ROUTES.ADMIN.CUSTOMER_HIRING_CLIENT_ORDER,
                      ],
                      allowedRoles: [ROLES.ADMIN, ROLES.CSM, ROLES.CONTENT_MANAGER],
                  },
                  {
                      name: 'Request Document',
                      icon: DocumentRequest,
                      route: [ROUTES.ADMIN.DOCUMENT_REQUEST],
                      allowedRoles: [ROLES.ADMIN, ROLES.CSM],
                  },
                  {
                      name: 'Scorecards',
                      icon: ScoreCard,
                      route: [
                          ROUTES.ADMIN.ADMIN_SCORE_CARD_LISTING,
                          ROUTES.ADMIN.SCORE_CARD_REPORT_ADMIN,
                      ],
                      allowedRoles: [ROLES.ADMIN, ROLES.CSM],
                  },
                  {
                      name: 'Regulatory Issue',
                      icon: Broadcast,
                      route: [ROUTES.ADMIN.ADMIN_REGULATORY_ISSUE],
                      allowedRoles: [ROLES.ADMIN, ROLES.CSM],
                  },
                  {
                      name: 'Digital Safety Library',
                      icon: DocumentCategory,
                      route: [ROUTES.ADMIN.DIGITAL_SAFETY_LIBRARY],
                      allowedRoles: [ROLES.ADMIN, ROLES.CSM, ROLES.CONTENT_MANAGER],
                  },
              ]
            : [
                  {
                      name: 'Dashboard',
                      icon: DashboardIcon,
                      route: [ROUTES.CUSTOMER.DASHBOARD],
                  },
                  {
                      name: 'Documents',
                      icon: Documents,
                      route: [ROUTES.CUSTOMER.DOCUMENTS],
                  },
                  {
                      name: 'Scorecard',
                      icon: ScoreCard,
                      route: [ROUTES.CUSTOMER.SCORE_CARD_REPORT],
                  },
                  {
                      name: 'Hiring Clients',
                      icon: HiringClients,
                      route: [ROUTES.CUSTOMER.HIRING_CLIENTS],
                  },
                  {
                      name: 'Digital Safety Library',
                      icon: DocumentCategory,
                      route: [ROUTES.CUSTOMER.DIGITAL_SAFETY_LIBRARY],
                  },
              ];

    const clickHandler = () => {
        toggleSidebar(!show_sidebar);
    };

    return (
        <>
            <ButtonComponent
                className={`ssc-slider-btn position-absolute d-flex align-items-center justify-content-center d-lg-none d-block  bg-white p-1 ${show_sidebar ? 'open' : ''}`}
                text={''}
                type={'button'}
                id={'toggleButton'}
                clickHandler={clickHandler}
            >
                <img src={SliderButton} alt='arrow-icon' width='14' height='14' />
            </ButtonComponent>
            <div
                className={`col-lg-2 ssc-sliderbar ${show_sidebar ? 'show' : ''}`}
                id='MenuSidebarMobileView2'
            >
                <ul className='link-area mb-0 ps-0'>
                    {sidebarOptions.map((option, index) => {
                        return sidebarFor === 'admin' ? (
                            option?.allowedRoles.includes(role) ? (
                                <li className='links-set' key={index}>
                                    <Link
                                        onClick={() => {
                                            setCurrentDashboardRoute(option.route[0]);
                                        }}
                                        to={option.route[0]}
                                        className={`d-flex align-items-center justify-content-start link-line ${option.route.includes(currentDashboardRoute) && 'active'}`}
                                        title={option.name}
                                    >
                                        <option.icon color='#738DA6' height={16} width={16} />
                                        <span>{option.name}</span>
                                    </Link>
                                </li>
                            ) : null
                        ) : (
                            <li className='links-set' key={index}>
                                <Link
                                    onClick={() => {
                                        setCurrentDashboardRoute(option.route[0]);
                                    }}
                                    to={option.route[0]}
                                    className={`d-flex align-items-center justify-content-start link-line ${option.route.includes(currentDashboardRoute) && 'active'}`}
                                    title={option.name}
                                >
                                    <option.icon color='#738DA6' height={16} width={16} />
                                    <span>{option.name}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

SideBar.propTypes = {
    sidebarFor: PropTypes.string.isRequired,
    currentDashboardRoute: PropTypes.string.isRequired,
    setCurrentDashboardRoute: PropTypes.func.isRequired,
};
