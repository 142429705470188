import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BUTTONS, EDIT_CUSTOMER, SYMBOL } from '../../../constants';
import { useDebounce } from '../../../hooks';
import { getAccountByID, popup, updateHiringClientOrder } from '../../../utils';
import { Heading } from './Heading';
import { SectionBox } from './SectionBox';
import { ConfirmationBox } from '../../shared';

export const CSMHiringClientOrder = () => {
    const [tpa, setTpa] = useState('');
    const [accountData, setAccountData] = useState({});
    const [data, setData] = useState([]);
    const [hiringClients, setHiringClients] = useState([]);
    const [initialHiringClients, setInitialHiringClients] = useState([]);
    const [orderingList, setOrderingList] = useState({});

    const [confirm, setConfirm] = useState(false);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);

    const location = useLocation();
    const id = location.state?.id;

    // get all data
    const debouncedApiCall = useDebounce(() => {
        getAccountByID({ id: id })
            .then((res) => {
                if (res?.data) setAccountData(res.data);
            })
            .catch((error) => {
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        debouncedApiCall();
    }, [id]);

    useEffect(() => {
        if (accountData && accountData.platformsWithHC) {
            setData(accountData?.platformsWithHC);
            setTpa(accountData?.platformsWithHC?.[0]);
            setInitialHiringClients(transformData(accountData?.platformsWithHC));
        }
    }, [accountData]);

    useEffect(() => {
        if (tpa && initialHiringClients[tpa.id]) {
            setHiringClients(initialHiringClients[tpa.id]);
        }
    }, [tpa]);

    function transformData(data) {
        const result = {};
        initialHiringClients;
        data.forEach((item) => {
            if (item.hiringClients) {
                result[item.id] = item.hiringClients;
            } else {
                result[item.id] = [];
            }
        });

        return result;
    }

    const onDragEnd = async (e) => {
        if (!e.destination) return;
        if (e.source.index === e.destination.index) return;
        const sorted = reorder(hiringClients, e.source.index, e.destination.index);
        setHiringClients(sorted);
        const updatedHiringClients = {
            ...initialHiringClients,
            [tpa.id]: sorted,
        };
        setInitialHiringClients(updatedHiringClients);
        const data = sorted.map(({ achcp_id, hc_order }) => ({ achcp_id, hc_order }));
        const ordered = {
            ...orderingList,
            [tpa.id]: data,
        };
        setOrderingList(ordered);
    };
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    useEffect(() => {
        if (confirm) {
            updateHiringClientOrder({ data: orderingList });
            setOrderingList({});
            popup('success', 'Changes are saved!');
        }
    }, [confirm]);

    return (
        <>
            <div className='edit-box-customer'>
                <Heading data={accountData || ''} heading={EDIT_CUSTOMER.HEADING} />
                <div className='tabs-set'>
                    <div className='tabs-row d-flex align-items-center justify-content-start'>
                        <span
                            className={`d-flex align-items-center justify-content-center link-profile active`}
                        >
                            {'Manage Hiring Client'}
                        </span>
                    </div>
                </div>
                <SectionBox>
                    <div className='d-flex flex-column justify-content-between align-item-center ps-lg-2 pe-lg-2 '>
                        <div className='d-flex justify-content-start  align-item-center gap-3 '>
                            {data.length ? (
                                <div className='d-flex w-50 justify-content-between align-item-center'>
                                    <div className='d-flex w-50 align-item-center gap-2 justify-content-start'>
                                        {data.map((platform) => (
                                            <span
                                                key={platform.id}
                                                className={`cursor-pointer ${platform.id === tpa.id ? 'ssc-primary-green-btn' : 'ssc-secondary-white-btn'} button-green-filled`}
                                                onClick={() => setTpa(platform)}
                                            >
                                                {platform?.name ?? ''}
                                                <sup>{SYMBOL.REGISTERED}</sup>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <span className='d-flex align-items-center ms-1 '>
                                    {EDIT_CUSTOMER.BUTTON_TEXT.NO_PLATFORM}
                                </span>
                            )}
                        </div>
                    </div>

                    <div className='new-table-responsiveness'>
                        <div className='ssc-table-area draggable'>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId='Table'>
                                    {(provided) => (
                                        <table
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className='w-100 mt-3'
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Hiring Client</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {hiringClients?.length ? (
                                                    hiringClients?.map((item, index) => (
                                                        <Draggable
                                                            key={item.id}
                                                            draggableId={item.id}
                                                            index={index}
                                                        >
                                                            {(provided) => (
                                                                <tr
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className='border-bottom'
                                                                >
                                                                    <td>{item?.name}</td>
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    ))
                                                ) : (
                                                    <tr className='text-special text-center ms-5'>
                                                        <td>{EDIT_CUSTOMER.BUTTON_TEXT.NO_HC}</td>
                                                    </tr>
                                                )}
                                                {provided.placeholder}
                                            </tbody>
                                        </table>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end mb-1'>
                        <button
                            type='button'
                            className={`form-white-button d-flex align-items-center justify-content-center border-0 cursor-pointer me-3`}
                            onClick={() => {
                                setOrderingList({});
                                setHiringClients(tpa?.hiringClients);
                                setInitialHiringClients(
                                    transformData(accountData?.platformsWithHC),
                                );
                            }}
                        >
                            {EDIT_CUSTOMER.BUTTON_TEXT.CANCEL}
                        </button>
                        <button
                            type='submit'
                            className='ssc-primary-green-btn button-green-filled form-button d-flex align-items-center justify-content-center border-0'
                            onClick={() => setOpenConfirmationBox(true)}
                            disabled={isEmpty(orderingList)}
                        >
                            {BUTTONS.SAVE_BUTTON}
                        </button>
                    </div>
                </SectionBox>

                <ConfirmationBox
                    setConfirm={setConfirm}
                    openConfirmationBox={openConfirmationBox}
                    setOpenConfirmationBox={setOpenConfirmationBox}
                    customMessage='Do you want to save changes?'
                />
            </div>
        </>
    );
};
