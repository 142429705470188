import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DOCUMENT_REQUEST_FORM, ROUTES } from '../../../constants';

export default function DocumentRequestSubmit({ res, setRes }) {
    const navigate = useNavigate();
    return (
        <div
            className={`modal custom-modal-doc-cato fade ${res ? 'show' : ''}`}
            id='exampleModal'
            aria-hidden={!res}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            style={{ display: res ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content p-3 border-0'>
                    <div className='d-flex align-items-center justify-content-end pt-3'>
                        <button
                            type='button'
                            className='btn-close me-3'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={() => {
                                setRes(false);
                            }}
                        />
                    </div>
                    <div className='modal-body py-0'>
                        <h5 className='text-center document-modal-title'>
                           {DOCUMENT_REQUEST_FORM.FORM_SUCCESS}<br/>
                           {DOCUMENT_REQUEST_FORM.SUCCESS_MESSAGE}
                        </h5>
                    </div>
                    <div className='pb-3 d-flex align-items-center justify-content-center mx-5 mb-3 modal-btn custom-button'>
                        <button
                            type='button'
                            className='ssc-secondary-white-btn col-5'
                            onClick={() => {
                                return navigate(ROUTES.ADMIN.DOCUMENTS);
                            }}
                        >
                            {DOCUMENT_REQUEST_FORM.VIEW_DOCUMENT}
                        </button>
                        <button
                            type='button'
                            className='ssc-primary-green-btn col py-0'
                            onClick={() => {
                                setRes(false);
                            }}
                        >
                           {DOCUMENT_REQUEST_FORM.NEW_DOCUMENT}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
