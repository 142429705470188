import React from 'react';

export default function Scorecard() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='14'
            height='14'
            fill='none'
            viewBox='0 0 14 14'
        >
            <path
                stroke='#738DA6'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
                d='M4.223 5.92v4.117M7.023 3.952v6.085M9.777 8.096v1.941'
            ></path>
            <path
                fillRule='evenodd'
                stroke='#738DA6'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
                d='M9.811 1H4.19C2.229 1 1 2.387 1 4.351V9.65C1 11.613 2.223 13 4.189 13H9.81C11.777 13 13 11.613 13 9.649V4.35C13 2.387 11.777 1 9.811 1z'
                clipRule='evenodd'
            ></path>
        </svg>
    );
}
