import React from "react";
import PropTypes from 'prop-types';

const CustomDropdownArrow = ({width, height}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill="none"
			viewBox="0 0 11 6"
		>
			<path
				fill="#738DA6"
				d="M.478.313A.5.5 0 011.13.265l.056.048 4.313 4.313L9.812.313a.5.5 0 01.65-.048l.057.048a.5.5 0 01.048.651l-.048.056-4.667 4.667a.5.5 0 01-.65.048l-.057-.048L.478 1.02a.5.5 0 010-.707z"
			></path>
		</svg>
	);
}

CustomDropdownArrow.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number
}

CustomDropdownArrow.defaultProps = {
	width: 11,
	height: 6
}

export default CustomDropdownArrow;