// themeSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorageItem, setLocalStorageItem } from '../../utils';
import { LOCAL_STORAGE } from '../../constants';
import { REDUX } from '../../constants';

const initialState = {
    isDarkMode: getLocalStorageItem(LOCAL_STORAGE.DARK_MODE) === 'true', // Initialize from localStorage
};

const themeSlice = createSlice({
    name: REDUX.slice.THEME,
    initialState,
    reducers: {
        toggleTheme: (state) => {
            state.isDarkMode = !state.isDarkMode;
            setLocalStorageItem(LOCAL_STORAGE.DARK_MODE, state.isDarkMode.toString());
        },
        setTheme: (state, action) => {
            state.isDarkMode = action.payload;
            setLocalStorageItem(LOCAL_STORAGE.DARK_MODE, action.payload.toString());
        },
    },
});

export const { toggleTheme, setTheme } = themeSlice.actions;
export default themeSlice.reducer;
