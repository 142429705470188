import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setLoaderVisibility } from '../../../redux';
import { DELETE_MODAL, BUTTONS, SUCCESS } from '../../../constants';
import { handleDeleteFn } from './ScoreCardsFunctions';
import { popup, deleteScorecardByAccountAndPlatform } from '../../../utils';
import { ButtonComponent } from '../../shared';

const ScoreCardDeleteModal = ({ isDeleteScorecardByAccountAndPlatform, isDeleteModal, setIsDeleteModal, selectedData, deleteButtonName, debouncedApiCall }) => {
    const dispatch = useDispatch();

    const handleDeleteClick = async () => {
        const success = isDeleteScorecardByAccountAndPlatform ? 
            await deleteScorecard() : 
            await deleteScorecardEntry();

        if (success) {
            dispatch(setLoaderVisibility(true));
            setIsDeleteModal(false);
            await debouncedApiCall();
            setTimeout(() => {
                popup('error', SUCCESS.SCORE_CARD_COLUMNS_ADMIN.DELETED);
            }, 500);
        }
    };

    const deleteScorecardEntry = async () => {
        const success = await handleDeleteFn(selectedData.id);
        return success;
    };

    const deleteScorecard = async () => {
        const success = deleteScorecardByAccountAndPlatform({
            accountId: selectedData.account_id,
            platformId: selectedData.platform_id
        });
        return success;
    };

    return (
        <div
            className={`modal custom-modal-doc-cato fade ${isDeleteModal ? 'show' : ''}`}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!isDeleteModal}
            role='dialog'
            style={{ display: isDeleteModal ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-end'>
                        <ButtonComponent clickHandler={() => setIsDeleteModal(false)} className='btn-close'></ButtonComponent>
                    </div>
                    <div className='modal-body p-0'>
                        <h3 className='text-center del-title mt-0'>
                            {`${DELETE_MODAL.DELETE_HEADING} ${deleteButtonName}`}
                        </h3>
                        <p className='text-center dl-para'>{DELETE_MODAL.PARA_TEXT}<br />{DELETE_MODAL.UNDO_TEXT}</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-center modal-btn'>
                        <ButtonComponent clickHandler={handleDeleteClick} className='ssc-secondary-white-btn'>
                            {DELETE_MODAL.DELETE_BUTTON}
                        </ButtonComponent>
                        <ButtonComponent clickHandler={() => setIsDeleteModal(false)} className='ssc-primary-green-btn py-0 w-auto'>
                            {BUTTONS.CANCEL_BUTTON}
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScoreCardDeleteModal;
ScoreCardDeleteModal.propTypes = {
    isDeleteModal: PropTypes.bool,
    setIsDeleteModal: PropTypes.func,
    selectedItemId: PropTypes.string,
    deleteButtonName: PropTypes.string
};

