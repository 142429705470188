import React from 'react';

export default function DashboardIcon() {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <mask id='path-1-inside-1_947_2846' fill='white'>
                <rect x='1' y='1' width='6.06061' height='7.27273' rx='1' />
            </mask>
            <rect
                x='1'
                y='1'
                width='6.06061'
                height='7.27273'
                rx='1'
                stroke='#738DA6'
                strokeWidth='3'
                mask='url(#path-1-inside-1_947_2846)'
            />
            <mask id='path-2-inside-2_947_2846' fill='white'>
                <rect x='1' y='9.18188' width='6.06061' height='5.15152' rx='1' />
            </mask>
            <rect
                x='1'
                y='9.18188'
                width='6.06061'
                height='5.15152'
                rx='1'
                stroke='#738DA6'
                strokeWidth='3'
                mask='url(#path-2-inside-2_947_2846)'
            />
            <mask id='path-3-inside-3_947_2846' fill='white'>
                <rect x='8.27271' y='1' width='6.06061' height='5.15152' rx='1' />
            </mask>
            <rect
                x='8.27271'
                y='1'
                width='6.06061'
                height='5.15152'
                rx='1'
                stroke='#738DA6'
                strokeWidth='3'
                mask='url(#path-3-inside-3_947_2846)'
            />
            <mask id='path-4-inside-4_947_2846' fill='white'>
                <rect x='7.96973' y='7.06055' width='6.36364' height='7.27273' rx='1' />
            </mask>
            <rect
                x='7.96973'
                y='7.06055'
                width='6.36364'
                height='7.27273'
                rx='1'
                stroke='#738DA6'
                strokeWidth='3'
                mask='url(#path-4-inside-4_947_2846)'
            />
        </svg>
    );
}
