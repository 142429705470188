import React from 'react';
import { UnauthenticatedTemplate } from '@azure/msal-react';
import { SSCLogo } from '../../../assets/svg';
import SSOIcon from '../../../assets/img/sso-icon.webp';
import { ButtonComponent } from '../../shared';
import { getAdminLoginURL } from '../../../utils/apiServices/SSOLogin';
import { SSO } from '../../../constants';

export const SSOLogin = () => {
    const goToDashboard = async (event) => {
        event.preventDefault();
        const response = await getAdminLoginURL();
        window.location.href = response.data;
    };

    return (
        <UnauthenticatedTemplate>
            <div className='w-100 admin-form d-flex align-items-center justify-content-center flex-column'>
                <SSCLogo width={233} height={44} />
                <ButtonComponent
                    className='bg-white admin-login-btn d-flex align-items-center justify-content-center'
                    clickHandler={goToDashboard}
                >
                    <img src={SSOIcon} alt='icon' width='24' height='24' />
                    <span>{SSO.LOGIN_TEXT}</span>
                </ButtonComponent>
            </div>
        </UnauthenticatedTemplate>
    );
}
