import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerByID, getAllDocumentRequestByAccount, popup, setLocalStorageItem } from '../../../utils';
import CSMProfileCard from './CSMProfileCard';
import CustomerTPABox from './CustomerTPABox';
import CustomerDashboardHiringClients from './CustomerDashboardHiringClients';
import CustomerDashboardDocumentRequests from './CustomerDashboardDocumentRequests';
import CustomerFeedback from './CustomerFeedback';
import { setLoaderVisibility } from '../../../redux';
import { CustomerNewsFeed } from './CustomerNewsFeed';
import { LOCAL_STORAGE } from '../../../constants';

// TODO: /* USING DUMMY DATA UNTIL THE API IS INTEGRATED */

const DashboardContent = () => {
    const [csmData, setCsmData] = useState('');
    const [tpaData, setTPAData] = useState([]);
    const [documentRequests, setDocumentRequestData] = useState([]);
    const customerId = useSelector((state) => state.accountArray.selectedAccount);
    const filteredDocumentRequests = documentRequests.filter((req) => req.status === 'requested' || req.status ===  'rejected'); 

    const dispatch = useDispatch();

    const fetchData = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            const offset = 1;
            const limit = 21; 
            const [customerResponse, docRequestResponse] = await Promise.all([
                getCustomerByID({
                    customerID: customerId,
                    fetchCSM: true,
                    fetchHiringClient: true,
                    fetchPlatform: true,
                }),
                getAllDocumentRequestByAccount({ account_id: customerId, offset, limit }),
            ]).catch((error) => {
                throw error;
            });

            if (customerResponse?.data) {
                setCsmData(customerResponse.data[0].csm[0]);
                setTPAData(customerResponse.data[0].platforms);
                setLocalStorageItem(LOCAL_STORAGE.PLATFORMS_LENGTH, customerResponse?.data[0]?.platforms?.length ?? 0);
            }
            if (docRequestResponse?.data) {
                setDocumentRequestData(docRequestResponse.data);
            }
            return dispatch(setLoaderVisibility(false));
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    };

    useEffect(() => {
        if (customerId) {
            fetchData();
            dispatch(setLoaderVisibility(true)); // TODO: Need to fix listing css issue
        }
    }, [customerId]);

    return (
        <div className='customer-dashboard-view'>
            <div className='row main-content h-100'>
                <div className='col-9 custom-col-set'>
                    <div className='h-100'>
                        <div className='row main-content-inner mb-32'>
                            <CSMProfileCard
                                csmName={csmData?.full_name}
                                csmImageLink={csmData?.image}
                            />
                            <CustomerTPABox tpaData={tpaData} />
                        </div>
                        <div className='row main-content-inner'>
                            <CustomerDashboardHiringClients platforms={tpaData} />
                            <CustomerDashboardDocumentRequests
                                documentRequests={filteredDocumentRequests}
                                showViewAllButton={filteredDocumentRequests.length >= 20}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-3 custom-col-set'>
                    <div className='h-100 flex-column d-flex align-items-center justify-content-between w-100 ssc-activity-bar'>
                        <CustomerNewsFeed />
                        <CustomerFeedback />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardContent;
