import { API, METHODS } from '../../constants';
import { fetchData } from '../common/apiCalling';
import { constructURL } from '../common';

export const getAllNotificationsByAccountFilters = ({offset, limit, include_all_notifications, accountId, filter}) => {

    const queryParams = {
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        account_id: accountId ? accountId : '',
        include_all_notifications: include_all_notifications ? true : false,
        filter: filter ? JSON.stringify(filter) : ''
    }

    const url = constructURL(API.NOTIFICATION, queryParams);

    return fetchData({ method: METHODS.GET, url });
};

export const putMarkAsReadNotification = ({notificationId, accountId}) => {

    const baseUrl = `${API.NOTIFICATION}/${notificationId}`;

    const queryParams = {
        account_id: accountId ? accountId : ''
    }

    const url = constructURL(baseUrl, queryParams);

    const data = {
        is_read: true
    };

    return fetchData({ body: data, method: METHODS.PUT, url });
}

export const putMarkAllAsReadNotificationByAccount = ({accountId}) => {

    const queryParams = {
        account_id: accountId ? accountId : '',
    }

    const url = constructURL(API.NOTIFICATION, queryParams);

    return fetchData({ body: {}, method: METHODS.PUT, url });
}

export const putMarkAllAsReadByNotificationType = ({accountId, notificationType}) => {

    const queryParams = {
        account_id: accountId ? accountId : '',
        notification_type: notificationType ? notificationType : ''
    }

    const url = constructURL(API.NOTIFICATION, queryParams);

    return fetchData({ body: {}, method: METHODS.PUT, url });
}
