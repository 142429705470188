import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import { SearchIcon, DownArrow, DocImage, FeedbackCross, ViewIcon } from '../../../assets/svg';
import {
    popup,
    formValidator,
    getLocalStorageItem,
    validateFiles,
    getContentTypeByExtension,
    getFileExtensionFromPath,
    signedDigitalDeliveryFile,
    createDigitalDelivery,
    getAllCustomer,
    getFileSignedUrl,
} from '../../../utils';
import {
    REGEX,
    VALIDATION,
    MODAL,
    BUTTONS,
    DOCUMENT_REQUEST_FORM,
    SUCCESS,
    DIGITAL_SAFETY_LIBRARY,
    MAX_LENGTH,
    CUSTOMER_LIST,
    LOCAL_STORAGE,
} from '../../../constants';
import { useDebounce } from '../../../hooks';
import { setFileViewModalDetails, setLoaderVisibility } from '../../../redux';

const extractFileName = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
        return fileName;
    }
    return fileName.substring(0, lastDotIndex);
};

const DEFAULT_SIGNED_URL_VALUE = { preSignedUrl: '', filePath: '' };

function renameFileWithUUID(filename, uuid) {
    const lastIndex = filename.lastIndexOf('.');
    const fileExtension = filename.substring(lastIndex);
    return `${uuid}${fileExtension}`;
}

export const UploadDigitalDelivery = ({ showAddModal, setShowAddModal, debouncedApiCall }) => {
    const [customer, setCustomer] = useState([]);
    const [signedURL, setSignedURL] = useState(DEFAULT_SIGNED_URL_VALUE);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fullFileName, setFullFileName] = useState('');
    const [fileName, setFileName] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState({ id: '', name: '' });
    const [openCsmDropdown, setOpenCsmDropdown] = useState(false);
    const [searchCustomer, setSearchCustomer] = useState('');
    const [isFormFocused, setIsFormFocused] = useState(false);
    const dropdownRef = useRef(null);
    const ddInputRef = useRef(null);
    const uploaderId = getLocalStorageItem(LOCAL_STORAGE.USER_ID);
    const dispatch = useDispatch();

    const validationSchema = {
        fileName: {
            regex: REGEX.FILE_NAME,
            message: VALIDATION.FILE_NAME,
            requiredMessage: VALIDATION.FILE_NAME_REQUIRED,
        },
    };

    const handleSearchCustomer = (e) => {
        setSearchCustomer(e.target.value);
    };

    const debouncedFetchData = useDebounce(() => {
        getAllCustomer({
            offset: 1,
            limit: 10,
            search: searchCustomer || '',
        })
            .then((res) => {
                if (res?.data) setCustomer(res.data);
            })
            .catch((error) => {
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        debouncedFetchData();
    }, [searchCustomer]);

    const handleClose = (data) => {
        setOpenCsmDropdown(!openCsmDropdown);
        setSelectedCustomer({ id: data.id, name: data.name, primaryUserId: data.primaryUserId });
    };

    const validateForm = () => setIsFormFocused(true);

    const handleClickOutside = useCallback((event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpenCsmDropdown(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClear = (e) => {
        e.stopPropagation();
        setSelectedCustomer({ id: '', name: '' });
    };

    const handleRemoveFile = (indexToRemove) => {
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles.filter((_, index) => index !== indexToRemove),
        );
        setFileName('');
        setFullFileName('');
        setSignedURL(DEFAULT_SIGNED_URL_VALUE);
    };

    const onFileChange = (event, setFieldValue) => {
        try {
            const files = event.target.files;
            validateFiles(files, selectedFiles);
            const selectedFilesArray = Array.from(files);
            setFullFileName(selectedFilesArray[0]?.name);
            setFileName(extractFileName(selectedFilesArray[0]?.name));
            setFieldValue('fileName', extractFileName(selectedFilesArray[0]?.name));
            setSelectedFiles([...selectedFilesArray]);
        } catch (error) {
            popup('error', error.message);
        }
    };

    // 1.) To dynamically creates the <input> element,
    // 2.) Don't need the input to persist in the DOM but only need its functionality.
    const openFileExplorer = (setFieldValue) => {
        if (selectedFiles.length >= 2) {
            popup(SUCCESS.SUCCESS_TOAST, DOCUMENT_REQUEST_FORM.FILE.LIMIT_TEXT);
        } else {
            const input = document.createElement('input');
            input.type = 'file';
            input.multiple = true;
            input.onchange = (e) => onFileChange(e, setFieldValue);
            input.click();
        }
    };

    const handleFileNameChange = (event, setFieldValue) => {
        setFileName(event.target.value);
        setFieldValue('fileName', event.target.value);
    };

    const readFileAsArrayBuffer = async (file) => {
        try {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            return new Promise((resolve, reject) => {
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const handlePreview = async () => {
        if (selectedFiles[0]) {
            const fileType = selectedFiles[0]?.type;
            const uri = URL.createObjectURL(selectedFiles[0]);
            if (
                fileType === 'application/vnd.ms-excel' ||
                fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                fileType === 'application/msword' ||
                fileType ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ) {
                try {
                    dispatch(setLoaderVisibility(true));
                    const fileUUID = uuidV4();
                    const signedData = {
                        file_name: renameFileWithUUID(fullFileName, fileUUID),
                        account_id: selectedCustomer?.id,
                    };
                    const binaryData = await readFileAsArrayBuffer(selectedFiles[0]);

                    const {
                        data: { preSignedUrl, filePath },
                    } = await signedDigitalDeliveryFile({
                        signedData,
                    });

                    setSignedURL({ preSignedUrl, filePath });

                    const uploadResponse = await fetch(preSignedUrl, {
                        method: 'PUT',
                        body: binaryData,
                        headers: {
                            'Content-Type': fileType,
                        },
                    });
                    if (uploadResponse.status === 200) {
                        const { data } = await getFileSignedUrl({ filePath });
                        dispatch(
                            setFileViewModalDetails({
                                openFileViewerModal: true,
                                signedURL: data.url,
                                fileName,
                            }),
                        );
                    }
                } catch (error) {
                    popup('error', error.message);
                } finally {
                    dispatch(setLoaderVisibility(false));
                }
            } else {
                dispatch(
                    setFileViewModalDetails({
                        openFileViewerModal: true,
                        docs: [{ uri, fileType }],
                        fileName,
                    }),
                );
            }
        }
    };

    const createDigitalDeliveryData = async (values, filePath) => {
        const createDDData = {
            file_name: values.fileName,
            s3_key: filePath,
            account_id: selectedCustomer.id,
            primary_user_id: selectedCustomer.primaryUserId,
            uploaded_by: uploaderId,
        };
        const { message } = await createDigitalDelivery({ createDDData });
        return message;
    };

    const resetFormState = () => {
        setShowAddModal(false);
        setFileName('');
        setFullFileName('');
        debouncedApiCall();
    };

    return (
        <Formik
            initialValues={{
                fileName: fileName,
            }}
            validate={(values) => {
                const errors = {};
                formValidator({ values, errors, validationSchema });
                return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                dispatch(setLoaderVisibility(true));
                setSubmitting(true);
                try {
                    if (!selectedCustomer.primaryUserId) {
                        throw new Error('No primary user to assign the file');
                    }

                    if (!values.fileName || !fileName || !uploaderId || !selectedCustomer.id) {
                        throw new Error('Required fields are missing');
                    }

                    if (!signedURL.filePath) {
                        const fileUUID = uuidV4();
                        const signedData = {
                            file_name: renameFileWithUUID(fullFileName, fileUUID),
                            account_id: selectedCustomer?.id,
                        };
                        const binaryData = await readFileAsArrayBuffer(selectedFiles[0]);

                        const {
                            data: { preSignedUrl, filePath },
                        } = await signedDigitalDeliveryFile({
                            signedData,
                        });

                        const uploadResponse = await fetch(preSignedUrl, {
                            method: 'PUT',
                            body: binaryData,
                            headers: {
                                'Content-Type': getContentTypeByExtension(
                                    getFileExtensionFromPath(filePath),
                                ),
                            },
                        });

                        if (uploadResponse.status === 200) {
                            const message = await createDigitalDeliveryData(values, filePath);
                            popup('success', message);
                            resetFormState();
                        } else {
                            throw new Error(DOCUMENT_REQUEST_FORM.FILE.FILE_UPLOAD_FAILED);
                        }
                    } else if (signedURL.filePath) {
                        const message = await createDigitalDeliveryData(
                            values,
                            signedURL?.filePath,
                        );
                        popup('success', message);
                        resetFormState();
                    }
                } catch (error) {
                    popup('error', error.message);
                } finally {
                    setSubmitting(false);
                    dispatch(setLoaderVisibility(false));
                }
            }}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleSubmit,
                dirty,
                isSubmitting,
                setTouched,
                setFieldValue,
            }) => (
                <div
                    className={`modal custom-modal-doc-cato fade ${showAddModal ? 'show' : ''}`}
                    id='exampleModal-add'
                    tabIndex={-1}
                    aria-labelledby='exampleModalLabel'
                    aria-hidden={!showAddModal}
                    style={{ display: showAddModal ? 'block' : 'none' }}
                >
                    <div className='modal-dialog w-100 custom-wid '>
                        <div
                            className={`modal-content border-0 ${openCsmDropdown ? 'custom-wid-customer h-auto' : ''}`}
                        >
                            <div className='d-flex align-items-center justify-content-between'>
                                <span className='head-title-edit'>
                                    {DIGITAL_SAFETY_LIBRARY.MODAL.HEADING}
                                </span>
                                <button
                                    type='button'
                                    className='btn-close'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                    onClick={() => {
                                        setShowAddModal(false);
                                    }}
                                />
                            </div>
                            <div
                                className='modal-body customer-form p-0'
                                onFocus={() => validateForm()}
                            >
                                <form action='' className='customer-from-content'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='input-set-area mb-4'>
                                                <label htmlFor=''>
                                                    {CUSTOMER_LIST.PAGE_HEADING}
                                                </label>
                                                <div
                                                    className={`wrapper position-relative ${openCsmDropdown ? 'active' : ''}`}
                                                    id='wrapper'
                                                    ref={dropdownRef}
                                                >
                                                    <div
                                                        className='select-btn'
                                                        id='select-btn'
                                                        onClick={() => {
                                                            setTimeout(() => {
                                                                ddInputRef?.current?.focus();
                                                            }, 50);
                                                            setOpenCsmDropdown(!openCsmDropdown);
                                                        }}
                                                    >
                                                        <span
                                                            className={
                                                                !selectedCustomer.name
                                                                    ? 'new-customer-input'
                                                                    : 'select-option-csm'
                                                            }
                                                        >
                                                            {selectedCustomer.name
                                                                ? selectedCustomer.name
                                                                : CUSTOMER_LIST.SEARCH_BAR_PLACEHOLDER}
                                                        </span>
                                                        <div className=''>
                                                            {selectedCustomer.name ? (
                                                                <button
                                                                    type='button'
                                                                    className='btn-close w-25 me-1'
                                                                    data-bs-dismiss='modal'
                                                                    aria-label='Close'
                                                                    onClick={handleClear}
                                                                />
                                                            ) : (
                                                                <DownArrow />
                                                            )}
                                                        </div>
                                                    </div>
                                                    {openCsmDropdown && (
                                                        <div className='content position-absolute w-100 shadow-sm'>
                                                            <div className='search position-relative'>
                                                                <SearchIcon
                                                                    className={'search-icon-svg'}
                                                                    width='16'
                                                                    height='16'
                                                                />
                                                                <input
                                                                    type='search'
                                                                    name=''
                                                                    value={searchCustomer}
                                                                    onChange={handleSearchCustomer}
                                                                    placeholder='Search'
                                                                    className='input-dropdown-set'
                                                                    ref={ddInputRef}
                                                                />
                                                            </div>
                                                            <ul
                                                                className='options mb-0'
                                                                style={{ maxHeight: 120 }}
                                                            >
                                                                {customer.map((customer, index) => (
                                                                    <li
                                                                        key={index}
                                                                        onClick={() =>
                                                                            handleClose({
                                                                                id: customer.id,
                                                                                name: customer.name,
                                                                                primaryUserId:
                                                                                    customer.primary_user_id,
                                                                            })
                                                                        }
                                                                    >
                                                                        <span
                                                                            className={`${!customer.primary_user_id && 'text-danger'}`}
                                                                            title={
                                                                                !customer.primary_user_id &&
                                                                                'No primary user'
                                                                            }
                                                                        >
                                                                            {customer.name}
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {selectedFiles.length > 0 && (
                                            <div className='col-12'>
                                                <div className='input-set-area mb-4'>
                                                    <label htmlFor='fileName'>
                                                        {DIGITAL_SAFETY_LIBRARY.FILE_NAME}
                                                    </label>
                                                    <input
                                                        maxLength={MAX_LENGTH.NAME}
                                                        type='text'
                                                        name='fileName'
                                                        onChange={(e) => {
                                                            !touched.fileName &&
                                                                setTouched({
                                                                    ...touched,
                                                                    fileName: true,
                                                                });
                                                            handleFileNameChange(e, setFieldValue);
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.fileName}
                                                        className={`new-customer-input input-area-set form-control ${isFormFocused && errors.fileName && touched.fileName && errors.fileName ? 'border border-danger-subtle border-1' : ''}`}
                                                        placeholder={
                                                            DIGITAL_SAFETY_LIBRARY.FILE_NAME_PLACEHOLDER
                                                        }
                                                    />
                                                    {isFormFocused &&
                                                        touched.fileName &&
                                                        errors.fileName && (
                                                            <p className='error-message'>
                                                                {errors.fileName}
                                                            </p>
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                        <div className='d-flex align-items-center justify-content-between col-4'>
                                            {selectedFiles.length === 0 && (
                                                <button
                                                    className='ssc-primary-green-btn upload-file-btn disabled-hover '
                                                    type='button'
                                                    onClick={() => openFileExplorer(setFieldValue)}
                                                    disabled={
                                                        selectedFiles.length ===
                                                            DOCUMENT_REQUEST_FORM.FILE.FILE_LIMIT ||
                                                        !selectedCustomer?.id
                                                    }
                                                    style={{ height: '48px', fontSize: '16px' }}
                                                >
                                                    {DOCUMENT_REQUEST_FORM.FILE.UPLOAD}
                                                </button>
                                            )}

                                            {selectedFiles.length > 0 && (
                                                <div className='d-flex align-items-center file-upload position-relative'>
                                                    {selectedFiles.map((file, index) => (
                                                        <React.Fragment key={index}>
                                                            <div
                                                                key={index}
                                                                className='d-flex align-items-start flex-column position-relative'
                                                            >
                                                                <DocImage />
                                                                {file.name.length < 10 ? (
                                                                    <span
                                                                        title={file.name}
                                                                        className='mt-2'
                                                                    >
                                                                        {file.name}
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className='truncate-text mt-2'
                                                                        title={file.name}
                                                                    >
                                                                        {file.name.slice(0, 6) +
                                                                            '...' +
                                                                            file.name.split('.')[1]}
                                                                    </span>
                                                                )}
                                                                <span
                                                                    className='position-absolute'
                                                                    style={{
                                                                        bottom: '20px',
                                                                        right: '-5px',
                                                                        background: 'white',
                                                                    }}
                                                                    id='action-btn'
                                                                    onClick={handlePreview}
                                                                    title='View'
                                                                >
                                                                    <ViewIcon />
                                                                </span>
                                                            </div>
                                                            <span
                                                                className='position-absolute'
                                                                style={{
                                                                    top: '-12px',
                                                                    right: '-6px',
                                                                }}
                                                                id='action-btn'
                                                                onClick={() =>
                                                                    handleRemoveFile(index)
                                                                }
                                                                title='Remove'
                                                            >
                                                                <FeedbackCross />
                                                            </span>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='d-flex align-items-center justify-content-end modal-btn mt-3'>
                                <button
                                    type='button'
                                    className='ssc-secondary-white-btn '
                                    onClick={() => {
                                        setShowAddModal(false);
                                    }}
                                >
                                    {MODAL.CANCEL_BUTTON}
                                </button>
                                <button
                                    type='submit'
                                    onClick={handleSubmit}
                                    className='ssc-primary-green-btn py-0 w-auto disabled-hover '
                                    disabled={
                                        isSubmitting ||
                                        !dirty ||
                                        errors.fileName ||
                                        !selectedCustomer?.id
                                    }
                                >
                                    {BUTTONS.SAVE_BUTTON}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};

UploadDigitalDelivery.propTypes = {
    showAddModal: PropTypes.bool,
    setShowAddModal: PropTypes.func,
};
