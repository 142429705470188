import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDebounce } from '../../../hooks';
import { CUSTOMER_DASHBOARD } from '../../../constants';
import { setLoaderVisibility } from '../../../redux';
import { popup, getAllNewsFeed, convertTimestampToDate, handleScroll } from '../../../utils';
import { ActivityToken } from '../../../assets/svg';

export const CustomerNewsFeed = () => {
    const accountId = useSelector((state) => state.accountArray.selectedAccount);
    const dispatch = useDispatch();
    const scrollBar = useRef(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const [response, setResponse] = useState({ data: [] });
    const debouncedApiCall = useDebounce(() => {
        getAllNewsFeed({ account_id: accountId })
            .then((res) => {
                dispatch(setLoaderVisibility(false));
                if (res?.data) setResponse({ data: res.data, count: res.count });
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 500);

    const handleScrolling = () => {
        handleScroll(setIsScrolling, scrollBar, 2000);
    };

    useEffect(() => {
        dispatch(setLoaderVisibility(true));
        debouncedApiCall();
    }, [accountId]);

    return (
        <div className='h-100 w-100 activity-main ssc-shadow'>
            <h5 className='mb-4'>Activity</h5>
            <div
                onScroll={handleScrolling}
                className={`activity-list ${isScrolling ? 'scrolling' : 'not-scrolling'}`}
            >
                <div className='d-flex flex-column align-items-start justify-content-start list-container'>
                    {response?.data?.length > 0
                        ? response?.data?.map(({ id, feeds, updated_at }) => (
                              <div key={id} className='d-flex align-items-start list'>
                                  <div className='icon'>
                                      <ActivityToken />
                                  </div>
                                  <div className='text'>
                                      {feeds}
                                      <br />
                                      <div className='small fst-italic'>
                                          last updated :{' '}
                                          {updated_at
                                              ? convertTimestampToDate(updated_at).formattedDate +
                                                ' ' +
                                                convertTimestampToDate(updated_at).formattedTime
                                              : ''}
                                      </div>
                                  </div>
                              </div>
                          ))
                        : CUSTOMER_DASHBOARD.NO_NEWS_FEED}
                </div>
            </div>
        </div>
    );
};
