import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { popup, formValidator, updateDigitalDelivery } from '../../../utils';
import {
    REGEX,
    VALIDATION,
    MODAL,
    BUTTONS,
    DIGITAL_SAFETY_LIBRARY,
    MAX_LENGTH,
} from '../../../constants';
import { setLoaderVisibility } from '../../../redux';

export const EditFileNameModal = ({
    showEditModal,
    setShowEditModal,
    currentFileName,
    selectedItemId,
    debouncedApiCall,
}) => {
    const [isFormFocused, setIsFormFocused] = useState(false);
    const dispatch = useDispatch();

    const validationSchema = {
        fileName: {
            regex: REGEX.FILE_NAME,
            message: VALIDATION.FILE_NAME,
            requiredMessage: VALIDATION.FILE_NAME_REQUIRED,
        },
    };

    const validateForm = () => setIsFormFocused(true);

    return (
        <Formik
            initialValues={{
                fileName: currentFileName,
            }}
            validate={(values) => {
                const errors = {};
                formValidator({ values, errors, validationSchema });
                return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                dispatch(setLoaderVisibility(true));
                setSubmitting(true);
                try {
                    const { message } = await updateDigitalDelivery({
                        data: { file_name: values.fileName },
                        id: selectedItemId,
                    });
                    popup('success', message);
                    debouncedApiCall();
                    setShowEditModal(false);
                } catch (error) {
                    popup('error', error.message);
                } finally {
                    setSubmitting(false);
                    dispatch(setLoaderVisibility(false));
                }
            }}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleSubmit,
                dirty,
                isSubmitting,
                setTouched,
                handleChange,
            }) => (
                <div
                    className={`modal custom-modal-doc-cato fade ${showEditModal ? 'show' : ''}`}
                    id='exampleModal-add'
                    tabIndex={-1}
                    aria-labelledby='exampleModalLabel'
                    aria-hidden={!showEditModal}
                    style={{ display: showEditModal ? 'block' : 'none' }}
                >
                    <div className='modal-dialog w-100 custom-wid '>
                        <div className={'modal-content border-0'}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <span className='head-title-edit'>
                                    {DIGITAL_SAFETY_LIBRARY.MODAL.HEADING}
                                </span>
                                <button
                                    type='button'
                                    className='btn-close'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                    onClick={() => {
                                        setShowEditModal(false);
                                    }}
                                />
                            </div>
                            <div
                                className='modal-body customer-form p-0'
                                onFocus={() => validateForm()}
                            >
                                <form action='' className='customer-from-content'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='input-set-area mb-4'>
                                                <label htmlFor='fileName'>
                                                    {DIGITAL_SAFETY_LIBRARY.FILE_NAME}
                                                </label>
                                                <input
                                                    maxLength={MAX_LENGTH.ACCOUNT_NAME}
                                                    type='text'
                                                    name='fileName'
                                                    onChange={(e) => {
                                                        !touched.fileName &&
                                                            setTouched({
                                                                ...touched,
                                                                fileName: true,
                                                            });
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.fileName}
                                                    className={`new-customer-input input-area-set form-control ${isFormFocused && errors.fileName && touched.fileName && errors.fileName ? 'border border-danger-subtle border-1' : ''}`}
                                                    placeholder={
                                                        DIGITAL_SAFETY_LIBRARY.FILE_NAME_PLACEHOLDER
                                                    }
                                                />
                                                {isFormFocused &&
                                                    touched.fileName &&
                                                    errors.fileName && (
                                                        <p className='error-message'>
                                                            {errors.fileName}
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='d-flex align-items-center justify-content-end modal-btn mt-3'>
                                <button
                                    type='button'
                                    className='ssc-secondary-white-btn '
                                    onClick={() => {
                                        setShowEditModal(false);
                                    }}
                                >
                                    {MODAL.CANCEL_BUTTON}
                                </button>
                                <button
                                    type='submit'
                                    onClick={handleSubmit}
                                    className='ssc-primary-green-btn py-0 w-auto disabled-hover '
                                    disabled={isSubmitting || !dirty || errors.fileName}
                                >
                                    {BUTTONS.SAVE_BUTTON}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};

EditFileNameModal.propTypes = {
    showEditModal: PropTypes.bool,
    setShowEditModal: PropTypes.func,
};
