import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setLoaderVisibility } from '../../../redux';
import { createNewsFeed, popup, updateNewsFeed } from '../../../utils';
import { NEWS_FEED, VALIDATION, SUCCESS, MAX_LENGTH } from '../../../constants';
import { TextArea } from '../../Admin/DocumentRequest/TextArea';

const DEFAULT_FEEDS = { feeds: '' };

export const NewsFeedAddEdit = ({
    openEditModal,
    setOpenEditModal,
    data,
    isEdit,
    debouncedApiCall,
    accountId,
    order,
}) => {
    const dispatch = useDispatch();
    const [feed, setFeed] = useState(DEFAULT_FEEDS);
    const [isDisabled, setIsDisabled] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (openEditModal && isEdit && data) {
            setFeed(data);
        } else {
            setFeed(DEFAULT_FEEDS);
        }
        if (openEditModal && inputRef.current) {
            inputRef.current.focus();
        }
    }, [openEditModal, data, isEdit]);

    const handleTextarea = (value) => {
        setFeed((prevData) => ({ ...prevData, feeds: value }));
    };

    const handleClick = async () => {
        if (!feed.feeds) {
            popup('error', VALIDATION.EMPTY_FEED);
            return;
        }
        setIsDisabled(true);
        try {
            dispatch(setLoaderVisibility(true));
            if (isEdit) {
                try {
                    await updateNewsFeed({
                        id: data?.id,
                        data: { ...feed, feeds: feed?.feeds?.trim() },
                    });
                    setOpenEditModal(false);
                    debouncedApiCall();
                    popup(SUCCESS.SUCCESS_TOAST, NEWS_FEED.UPDATED_MESSAGE);
                } catch (error) {
                    setIsDisabled(false);
                    popup('error', error.message);
                    dispatch(setLoaderVisibility(false));
                }
            } else {
                try {
                    order &&
                        (await createNewsFeed({
                            data: {
                                ...feed,
                                feeds: feed?.feeds?.trim(),
                                account_id: accountId,
                                order,
                            },
                        }));
                    setOpenEditModal(false);
                    debouncedApiCall();
                    popup(SUCCESS.SUCCESS_TOAST, NEWS_FEED.CREATED_MESSAGE);
                } catch (error) {
                    setIsDisabled(false);
                    popup('error', error.message);
                    dispatch(setLoaderVisibility(false));
                }
            }
        } catch (error) {
            setIsDisabled(false);
            popup('error', error.message);
        }
        setIsDisabled(false);
    };

    return (
        <div
            className={`modal custom-modal-doc-cato fade ${openEditModal ? 'show' : ''}`}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!openEditModal}
            role='dialog'
            style={{ display: openEditModal ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='head-title-edit'>
                            {isEdit ? NEWS_FEED.EDIT : NEWS_FEED.ADD}
                        </span>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={() => setOpenEditModal(false)}
                        />
                    </div>
                    <div className='modal-body p-0'>
                        <TextArea
                            name='feeds'
                            placeholder={NEWS_FEED.PLACEHOLDER}
                            note={feed.feeds}
                            setNote={handleTextarea}
                            ref={inputRef}
                            maxLength={MAX_LENGTH.DOCUMENT_NOTES}
                            className='form-control mt-4 py-1'
                        />
                    </div>

                    <div className='d-flex align-items-center justify-content-end modal-btn'>
                        <button
                            type='button'
                            className='ssc-secondary-white-btn'
                            onClick={() => setOpenEditModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            type='button'
                            className='ssc-primary-green-btn py-0 w-auto disabled-hover'
                            onClick={handleClick}
                            disabled={isDisabled}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

NewsFeedAddEdit.propTypes = {
    openEditModal: PropTypes.bool,
    setOpenEditModal: PropTypes.func,
    data: PropTypes.object,
    isEdit: PropTypes.bool,
    debouncedApiCall: PropTypes.func,
    accountId: PropTypes.string,
    order: PropTypes.number,
};
