import React from "react";
const ActivityToken = () => {
    return (
        <svg
            width={16}
            height={16}
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle cx={8} cy={8} r='7.5' fill='#F1FBF8' stroke='#17B1A4' />
            <path
                d='M11.3332 5.33337L6.74984 10L4.6665 7.87883'
                stroke='#17B1A4'
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default ActivityToken;
