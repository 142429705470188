import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const CustomDropdown = ({
    selectedOption,
    options,
    setIsDropdownOpen,
    isDropdownOpen,
    handleOptionSelect,
}) => {
    const dropdownRef = useRef(null);
    const handleClickOutside = useCallback(
        (event) => {
            if (
                isDropdownOpen &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsDropdownOpen(false);
            }
        },
        [isDropdownOpen, setIsDropdownOpen],
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <div ref={dropdownRef} className='ms-4 customized-dropdown'>
            <div className='dropdown'>
                <select
                    className='custom-btn height-40px bg-white dropdown-toggle d-flex align-items-center justify-content-between'
                    type='button'
                    name='item-per-page'
                    id='itemPerPage'
                    value={selectedOption}
                    onChange={(e) => {
                        const selected = options.find(
                            (option) => String(option?.id) === e.target.value,
                        );
                        handleOptionSelect(selected);
                    }}
                >
                    {options.map((option, index) => (
                        <option key={index} value={option.id} className='cursor-pointer text-txt'>
                            {option.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

CustomDropdown.propTypes = {
    optionsKeyName: PropTypes.string,
    selectedOption: PropTypes.any,
    options: PropTypes.array,
    setIsDropdownOpen: PropTypes.func,
    isDropdownOpen: PropTypes.bool,
    handleOptionSelect: PropTypes.func,
};
