/* eslint-disable no-undef */
import React from 'react';
import CrytpoJS from 'crypto-js';
import { toast, Slide } from 'react-toastify';
import {
    DOCUMENT_REQUEST_FORM,
    NOT_ALLOWED_FILE_TYPES,
    ROLE_NAME,
    ROLES,
    SUBSCRIPTION_EXPIRY,
} from '../../constants';
import { fetchData } from './apiCalling';
import { getFileSignedUrl } from '../apiServices/signedAPI';

const encrypt_key = process.env.REACT_APP_ENCRYPTION_KEY;

export function encryptPassword(password) {
    return CrytpoJS.AES.encrypt(password, encrypt_key).toString();
}

// decrypt search string
export const decryptSearchString = (encryptedSearch) => {
    try {
        const bytes = CrytpoJS.AES.decrypt(decodeURIComponent(encryptedSearch), encrypt_key);
        const originalText = bytes.toString(CrytpoJS.enc.Utf8);
        return originalText; // Return original text
    } catch (error) {
        console.error('Decryption Error:', error);
        return null; // Handle decryption error
    }
};

export const formValidator = ({ values, errors, validationSchema }) => {
    Object.keys(values).forEach((key) => {
        if (validationSchema[key]?.isNotRequired && !values[key]) {
            return;
        }
        if (Array.isArray(validationSchema[key])) {
            validationSchema[key].forEach((rule) => {
                if (!values[key] && !rule.isNotRequired) {
                    errors[key] = rule.requiredMessage;
                } else if (values[key] && !rule.regex.test(values[key])) {
                    errors[key] = rule.message;
                }
            });
        } else if (!values[key] && !validationSchema[key]?.isNotRequired) {
            errors[key] = validationSchema[key].requiredMessage;
        } else if (values[key]) {
            if (validationSchema[key]?.matchWith) {
                if (values[key] !== values[validationSchema[key].matchWith]) {
                    if (validationSchema[key]?.message) errors[key] = validationSchema[key].message;
                }
            } else if (
                validationSchema[key]?.regex &&
                !validationSchema[key].regex.test(values[key])
            ) {
                if (validationSchema[key]?.message) errors[key] = validationSchema[key].message;
            }
        }
    });
};
export const popup = (
    type,
    message,
    position = 'top-right',
    style = {},
    icon = true,
    hideProgressBar = false,
    delay = 1000,
) => {
    const toast_style = {
        position,
        autoClose: 5000, // 5 seconds
        hideProgressBar,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        pauseOnFocusLoss: false,
        transition: Slide,
        style,
        icon,
        delay,
    };

    switch (type) {
        case 'success':
            return toast.success(message, toast_style);
        case 'info':
            return toast.info(message, toast_style);
        case 'error':
            return toast.error(message, toast_style);
        default:
            return toast(message, toast_style);
    }
};

export const renderWithLineBreaks = (text) => {
    return text.split(' \n ').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
};

export function getMaxOrderValue(array) {
    let maxOrderValue = Number.MIN_SAFE_INTEGER;

    if (array.length >= 1) {
        array.forEach((obj) => {
            // Compare the order value of the current object with the current maxOrderValue
            if (obj.order > maxOrderValue) {
                maxOrderValue = obj.order; // Update maxOrderValue if the current order value is higher
            }
        });
    } else maxOrderValue = 0;

    return maxOrderValue + 1;
}

export const constructURL = (baseUrl, params) => {
    let url = `${baseUrl}?`;

    Object.entries(params).forEach(([key, value]) => {
        if (value && value !== undefined && value !== null && value !== '') {
            url += `${key}=${value}&`;
        }
    });

    // Remove the trailing '&' or '?' if it exists
    url = url.endsWith('&') || url.endsWith('?') ? url.slice(0, -1) : url;

    return url;
};

export const validateFiles = (files, selectedFiles) => {
    const notAllowedFileTypes = NOT_ALLOWED_FILE_TYPES;
    const allowedFileSize = DOCUMENT_REQUEST_FORM.FILE.FILE_SIZE_LIMIT; // 5MB // changed to 10 mb as suggested
    const fileLimit = DOCUMENT_REQUEST_FORM.FILE.FILE_LIMIT;

    // Check total file limit (including selected and uploaded)
    const totalFiles = files.length + selectedFiles.length;
    if (totalFiles > fileLimit) {
        throw new Error(DOCUMENT_REQUEST_FORM.FILE.LIMIT_TEXT);
    }
    const filesArray = Array.from(files);
    filesArray.forEach((file) => {
        // Validate file size

        if (file.size > allowedFileSize) {
            throw new Error(`${DOCUMENT_REQUEST_FORM.FILE.FILE_SIZE} for file- ${file.name}`);
        }

        // Validate file type
        if (notAllowedFileTypes.includes(file.type)) {
            throw new Error(`${DOCUMENT_REQUEST_FORM.FILE.FILE_TYPE} for file- ${file.name}`);
        }

        // (if file names are unique) and  Check for duplicate filenames (already uploaded)
        if (
            selectedFiles.some(
                (uploadedFile) =>
                    uploadedFile.name === file.name && uploadedFile.size === file.size,
            )
        ) {
            throw new Error(
                `Document "${file.name}" already selected. Please choose unique files.`,
            );
        }
    });
};

export const downloadFileFromS3 = async ({ signedURL }) => {
    return await fetchData({ method: 'GET', url: signedURL });
};

export const getFileExtension = (fileName) => fileName.substr(fileName.lastIndexOf('.') + 1);

export const getContentTypeByExtension = (extension) => {
    switch (extension.toLowerCase()) {
        case 'bmp':
            return 'image/bmp';
        case 'csv':
            return 'text/csv';
        case 'doc':
            return 'application/msword';
        case 'docx':
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        case 'gif':
            return 'image/gif';
        case 'html':
            return 'text/html';
        case 'jpeg':
        case 'jpg':
            return 'image/jpeg';
        case 'mp4':
            return 'video/mp4';
        case 'pdf':
            return 'application/pdf';
        case 'png':
            return 'image/png';
        case 'ppt':
            return 'application/vnd.ms-powerpoint';
        case 'pptx':
            return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        case 'tiff':
        case 'tif':
            return 'image/tiff';
        case 'txt':
            return 'text/plain';
        case 'xls':
            return 'application/vnd.ms-excel';
        case 'xlsx':
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        default:
            return 'application/octet-stream';
    }
};

export const getFileExtensionFromPath = (filePath) => {
    const parts = filePath.split('.');
    const extension = parts[parts.length - 1];
    return extension.toLowerCase();
};

export const sortingByName = ({ sortBy, data, order }) => {
    /*Provide 0 in order to ascending and 1 for Descending*/
    data.sort((a, b) => {
        const nameA = a[sortBy]?.toLowerCase();
        const nameB = b[sortBy]?.toLowerCase();

        // Compare the names
        if (nameA < nameB) {
            return order ? 1 : -1;
        }
        if (nameA > nameB) {
            return order ? -1 : 1;
        }
        return 0;
    });

    return data;
};

// Compress image function
export const compressImage = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const MAX_WIDTH = 800;
                const MAX_HEIGHT = 600;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                canvas.toBlob(
                    (blob) => {
                        resolve(blob);
                    },
                    'image/jpeg',
                    0.7,
                );
            };
            img.onerror = (error) => {
                reject(error);
            };
        };
        reader.onerror = (error) => {
            reject(error);
        };
    });
};

export const getProfileImage = async ({ profileImg }) => {
    if (profileImg === 'undefined' || profileImg === 'null') {
        return null;
    }
    const signedUrlResponse = await getFileSignedUrl({ filePath: profileImg });
    const imageUrl = signedUrlResponse.data.url;
    const imageResponse = await fetch(imageUrl);
    if (imageResponse && imageResponse?.status !== 200) {
        return null;
    }
    const blobData = await imageResponse.blob();
    const imageData = URL.createObjectURL(blobData);
    return imageData;
};

export const createSubscriptionExpiryObject = (accounts) => {
    const subscriptionExpiry = {};

    for (const account of accounts) {
        const expiryDate = new Date(account?.subscription_expiry).getTime();
        if (expiryDate && account.id && account.subscription_expiry) {
            subscriptionExpiry[account.id] = {
                days_before_expiry: account.days_before_expiry,
                days_after_expiry: account.days_after_expiry,
                subscription_expiry: account.subscription_expiry,
                is_subscription_expiry_read: account.is_subscription_expiry_read,
            };
        }
    }

    return subscriptionExpiry;
};

export const getSubscriptionStatus = (expiryTimestamp) => {
    let title;
    let content;
    const expiryTime = new Date(expiryTimestamp);
    const currentTime = new Date();
    const timeDiff = expiryTime.getTime() - currentTime.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    if (currentTime.getTime() < expiryTime.getTime()) {
        title = SUBSCRIPTION_EXPIRY.ABOUT_TO_EXPIRE_TITLE;
        content =
            SUBSCRIPTION_EXPIRY.ABOUT_TO_EXPIRE +
            ' ' +
            Math.ceil(daysDiff) +
            ' ' +
            (Math.ceil(daysDiff) > 1 ? SUBSCRIPTION_EXPIRY.DAYS : SUBSCRIPTION_EXPIRY.DAY);
    } else if (expiryTime.getTime() < currentTime.getTime()) {
        title = SUBSCRIPTION_EXPIRY.EXPIRED_TITLE;
        content =
            SUBSCRIPTION_EXPIRY.EXPIRED +
            ' ' +
            (Math.abs(Math.ceil(daysDiff)) > 1 ? Math.abs(Math.ceil(daysDiff)) : 'a') +
            ' ' +
            (Math.abs(Math.ceil(daysDiff)) > 1
                ? SUBSCRIPTION_EXPIRY.DAYS_BEFORE
                : SUBSCRIPTION_EXPIRY.DAY_BEFORE);
    }

    return {
        title: title,
        content: content,
    };
};

export const getAccountDetailsFromAccountArrayByAccountId = (accounts, accountId) => {
    return accounts.find((account) => account.id === accountId);
};

export const handleScroll = (setIsScrolling, scrollBarRef, delay) => {
    setIsScrolling(true);
    clearTimeout(scrollBarRef.current);
    scrollBarRef.current = setTimeout(() => {
        setIsScrolling(false);
    }, delay);
};

export const getRoleFromCode = (code) => {
    switch (code) {
        case ROLES.ADMIN:
            return ROLE_NAME.ADMIN;
        case ROLES.CSM:
            return ROLE_NAME.CSM;
        case ROLES.CONTENT_MANAGER:
            return ROLE_NAME.CONTENT_MANAGER;
        default:
            return null;
    }
};
