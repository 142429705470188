import { API, METHODS } from '../../constants';
import { fetchData } from '../common/apiCalling';

export const registerAccount = ({ body }) => {
    const url = API.ACCOUNT;
    return fetchData({ body, method: METHODS.POST, url });
};

export const updateAccount = ({body,id}) => {
    const url = `${API.ACCOUNT}/${id}`;
    return fetchData({body, method: METHODS.PUT, url})
}

export const deleteAccount = ({id}) => {
    const url = `${API.ACCOUNT}/${id}`;
    return fetchData({method: METHODS.DELETE, url})
}
