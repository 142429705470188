import React from "react";

const EmptyImg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="176"
      height="138"
      fill="none"
      viewBox="0 0 176 138"
    >
      <g clipPath="url(#clip0_1297_33534)">
        <path
          fill="#FFB8B8"
          d="M32.09 16.277a2.285 2.285 0 00-1.646.995 2.255 2.255 0 00-.288 1.893l-6.58 4.675 3.922 1.5 5.647-4.669c.526-.192.962-.57 1.227-1.06a2.264 2.264 0 00-.706-2.94c-.46-.319-1.02-.459-1.577-.394z"
        ></path>
        <path
          fill="#2F2E41"
          d="M31.477 23.318l-8.161 8.165a6.783 6.783 0 01-4.169 1.942l-10.085.915A2.794 2.794 0 017 33.696a2.685 2.685 0 01-.133-4.021c.37-.352.83-.592 1.331-.694l10.303-2.18 9.38-6.876a.86.86 0 01.853.006l2.551 2.078a.835.835 0 01.423.875.813.813 0 01-.23.434z"
        ></path>
        <path
          fill="#F2F2F2"
          d="M129.33 27.706a2.715 2.715 0 00-.324-1.61 2.74 2.74 0 00-1.196-1.135L77.017.277a2.75 2.75 0 00-3.015.405c-.27.236-.49.523-.648.845v.002L54.301 40.284a2.713 2.713 0 00-.128 2.08 2.733 2.733 0 001.386 1.563l50.794 24.684a2.758 2.758 0 002.092.127 2.739 2.739 0 001.572-1.378l19.05-38.756c.14-.282.228-.585.262-.898z"
        ></path>
        <path
          fill="#fff"
          d="M99.574 113.725H33.667a3.14 3.14 0 01-2.212-.913 3.105 3.105 0 01-.917-2.199v-88.96c0-.826.33-1.617.917-2.2a3.142 3.142 0 012.212-.912h65.907c.83 0 1.625.329 2.212.912.587.583.917 1.374.918 2.2v88.96a3.104 3.104 0 01-.918 2.199 3.14 3.14 0 01-2.212.913z"
        ></path>
        <path
          fill="#E6E6E6"
          d="M51.148 45.068c2.374 0 4.3-1.914 4.3-4.276 0-2.36-1.926-4.275-4.3-4.275-2.375 0-4.3 1.914-4.3 4.275 0 2.362 1.925 4.276 4.3 4.276zM44.594 52.477c.608.344 1.244.635 1.901.871l.116.04a12.456 12.456 0 004.929.667 12.307 12.307 0 003.05-.578 12.512 12.512 0 003.633-1.871 6.194 6.194 0 00-2.534-2.535 6.235 6.235 0 00-2.957-.74h-2.263a6.253 6.253 0 00-3.366.983 6.203 6.203 0 00-2.509 3.163z"
        ></path>
        <path
          fill="#2F2E41"
          d="M52.607 53.697a.822.822 0 00-.403.14c.607-.037 1.21-.12 1.804-.249l-1.401.109z"
        ></path>
        <path
          fill="#CCC"
          d="M51.408 29.19c-2.77 0-5.46.91-7.657 2.587a12.5 12.5 0 00-4.49 6.688c-.715 2.66-.527 5.481.535 8.024a12.526 12.526 0 005.339 6.04 12.4 12.4 0 001.998.925l.118.04a12.572 12.572 0 004.974.673 12.43 12.43 0 003.076-.582 12.564 12.564 0 006.831-5.362 12.445 12.445 0 001.695-8.487 12.487 12.487 0 00-4.254-7.55 12.62 12.62 0 00-8.165-2.996zm7.296 21.87a11.945 11.945 0 01-3.61 1.892l-.214.068a11.27 11.27 0 01-.944.243c-.577.125-1.164.206-1.754.243a11.953 11.953 0 01-4.71-.637l-.11-.038a11.852 11.852 0 01-6.895-6.49 11.768 11.768 0 01-.625-7.378 11.82 11.82 0 013.908-6.302 11.95 11.95 0 0114.116-.894 11.844 11.844 0 014.68 5.758c.9 2.368 1.013 4.96.321 7.397a11.828 11.828 0 01-4.163 6.138z"
        ></path>
        <path
          fill="#CCC"
          d="M99.574 113.725H33.667a3.14 3.14 0 01-2.212-.913 3.105 3.105 0 01-.917-2.199v-88.96c0-.826.33-1.617.917-2.2a3.142 3.142 0 012.212-.912h65.907c.83 0 1.625.329 2.212.912.587.583.917 1.374.918 2.2v88.96a3.104 3.104 0 01-.918 2.199 3.14 3.14 0 01-2.212.913zM33.667 18.907c-.732 0-1.434.29-1.952.805a2.74 2.74 0 00-.81 1.94v88.961c.002.728.293 1.426.81 1.941.518.514 1.22.804 1.952.805h65.907a2.778 2.778 0 001.952-.805 2.746 2.746 0 00.81-1.941v-88.96a2.745 2.745 0 00-.81-1.941 2.774 2.774 0 00-1.952-.805H33.667z"
        ></path>
        <path
          fill="#E6E6E6"
          d="M86.815 41.645h-18.65a1.48 1.48 0 01-1.042-.43 1.463 1.463 0 010-2.074 1.48 1.48 0 011.043-.43h18.65c.39 0 .766.155 1.042.43a1.463 1.463 0 010 2.075 1.48 1.48 0 01-1.043.43zM92.901 46.596H68.166a1.48 1.48 0 01-1.043-.43 1.463 1.463 0 010-2.074 1.48 1.48 0 011.043-.43H92.9c.391 0 .767.155 1.043.43a1.463 1.463 0 010 2.075 1.48 1.48 0 01-1.043.43zM86.788 65.585H40.34a1.48 1.48 0 01-1.043-.43 1.463 1.463 0 010-2.074 1.48 1.48 0 011.043-.43h46.448c.392 0 .767.155 1.043.43a1.463 1.463 0 010 2.075 1.48 1.48 0 01-1.043.43zM92.874 70.536H40.34a1.48 1.48 0 01-1.043-.43 1.463 1.463 0 010-2.074 1.48 1.48 0 011.043-.43h52.534c.391 0 .767.155 1.043.43a1.463 1.463 0 010 2.075 1.48 1.48 0 01-1.043.43zM86.788 81.915H40.34a1.48 1.48 0 01-1.043-.43 1.463 1.463 0 010-2.074 1.48 1.48 0 011.043-.43h46.448c.392 0 .767.155 1.043.43a1.463 1.463 0 010 2.075 1.48 1.48 0 01-1.043.43zM92.874 86.867H40.34a1.48 1.48 0 01-1.043-.43 1.463 1.463 0 010-2.075 1.48 1.48 0 011.043-.43h52.534c.391 0 .767.155 1.043.43a1.463 1.463 0 010 2.075 1.48 1.48 0 01-1.043.43zM86.788 98.246H40.34a1.48 1.48 0 01-1.043-.43 1.463 1.463 0 010-2.074 1.48 1.48 0 011.043-.43h46.448c.392 0 .767.155 1.043.43a1.463 1.463 0 010 2.074 1.48 1.48 0 01-1.043.43zM92.874 103.197H40.34a1.48 1.48 0 01-1.043-.43 1.462 1.462 0 010-2.075 1.48 1.48 0 011.043-.429h52.534c.391 0 .766.155 1.042.43a1.463 1.463 0 010 2.073c-.276.275-.65.43-1.042.431z"
        ></path>
        <path
          fill="#fff"
          d="M127.067 53.133a2.715 2.715 0 00-.324-1.61 2.741 2.741 0 00-1.196-1.135L74.754 25.706a2.752 2.752 0 00-3.016.404c-.27.237-.49.524-.647.845l-.001.002L52.04 65.71a2.714 2.714 0 00-.13 2.08 2.733 2.733 0 001.387 1.563l50.794 24.684a2.757 2.757 0 002.092.128 2.736 2.736 0 001.571-1.379l19.051-38.756a2.69 2.69 0 00.262-.898z"
        ></path>
        <path
          fill="#3F3D56"
          d="M127.067 53.133a2.715 2.715 0 00-.324-1.61 2.741 2.741 0 00-1.196-1.135L74.754 25.706a2.752 2.752 0 00-3.016.404c-.27.237-.49.524-.647.845l-.001.002L52.04 65.71a2.714 2.714 0 00-.13 2.08 2.733 2.733 0 001.387 1.563l50.794 24.684a2.757 2.757 0 002.092.128 2.736 2.736 0 001.571-1.379l19.051-38.756a2.69 2.69 0 00.262-.898zm-19.602 39.513a2.415 2.415 0 01-1.387 1.217 2.433 2.433 0 01-1.846-.113L53.439 69.067a2.41 2.41 0 01-1.224-1.38 2.393 2.393 0 01.114-1.835l19.05-38.756c.282-.572.781-1.01 1.387-1.216a2.432 2.432 0 011.846.113l50.793 24.683c.576.28 1.016.777 1.224 1.38a2.397 2.397 0 01-.113 1.835l-19.051 38.755z"
        ></path>
        <path
          fill="#E6E6E6"
          d="M121.527 54.895l-13.302 27.062a.762.762 0 01-1.02.349L61.002 59.853a.757.757 0 01-.35-1.014l13.302-27.063a.759.759 0 01.732-.424c.1.007.198.032.288.076l46.203 22.453a.76.76 0 01.387.435.761.761 0 01-.036.58h-.001z"
        ></path>
        <path
          fill="#17B1A4"
          d="M80.964 53.521c3.696 0 6.691-2.978 6.691-6.653 0-3.674-2.995-6.653-6.69-6.653-3.696 0-6.692 2.979-6.692 6.653 0 3.675 2.996 6.653 6.691 6.653z"
        ></path>
        <path
          fill="#3F3D56"
          d="M90.761 74.45L61.27 60.117a.672.672 0 01-.106-.063L86.92 42.812a1.09 1.09 0 011.7.826l1.437 20.67.07.989.634 9.152z"
        ></path>
        <path
          fill="#000"
          d="M90.761 74.45l-10.069-4.894 8.452-4.736.609-.342.304-.17.07.989.634 9.152z"
          opacity="0.2"
        ></path>
        <path
          fill="#3F3D56"
          d="M107.182 82.43L81.674 70.032l8.452-4.736.609-.342 11.014-6.172c.722-.405 1.756.05 1.991.825.015.052.027.106.036.16l3.406 22.661z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M28.48 84.791h2.774l1.32-10.64H28.48v10.64z"
        ></path>
        <path
          fill="#2F2E41"
          d="M27.772 83.89h5.464a3.5 3.5 0 012.462 1.014 3.462 3.462 0 011.02 2.448v.113h-8.946V83.89z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M6.914 83.034l2.875.56 3.536-10.759-4.242-.825-2.169 11.024z"
        ></path>
        <path
          fill="#2F2E41"
          d="M6.364 81.959l5.661 1.101a3.687 3.687 0 012.345 1.547 3.644 3.644 0 01.557 2.742l-.023.116-9.268-1.802.728-3.704z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M32.516 48.086a2.26 2.26 0 00-1.274-1.435 2.288 2.288 0 00-1.925.05l-5.783-5.621-.798 4.102 5.614 4.709c.283.48.733.842 1.265 1.014a2.3 2.3 0 001.624-.077 2.282 2.282 0 001.163-1.13 2.263 2.263 0 00.114-1.612zM11.06 19.155c3.07 0 5.558-2.475 5.558-5.527S14.13 8.102 11.06 8.102 5.5 10.576 5.5 13.628c0 3.052 2.489 5.527 5.559 5.527z"
        ></path>
        <path
          fill="#2F2E41"
          d="M9.893 19.24c.295.128.9-2.212.629-2.68-.403-.695-.38-.69-.647-1.151-.267-.46-.327-1.09.02-1.496.346-.405 1.147-.351 1.305.157-.101-.965.859-1.74 1.81-1.959.95-.218 1.953-.082 2.909-.276 1.11-.225 2.264-1.15 1.821-2.363a1.707 1.707 0 00-.335-.548c-.512-.572-1.227-.816-1.927-1.052-1.455-.49-2.95-.988-4.485-.934a6.54 6.54 0 00-3.507 1.24 6.484 6.484 0 00-2.252 2.948 5.856 5.856 0 00-.245.905c-.527 2.819 1.118 5.598 3.792 6.671l1.112.538zM8.528 49.6a.846.846 0 01-.402-.836L5.618 36.41l-2.543-9.481a4.717 4.717 0 01.24-3.126 4.753 4.753 0 012.148-2.295l1.68-1.581 3.667 1.57 1.743 1.762a9.958 9.958 0 013.254 2.52 9.889 9.889 0 011.95 3.614l2.794 9.508 6.454 9.547a.845.845 0 01-.084 1.053c-.898.96-18.393.1-18.393.1z"
        ></path>
        <path
          fill="#2F2E41"
          d="M25.436 48.722l-9.516-6.557a6.728 6.728 0 01-2.654-3.74l-2.675-9.711a2.749 2.749 0 01.276-2.133c.254-.44.628-.8 1.079-1.04a2.728 2.728 0 012.88.205c.412.3.73.71.92 1.181l3.966 9.703 8.453 7.976a.851.851 0 01.143.836l-1.61 2.861a.833.833 0 01-1.262.42z"
        ></path>
        <path
          fill="#2F2E41"
          d="M5.666 81.388a.84.84 0 01-.101-.77l4.402-15.914.326-15.592 14.82-1.011.072-.005 4.464 8.044a20.612 20.612 0 012.512 8.154l1.47 16.028a.832.832 0 01-.18.606.842.842 0 01-.555.308l-4.469.423a.835.835 0 01-.923-.7L25.69 64.67l-6.53-10.968-2.19 11.547-4.541 15.947a.838.838 0 01-.783.546l-5.295.002a.826.826 0 01-.685-.357z"
        ></path>
        <path
          fill="#fff"
          d="M119.144 133.725H7.468a7.503 7.503 0 01-5.278-2.178A7.411 7.411 0 010 126.299v-60.98a7.413 7.413 0 012.19-5.248 7.498 7.498 0 015.278-2.177H21.16a7.475 7.475 0 015.023 1.93l14.849 10.542a5.263 5.263 0 003.544 1.366h75.261a7.503 7.503 0 015.32 2.214 7.393 7.393 0 012.148 5.32l-.694 47.141a7.433 7.433 0 01-2.234 5.172 7.523 7.523 0 01-5.233 2.146z"
        ></path>
        <path
          fill="#F2F2F2"
          d="M118.152 130.125H8.129a3.616 3.616 0 01-2.546-1.051 3.575 3.575 0 01-1.057-2.532V64.566c.001-.95.381-1.86 1.057-2.532a3.617 3.617 0 012.546-1.05 3.2 3.2 0 012.252.928c.598.594.934 1.4.935 2.24v58.115c0 .89.357 1.743.99 2.373a3.391 3.391 0 002.386.984H117.78a2.306 2.306 0 011.418.469c.407.31.698.746.826 1.239a2.17 2.17 0 01-.051 1.425 2.19 2.19 0 01-.923 1.091 1.737 1.737 0 01-.898.277z"
        ></path>
        <path
          fill="#17B1A4"
          d="M64.142 100.361l-.29-.288a.308.308 0 010-.45l2.542-2.53a.31.31 0 01.452 0l2.541 2.528a.311.311 0 01.008.442l-.007.008-.29.288a.316.316 0 01-.445.007l-.008-.007-1.502-1.575v3.737a.31.31 0 01-.191.288.307.307 0 01-.12.025h-.42a.314.314 0 01-.315-.313v-3.733l-1.503 1.575a.313.313 0 01-.444.006l-.008-.008z"
        ></path>
        <path
          fill="#3F3D56"
          d="M66.615 94.796c1.02 0 2.018.3 2.866.864a5.135 5.135 0 011.9 2.302 5.102 5.102 0 01-1.119 5.588 5.163 5.163 0 01-2.64 1.404c-1 .198-2.038.096-2.98-.292a5.146 5.146 0 01-2.315-1.889 5.104 5.104 0 01.643-6.474 5.177 5.177 0 013.645-1.503zm0-.675a5.86 5.86 0 00-3.242.978 5.81 5.81 0 00-2.15 2.605 5.77 5.77 0 00-.332 3.352 5.795 5.795 0 001.597 2.972 5.869 5.869 0 006.36 1.258 5.825 5.825 0 002.62-2.138 5.778 5.778 0 00-.726-7.327 5.854 5.854 0 00-4.127-1.7z"
        ></path>
        <path
          fill="#3F3D56"
          d="M119.144 133.725H7.468a7.503 7.503 0 01-5.278-2.178A7.411 7.411 0 010 126.299v-60.98a7.413 7.413 0 012.19-5.248 7.498 7.498 0 015.278-2.177H21.16a7.475 7.475 0 015.023 1.93l14.849 10.542a5.263 5.263 0 003.544 1.366h75.261a7.503 7.503 0 015.32 2.214 7.393 7.393 0 012.148 5.32l-.694 47.141a7.433 7.433 0 01-2.234 5.172 7.523 7.523 0 01-5.233 2.146zM7.468 58.344a7.044 7.044 0 00-4.958 2.045 6.963 6.963 0 00-2.057 4.93v60.98a6.964 6.964 0 002.057 4.93 7.042 7.042 0 004.958 2.045h111.676a7.062 7.062 0 004.916-2.015 6.988 6.988 0 002.099-4.858l.693-47.141a6.94 6.94 0 00-2.018-4.999 7.03 7.03 0 00-4.997-2.079H44.576a5.716 5.716 0 01-3.839-1.474L25.888 60.166a7.022 7.022 0 00-4.728-1.822H7.468z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M128.608 134.912h-2.774l-.868-10.641h3.643l-.001 10.641z"
        ></path>
        <path
          fill="#2F2E41"
          d="M129.316 137.586h-8.946v-.113a3.451 3.451 0 011.019-2.448 3.504 3.504 0 012.463-1.014h5.464v3.575z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M159.612 134.912h-2.774l-1.32-10.641h4.095l-.001 10.641z"
        ></path>
        <path
          fill="#2F2E41"
          d="M160.32 137.586h-8.946v-.113a3.451 3.451 0 011.02-2.448 3.496 3.496 0 012.462-1.014h5.464v3.575z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M150.49 102.018a2.535 2.535 0 00.066-3.218 2.065 2.065 0 00-.243-.246l2.09-7.671-3.483 1.149-1.45 7.108a2.543 2.543 0 00.23 2.802 1.789 1.789 0 002.155.55 1.79 1.79 0 00.635-.474z"
        ></path>
        <path
          fill="#17B1A4"
          d="M150.355 97.299a.889.889 0 01-.13-.01l-1.815-.272a.988.988 0 01-.682-.47 1.254 1.254 0 01-.159-.871l2.264-12.575-1.535-8.078c-.296-1.555.468-3.125 1.777-3.652a2.439 2.439 0 012.374.302 3.207 3.207 0 011.272 2.297l1.087 10.3-.013.045-3.498 12.207c-.05.214-.169.406-.339.546s-.382.221-.603.23z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M149.82 66.919c3.069 0 5.558-2.475 5.558-5.527s-2.489-5.527-5.558-5.527c-3.07 0-5.559 2.475-5.559 5.527s2.489 5.527 5.559 5.527z"
        ></path>
        <path
          fill="#17B1A4"
          d="M146.974 87.082c-.139 0-.276-.025-.405-.075l-8.786-3.36a1.124 1.124 0 01-.715-1.199l.353-2.63a5.923 5.923 0 01.056-2.826 5.954 5.954 0 011.358-2.483l.214-.234 2.599-4.713a2.763 2.763 0 011.667-1.325 2.786 2.786 0 012.12.243l4.59 2.55c.604.336 1.06.884 1.278 1.537a8.373 8.373 0 01-.129 5.67l-3.143 8.124a1.144 1.144 0 01-1.057.721z"
        ></path>
        <path
          fill="#2F2E41"
          d="M123.95 132.299l-.012-.002a1.14 1.14 0 01-.736-.494 1.118 1.118 0 01-.152-.87l6.733-29.261a78.616 78.616 0 014.549-13.534l2.864-6.493 10.688 4.851 1.526 2.775a27.03 27.03 0 011.805 9.681l10.86 31.645a1.122 1.122 0 01-.433 1.293 1.14 1.14 0 01-.452.18l-5.945-.067a1.122 1.122 0 01-1.216-.691l-12.414-31.994a.228.228 0 00-.217-.14.218.218 0 00-.205.148l-11.779 32.154c-.09.25-.267.46-.498.592a1.137 1.137 0 01-.766.13l-4.2.097z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M132.077 96.315a2.276 2.276 0 001.371-1.344 2.249 2.249 0 00-.157-1.909l5.324-6.053-4.164-.564-4.417 5.835a2.263 2.263 0 00-.781 2.92c.251.497.678.886 1.199 1.091.52.206 1.099.214 1.625.024z"
        ></path>
        <path
          fill="#17B1A4"
          d="M134.587 92.051a1.14 1.14 0 01-.532-.132l-1.873-.993a1.14 1.14 0 01-.562-.706 1.131 1.131 0 01.139-.89l6.919-10.84 1.2-8.181a3.152 3.152 0 011.306-2.12 3.182 3.182 0 012.444-.524 3.188 3.188 0 012.068 1.396 3.15 3.15 0 01.411 2.453l-2.478 10.083-8.161 10.037a1.122 1.122 0 01-.881.417z"
        ></path>
        <path
          fill="#2F2E41"
          d="M144.817 56.832c.643-2.096 4.357-3.032 7.445-2.162 3.087.87 5.306 3.15 5.822 5.38 1.143 4.938-4.821 8.945-3.74 13.883.715 3.267 4.851 6.355 9.51 7.101-5.134 1.069-11.4.13-16.161-2.42-2.963-1.588-5.449-3.983-5.411-6.399.022-1.463.958-2.756 2.103-3.874 1.144-1.117 2.514-2.11 3.565-3.277 1.05-1.167 1.778-2.563 1.471-4.044-.306-1.481-1.864-3.025-3.959-3.456l-.645-.732z"
        ></path>
        <path
          fill="#CCC"
          d="M175.774 138H89.55a.228.228 0 01-.226-.225c0-.06.024-.117.066-.159a.228.228 0 01.16-.066h86.224a.225.225 0 110 .45z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1297_33534">
          <path fill="#fff" d="M0 0H176V138H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default EmptyImg;
