import { API, METHODS } from '../../constants';
import { fetchData } from '../common/apiCalling';
import { constructURL } from '../common';

const getAllScoreCardDetails = ({ account_id, offset, limit, search, selectedPlatform, grade, list }) => {

    const queryParams = {
        account_id: account_id ? account_id : '',
        offset: offset ? offset : '',
        limit: limit ? limit : '',
        search: search ? encodeURIComponent(search) : '',
        platform_id: Object.keys(selectedPlatform).length ? selectedPlatform?.id : '',
        grade: grade ? grade : '',
        list: list ? list : ''
    };

    const url = constructURL(API.SCORECARD, queryParams);

    return fetchData({ method: METHODS.GET, url });
};

const createScoreCardDetails = ({ data }) => {
    const url = API.SCORECARD;
    return fetchData({ body: data, method: METHODS.POST, url });
};

const updateScoreCardDetails = ({ updatedData }) => {
    const {id, ...data } = updatedData;
    let url = `${API.SCORECARD}/${id}`;

    return fetchData({ body: data, method: METHODS.PUT, url });
};

export const deleteScorecardByAccountAndPlatform = ({accountId, platformId}) => {
    const url = API.SCORECARD;
    const data = {
        account_id: accountId,
        platform_id: platformId
    };

    return fetchData({ body: data, method: METHODS.DELETE, url});
}

const deleteScoreCardDetails = ({ id }) => {
    let url = `${API.SCORECARD}/${id}`;
    return fetchData({ method: METHODS.DELETE, url });
};

export { getAllScoreCardDetails, createScoreCardDetails, updateScoreCardDetails, deleteScoreCardDetails };