import React from 'react';
import PropTypes from 'prop-types';

const Toggle = ({ checked = false, title = '', onChange = () => {}, className = '', ...props }) => {
    const handleChange = (event) => {
        onChange(event.target.checked, event); // Pass the new state and event object
    };
    return (
        <label className={`switch ${className}`} title={title} {...props} onClick={props?.onClick}>
            <input
                type='checkbox'
                checked={checked}
                onChange={handleChange}
                aria-label={title || 'Toggle'}
            />
            <span className='slider round'></span>
        </label>
    );
};

Toggle.propTypes = {
    checked: PropTypes.bool,
    title: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default Toggle;
