import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSM, EDIT_CUSTOMER as EDIT, INFO } from '../../../constants';
import { getAllCSM, popup, updateAccount } from '../../../utils';
import { InputBoxWithSearch } from '../DocumentRequest/InputBoxWithSearch';
import { Edit } from '../../../assets/svg';
import { isEmpty } from 'lodash';

export const ManageCSMBox = ({ data, debouncedApiCall }) => {
    const [response, setResponse] = useState({ data: [], count: 0 });
    const [search, setSearch] = useState('');
    const [selectData, setSelectData] = useState({});
    const [edit, setEdit] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    useEffect(() => {
        getAllCSM({
            limit: 10,
            offset: 1,
            search: search,
        })
            .then((res) => {
                if (res?.data) setResponse({ data: res.data, count: res.count });
            })
            .catch((error) => {
                popup('error', error.message);
            });
    }, [search]);

    const handleSave = () => {
        setIsDisabled(true);
        updateAccount({
            body: {
                name: data.account?.name,
                phone: data.account?.phone?.replaceAll('-', ''),
                email: data.account?.email,
                csm_id: selectData?.id,
            },
            id: data.account?.account_id,
        })
            .then((response) => {
                popup('success', response.message);
                debouncedApiCall();
                setEdit((prev) => !prev);
            })
            .catch((error) => {
                popup('error', error.message);
            });
    };

    const handleEdit = () => {
        setEdit(true);
    };

    const handleDropdownToggle = (id) => {
        setOpenDropdown((prev) => (prev === id ? null : id));
    };

    const handleDiscard = () => {
        setEdit(false);
        setOpenDropdown(0);
        setSelectData({});
    };

    useEffect(() => {
        if (!isEmpty(selectData)) {
            setIsDisabled(false);
        }
    }, [selectData]);

    return (
        <>
            <div className='tabs-set h-25'>
                <div className='d-flex justify-content-end '>
                    <div>
                        {!edit && (
                            <span title='Edit' id='action-btn' onClick={handleEdit}>
                                <Edit className='cursor-pointer' />
                            </span>
                        )}
                    </div>
                </div>
                {!data.account.csm_name && !edit && (
                    <div
                        className='mt-4 vh-50 d-flex justify-content-center'
                        style={{ fontSize: '16px' }}
                    >
                        {INFO.NO_CSM_FOUND}
                    </div>
                )}
                <div className='form-area mt-4'>
                    {edit ? (
                        <div>
                            <InputBoxWithSearch
                                label={CSM.PAGE_NAME}
                                placeholder={
                                    data.account.csm_name ? data.account.csm_name : 'Select CSM'
                                }
                                dropdownData={response.data}
                                setSearchData={setSearch}
                                setSelectedData={setSelectData}
                                selectedData={selectData}
                                className={'w-25'}
                                isOpen={openDropdown === 1}
                                onToggle={() => handleDropdownToggle(1)}
                            />

                            <div className='d-flex align-items-center justify-content-end mb-1'>
                                <button
                                    onClick={handleDiscard}
                                    className={`form-white-button d-flex align-items-center justify-content-center border-0 cursor-pointer me-3`}
                                >
                                    {EDIT.BUTTON_TEXT.CANCEL}
                                </button>
                                <button
                                    onClick={handleSave}
                                    className='form-button d-flex align-items-center justify-content-center border-0'
                                    disabled={isDisabled}
                                >
                                    {EDIT.BUTTON_TEXT.SAVE}
                                </button>
                            </div>
                        </div>
                    ) : (
                        data.account.csm_name && (
                            <div className='d-flex  border-bottom justify-content-start ps-5 '>
                                <div className='d-flex ps-4 flex-column mb-3 input-set '>
                                    <label> {'CSM'}</label>
                                    <span>{data?.account?.csm_name}</span>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </>
    );
};
ManageCSMBox.propTypes = {
    data: PropTypes.object,
    debouncedApiCall: PropTypes.func,
};
