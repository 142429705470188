import React from 'react';
const GreenArrow = () => (
    <svg
        width={16}
        height={16}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M5.66675 3.33341L10.3334 8.00008L5.66675 12.6667'
            stroke='#17B1A4'
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
export default GreenArrow;
