import  React from "react";
const IconHover1 = (props) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.58171 10.2905L8.58171 2.26318"
      stroke="#E2F1F7"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5254 8.33887L8.58139 10.2909L6.63739 8.33887"
      stroke="#E2F1F7"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.67 5.41846H12.292C13.6487 5.41846 14.748 6.51779 14.748 7.87512L14.748 11.1311C14.748 12.4845 13.6514 13.5811 12.298 13.5811L4.87138 13.5811C3.51471 13.5811 2.41471 12.4811 2.41471 11.1245L2.41471 7.86779C2.41471 6.51512 3.51205 5.41846 4.86471 5.41846H5.49271"
      stroke="#E2F1F7"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconHover1;
