import React from 'react';
import PropTypes from 'prop-types';

export const ButtonComponent = ({
    className,
    children,
    text = '',
    type = 'button',
    id = '',
    disabled,
    clickHandler = () => {},
    bootstrapButton: BootstrapButton,
    ariaControls,
    ariaExpanded
}) => {
    return (
        <>
            {BootstrapButton ? (
                <BootstrapButton
                    className={className}
                    onClick={() => clickHandler(!ariaExpanded)}
                    type={type}
                    {...(id && { 'id': id })}
                    aria-expanded={ariaExpanded}
                    aria-controls={ariaControls}
                >
                    {text ? text : children}
                </BootstrapButton>
            ) : (
                <button
                    className={className}
                    onClick={clickHandler}
                    type={type}
                    id={id}
                    disabled={disabled}
                >
                    {text ? text : children}
                </button>
            )}
        </>
    );
}

ButtonComponent.propTypes = {
    className: PropTypes.string.isRequired,
    text: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    clickHandler: PropTypes.func,
    bootstrapButton: PropTypes.elementType,
    ariaControls: PropTypes.string,
    ariaExpanded: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool
};

