import { createSlice } from '@reduxjs/toolkit';
import { REDUX } from '../../constants';

const isSubscriptionExpiryReadSlice = createSlice({
    name: REDUX.slice.IS_SUBSCRIPTION_EXPIRY_READ_SLICE,
    initialState: {
        accountStates: {}
    },
    reducers: {
        setIsSubscriptionExpiryReadObj: (state, action) => {
            const { accountId, value } = action.payload;
            state.accountStates[accountId] = value;
        },
    },
});

export const { setIsSubscriptionExpiryReadObj } = isSubscriptionExpiryReadSlice.actions;
export default isSubscriptionExpiryReadSlice.reducer;
