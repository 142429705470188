import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setLoaderVisibility } from '../../../redux';
import {
    changeDocumentCategoryStatus,
    createDocumentCategory,
    editDocumentCategory,
    popup,
} from '../../../utils';
import { DOCUMENT_CATEGORY, REGEX, VALIDATION, SUCCESS } from '../../../constants';
import { ButtonComponent } from '../Buttons/ButtonComponent';
import { ConfirmationBox } from '../common/ConfirmationBox';

export const DocumentCategoryAddEdit = ({
    openEditModal,
    setOpenEditModal,
    data,
    isEdit,
    debouncedApiCall,
}) => {
    const dispatch = useDispatch();
    const [category, setCategory] = useState({ name: '' });
    const [validationError, setValidationError] = useState('');
    const inputRef = useRef(null);

    const [confirm, setConfirm] = useState(false);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);

    useEffect(() => {
        if (openEditModal && isEdit && data) {
            setCategory(data);
        } else {
            setCategory({ name: '' });
        }
        if (openEditModal && inputRef.current) {
            inputRef.current.focus();
        }
    }, [openEditModal, isEdit]);

    const isDocumentCategoryValid = (categoryName) => {
        if (categoryName) {
            if (categoryName.length > 100)
                setValidationError(VALIDATION.MAXIMUM_DOC_CATEGORY_LENGTH);
            else if (categoryName.length < 3)
                setValidationError(VALIDATION.MINIMUM_DOC_CATEGORY_LENGTH);
            else if (!REGEX.DOCUMENT_CATEGORY.test(category.name))
                setValidationError(VALIDATION.INVALID_CATEGORY_NAME);
            else setValidationError('');
        } else {
            setValidationError(VALIDATION.EMPTY_CATEGORY);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        isDocumentCategoryValid(value);
        setCategory((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleClick = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            const dataObj = {
                name: category?.name?.trim(),
            };
            if (isEdit) {
                try {
                    await editDocumentCategory({ id: data?.id, data: dataObj });
                    popup(SUCCESS.SUCCESS_TOAST, DOCUMENT_CATEGORY.UPDATED_MESSAGE);
                    setOpenEditModal(false);
                    debouncedApiCall();
                } catch (error) {
                    popup('error', error.message);
                }
            } else {
                try {
                    await createDocumentCategory({ data: dataObj });
                    setOpenEditModal(false);
                    debouncedApiCall();
                    popup(SUCCESS.SUCCESS_TOAST, DOCUMENT_CATEGORY.CREATED_MESSAGE);
                } catch (error) {
                    if (error.message === 'Document category already exists') {
                        setOpenConfirmationBox(true);
                    }
                }
            }
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    };

    useEffect(() => {
        async function handleChangeDocCategoryStatus() {
            const response = await changeDocumentCategoryStatus({
                name: category?.name,
                status: true,
            });
            debouncedApiCall();
            popup(SUCCESS.SUCCESS_TOAST, response?.message);
        }
        if (confirm) {
            handleChangeDocCategoryStatus();
            setOpenEditModal(false);
            debouncedApiCall();
        }
    }, [confirm]);

    return (
        <div
            className={`modal custom-modal-doc-cato fade ${openEditModal ? 'show' : ''}`}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!openEditModal}
            role='dialog'
            style={{ display: openEditModal ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='head-title-edit'>
                            {isEdit ? DOCUMENT_CATEGORY.EDIT_BUTTON : DOCUMENT_CATEGORY.BUTTON_TEXT}
                        </span>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={() => setOpenEditModal(false)}
                        />
                    </div>
                    <div className='modal-body p-0'>
                        <input
                            type='text'
                            name='name'
                            value={category?.name}
                            className={`form-control input-area mt-4 ${validationError ? 'border border-danger-subtle border-1 mb-1' : ''}`}
                            ref={inputRef}
                            onChange={handleChange}
                            placeholder={DOCUMENT_CATEGORY.INPUT_PLACEHOLDER}
                            min={3}
                        />
                    </div>
                    {validationError && <p className='error-message mb-3'>{validationError}</p>}

                    <div className='d-flex align-items-center justify-content-end modal-btn'>
                        <ButtonComponent
                            type='button'
                            className='ssc-secondary-white-btn'
                            clickHandler={() => setOpenEditModal(false)}
                        >
                            Cancel
                        </ButtonComponent>
                        <ButtonComponent
                            type='submit'
                            className='ssc-primary-green-btn py-0 w-auto disabled-hover '
                            clickHandler={handleClick}
                            disabled={validationError || !category.name}
                        >
                            Save
                        </ButtonComponent>
                    </div>
                </div>
            </div>
            <ConfirmationBox
                setConfirm={setConfirm}
                openConfirmationBox={openConfirmationBox}
                setOpenConfirmationBox={setOpenConfirmationBox}
                customMessage='Do you want to re-active Document category?'
            />
        </div>
    );
};

DocumentCategoryAddEdit.propTypes = {
    openEditModal: PropTypes.bool,
    setOpenEditModal: PropTypes.func,
    data: PropTypes.object,
    isEdit: PropTypes.bool,
    debouncedApiCall: PropTypes.func,
};
