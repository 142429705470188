import React, { useEffect, useState } from 'react';
import { ButtonComponent } from '../Buttons/ButtonComponent';
import { SCORECARD_EMAIL_LOGS_COLUMNS, SCORECARD_REPORT } from '../../../constants';
import { useDebounce, usePagination } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { getAllScoreCardEmailLogs } from '../../../utils/apiServices/scorecardEmailLogAPI';
import { Table } from '../Table';

const EmailReportModal = ({ showModal, setShowModal, accountId }) => {
    const [reportData, setReportData] = useState(undefined);
    const dispatch = useDispatch();
    const pagination = usePagination(reportData?.data?.length, reportData?.count);

    const getEmailReportsByAccountId = useDebounce(async () => {
        try {
            // dispatch(setLoaderVisibility(true));
            const response = await getAllScoreCardEmailLogs({
                offset: pagination.offset,
                limit: pagination.limit,
                account_id: accountId,
            });
            setReportData({ data: response.data, count: response.count });
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    }, 1000);

    //initial stat

    useEffect(() => {
        dispatch(setLoaderVisibility(true));
        getEmailReportsByAccountId();
    }, [accountId, pagination.limit, pagination.offset]);

    return (
        <div
            className={`modal custom-modal-doc-cato fade ${showModal ? 'show' : ''}`}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!showModal}
            role='dialog'
            style={{ display: showModal ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100' style={{ minWidth: '620px' }}>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='head-title-edit'>
                            {SCORECARD_REPORT.SCORE_CARD_EMAIL_RECORDS.HEADER_HEADING}
                        </span>
                        <ButtonComponent
                            clickHandler={() => setShowModal(false)}
                            className='btn-close'
                        ></ButtonComponent>
                    </div>
                    <div className='modal-body p-0 pt-4'>
                        {!!reportData && (
                            <Table
                                columns={SCORECARD_EMAIL_LOGS_COLUMNS}
                                data={reportData?.data}
                                pagination={pagination}
                                pageName={SCORECARD_REPORT.SCORE_CARD_EMAIL_RECORDS.HEADER_HEADING}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailReportModal;
