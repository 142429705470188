import React from 'react';
const BlueArrow = ({width, height}) => (
    <svg
        width={width || 16}
        height={height || 16}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      
    >
        <path
            d='M5.66675 3.33366L10.3334 8.00033L5.66675 12.667'
            stroke='#738DA6'
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
export default  BlueArrow;
