import React, { useState } from 'react';
import { Edit, Delete } from '../../../assets/svg';
import { SCORECARD_REPORT } from '../../../constants';
import PropTypes from 'prop-types';
import EditScorecardModal from './ScoreCardModal';
import ScoreCardDeleteModal from './ScoreCardDeleteModal';

export const ScoreCardOperation = ({ data, debouncedApiCall }) => {
    const [openEditModal, setOpenEditModal] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [selectedData, setSelected] = useState([]);
    const handleDeleteModalClick = (data) => {
        setSelected(data);
        setIsDeleteModal(true);
    };
    const handleEditClick = (data) => {
        setSelected(data);
        setOpenEditModal(true);
    };

    return (
        <>
            <div className='d-flex align-items-center justify-content-start options-set'>
                <button
                    className='border-0 p-0 bg-white'
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal-edit'
                    onClick={() => handleEditClick(data)}
                    id='action-btn'
                >
                    <Edit />
                </button>

                <button
                    className='border-0 p-0 bg-white'
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal-del'
                    onClick={() => handleDeleteModalClick(data)}
                    id='action-btn'
                >
                    <Delete />
                </button>
            </div>
            {openEditModal && (
                <EditScorecardModal
                    openEditModal={openEditModal}
                    setOpenEditModal={setOpenEditModal}
                    selectedData={selectedData}
                    setSelected={setSelected}
                    isEdit={true}
                    debouncedApiCall={debouncedApiCall}
                    accountDetails={{
                        id: data.account_id,
                    }}
                />
            )}
            {isDeleteModal && (
                <ScoreCardDeleteModal
                    isDeleteScorecardByAccountAndPlatform={false}
                    isDeleteModal={isDeleteModal}
                    setIsDeleteModal={setIsDeleteModal}
                    selectedData={selectedData}
                    deleteButtonName={SCORECARD_REPORT.DELETE_BUTTON}
                    debouncedApiCall={debouncedApiCall}
                />
            )}
        </>
    );
};

ScoreCardOperation.propTypes = {
    data: PropTypes.object.isRequired,
    debouncedApiCall: PropTypes.func,
};
