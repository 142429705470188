import React from "react";

const UserCircle = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 57 56"
    >
      <rect width="55" height="55" x="1" y="0.5" fill="#fff" rx="27.5"></rect>
      <rect
        width="55"
        height="55"
        x="1"
        y="0.5"
        stroke="#E2F1F7"
        rx="27.5"
      ></rect>
      <path
        stroke="#738DA6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M28.485 31.346c-3.868 0-7.17.584-7.17 2.926s3.281 2.948 7.17 2.948c3.867 0 7.17-.586 7.17-2.927 0-2.34-3.282-2.947-7.17-2.947z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#738DA6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.429"
        d="M28.485 28.006a4.596 4.596 0 10-4.596-4.596 4.58 4.58 0 004.563 4.596h.033z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default UserCircle;
