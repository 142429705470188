import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SearchIcon } from '../../../assets/svg';
import { setLoaderVisibility } from '../../../redux';
import { useDebounce } from '../../../hooks';
import { popup, updatePlatform } from '../../../utils';
import { ERROR, ROUTES, SUCCESS, MODAL } from '../../../constants';
import { GradeSelectModal } from '../../shared';

const ScoreCardAdminHeader = ({ accountUserDetails, searchFn, searchValue, isOpenGradeSelectModal, setOpenGradeSelectModal }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [accountDetails] = useState(accountUserDetails);

    const debounceGradeUpdate = useDebounce((item) => {
        let data = { platform_grade: item, account_id: accountDetails?.id};

        updatePlatform({
            id: accountDetails?.platform_id.toString(),
            data: data,
        })
            .then((res) => {
                dispatch(setLoaderVisibility(false));
                if (res.status === 'error') {
                    if (res.statusCode === ERROR.INTERNAL_SERVER_ERROR) {
                        navigate(ROUTES.SERVER_ERROR);
                    }
                    popup('error', res.message);
                } else {
                    popup('success', SUCCESS.SCORE_CARD_COLUMNS_ADMIN.GRADE_UPDATED);
                }
            })
            .catch((error) => {
                if (error.status === 'error' && error.statusCode === ERROR.INTERNAL_SERVER_ERROR) {
                    navigate(ROUTES.SERVER_ERROR);
                }
                dispatch(setLoaderVisibility(false));
            });
    }, 300);

    const handleGradeSelect = (item) => {
        dispatch(setLoaderVisibility(true));
        debounceGradeUpdate(item);
    };

    return (
        <>
            {isOpenGradeSelectModal && 
                <GradeSelectModal 
                    isGradeChangeModal={isOpenGradeSelectModal}
                    platform=''
                    handleUpdateClick={handleGradeSelect}
                    setGradeChangeConfirmationModalVisibility={setOpenGradeSelectModal}
                    heading={MODAL.SELECT_GRADE}
                />
            }
            <div className='ssc-scorecard-box'>
                <div className='p-3 name-box-set'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center justify-content-start'>
                            <div className='ssc-search-box'>
                                <div className='search-box d-flex align-items-center justify-content-start min-width-480'>
                                    <button className='bg-white p-0 border-0 d-flex align-items-center'>
                                        <SearchIcon />
                                    </button>
                                    <input
                                        onChange={searchFn}
                                        type='text'
                                        maxLength={40}
                                        value={searchValue}
                                        className='search-area border-0 w-100 py-1'
                                        placeholder='Search for Hiring Client'
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="upper-user-text mb-0 d-flex align-items-center justify-content-between gap-3">
                                <p className='font-16px mb-0'>{accountDetails?.name}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ScoreCardAdminHeader;

ScoreCardAdminHeader.propTypes = {
    accountUserDetails: PropTypes.object,
    searchFn: PropTypes.func,
    searchValue: PropTypes.string,
    isOpenGradeSelectModal: PropTypes.bool,
    setOpenGradeSelectModal: PropTypes.func
}
