import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setLoaderVisibility } from '../../../redux';
import { NewsFeedAddEdit, DeleteConfirmationModal } from '../../shared';
import { Edit, Delete } from '../../../assets/svg';
import { popup, deleteNewsFeed } from '../../../utils';
import { NEWS_FEED } from '../../../constants';

export const NewsFeedOperation = ({ data, debouncedApiCall }) => {
    const dispatch = useDispatch();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const handleDeleteModalClick = () => {
        setIsDeleteModal(true);
    };

    const handleDeleteClick = async ({ selectedItemId, setIsDeleteModal }) => {
        setIsDisabled(true);
        dispatch(setLoaderVisibility(true));
        deleteNewsFeed({ id: selectedItemId })
            .then((res) => {
                if (res.status === 'success') {
                    debouncedApiCall();
                    popup('error', NEWS_FEED.DELETED_MESSAGE);
                    setIsDeleteModal(false);
                }
            })
            .catch((error) => {
                popup('error', error.message);
                dispatch(setLoaderVisibility(false));
            });
        setIsDisabled(false);
    };
    return (
        <>
            <div className='d-flex gap-2 align-items-center justify-content-start options-set'>
                <button
                    className='border-0 p-0 bg-white'
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal-edit'
                    onClick={() => setOpenEditModal(true)}
                    id='action-btn'
                    title='Edit'
                >
                    <Edit />
                </button>

                <button
                    className='border-0 p-0 bg-white'
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal-del'
                    onClick={() => handleDeleteModalClick(data?.id)}
                    id='action-btn'
                    title='Delete'
                >
                    <Delete />
                </button>
            </div>
            {openEditModal && (
                <NewsFeedAddEdit
                    openEditModal={openEditModal}
                    setOpenEditModal={setOpenEditModal}
                    data={data}
                    isEdit={true}
                    debouncedApiCall={debouncedApiCall}
                />
            )}
            {isDeleteModal && (
                <DeleteConfirmationModal
                    isDeleteModal={isDeleteModal}
                    setIsDeleteModal={setIsDeleteModal}
                    selectedItemId={data?.id}
                    handleDeleteClick={handleDeleteClick}
                    isDisabled={isDisabled}
                    deleteButtonName={NEWS_FEED.TITLE}
                />
            )}
        </>
    );
};

NewsFeedOperation.propTypes = {
    data: PropTypes.object.isRequired,
    debouncedApiCall: PropTypes.func,
};
