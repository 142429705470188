import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { formValidator, popup, updateAccount } from '../../../utils';
import { AUTH, REGEX, VALIDATION, MAX_LENGTH, EDIT_CUSTOMER as EDIT } from '../../../constants';
import { Edit } from '../../../assets/svg';
import { setLoaderVisibility } from '../../../redux';
import { formatPhoneToUSFormat } from '../../../utils/common/formatHelpers';

export const PersonalInfoBox = ({ data, debouncedApiCall }) => {
    const dispatch = useDispatch();
    const [isFormFocused, setIsFormFocused] = useState(false);
    const [edit, setEdit] = useState(false);

    const handleEdit = () => {
        setEdit((prev) => !prev);
    };

    const handleDiscard = (resetForm) => {
        resetForm();
        setEdit(false);
    };

    const validationSchema = {
        customerName: {
            regex: REGEX.ALL_LANG_NAME,
            message: VALIDATION.CUSTOMER_NAME,
            requiredMessage: VALIDATION.CUSTOMER_NAME_REQUIRED,
        },
        phone: {
            regex: REGEX.PHONE,
            message: VALIDATION.INVALID_PHONE,
            isNotRequired: true,
        },
        email: {
            regex: REGEX.EMAIL,
            message: VALIDATION.INVALID_EMAIL,
            isNotRequired: true,
        },
    };

    const validateForm = () => setIsFormFocused(true);

    return (
        <>
            <div className='d-flex justify-content-end '>
                <div>
                    {!edit && (
                        <span title='Edit' id='action-btn' onClick={handleEdit}>
                            <Edit className='cursor-pointer' />
                        </span>
                    )}
                </div>
            </div>
            <div className='tabs-set'>
                <div className='form-area mt-4'>
                    {edit ? (
                        <Formik
                            initialValues={{
                                customerName: data.account?.name || '',
                                phone: data.account?.phone?.replace(/-/g, '') || '',
                                email: data.account?.email || '',
                            }}
                            validate={(values) => {
                                const errors = {};
                                formValidator({ values, errors, validationSchema });
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                dispatch(setLoaderVisibility(true));
                                updateAccount({
                                    body: {
                                        name: values.customerName,
                                        phone: values.phone,
                                        email: values.email,
                                    },
                                    id: data.account?.account_id,
                                })
                                    .then((response) => {
                                        setSubmitting(false);
                                        popup('success', response.message);
                                        setEdit(false);
                                        debouncedApiCall();
                                        dispatch(setLoaderVisibility(false));
                                    })
                                    .catch((error) => {
                                        setSubmitting(false);
                                        popup('error', error.message);
                                        dispatch(setLoaderVisibility(false));
                                    });
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                dirty,
                                isSubmitting,
                                setTouched,
                                resetForm,
                            }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    className='d-flex justify-content-between flex-column'
                                >
                                    <div
                                        onFocus={validateForm}
                                        className='d-flex justify-content-between align-items-center'
                                    >
                                        <div className='mb-1 input-set w-100'>
                                            <label htmlFor='name'> {EDIT.FORM_FIELD.NAME}</label>
                                            <input
                                                maxLength={MAX_LENGTH.NAME}
                                                type='text'
                                                name='customerName'
                                                onChange={(e) => {
                                                    !touched.customerName &&
                                                        setTouched({
                                                            ...touched,
                                                            customerName: true,
                                                        });
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                                value={values.customerName}
                                                className={`input-area-set form-control ${isFormFocused && errors.customerName && touched.customerName && errors.customerName ? 'border border-danger-subtle border-1' : ''}`}
                                                placeholder={AUTH.ENTER_CUSTOMER_NAME}
                                            />
                                            {isFormFocused &&
                                                touched.customerName &&
                                                errors.customerName && (
                                                    <p className='error-message'>
                                                        {errors.customerName}
                                                    </p>
                                                )}
                                        </div>

                                        <div className='mb-1 input-set w-100 mx-3'>
                                            <label htmlFor='emailAddress'>
                                                {EDIT.FORM_FIELD.EMAIL}
                                            </label>
                                            <input
                                                maxLength={MAX_LENGTH.EMAIL}
                                                type='email'
                                                name='email'
                                                onChange={(e) => {
                                                    !touched.email &&
                                                        setTouched({
                                                            ...touched,
                                                            email: true,
                                                        });
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                                value={values.email}
                                                className={`input-area-set form-control ${isFormFocused && errors.email && touched.email && errors.email ? 'border border-danger-subtle border-1' : ''}`}
                                                placeholder={AUTH.ENTER_EMAIL_ADDRESS}
                                            />
                                            {isFormFocused && touched.email && errors.email && (
                                                <p className='error-message'>{errors.email}</p>
                                            )}
                                        </div>

                                        <div className='mb-1 input-set w-100'>
                                            <label htmlFor='phoneNumber'>
                                                {EDIT.FORM_FIELD.PHONE}
                                            </label>
                                            <input
                                                maxLength={MAX_LENGTH.PHONE}
                                                type='text'
                                                name='phone'
                                                onChange={(e) => {
                                                    !touched.phone &&
                                                        setTouched({
                                                            ...touched,
                                                            phone: true,
                                                        });
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                                value={values.phone}
                                                className={`input-area-set form-control ${isFormFocused && errors.phone && touched.phone && errors.phone ? 'border border-danger-subtle border-1' : ''}`}
                                                placeholder='phone Number'
                                            />
                                            {isFormFocused && touched.phone && errors.phone && (
                                                <p className='error-message'>{errors.phone}</p>
                                            )}
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center justify-content-end mb-1'>
                                        <button
                                            type='button'
                                            className={`form-white-button d-flex align-items-center justify-content-center border-0 cursor-pointer me-3`}
                                            onClick={() => handleDiscard(resetForm)}
                                        >
                                            {EDIT.BUTTON_TEXT.CANCEL}
                                        </button>
                                        <button
                                            type='submit'
                                            disabled={isSubmitting || !dirty}
                                            className='ssc-primary-green-btn button-green-filled form-button d-flex align-items-center justify-content-center border-0 cursor-pointer'
                                        >
                                            {EDIT.BUTTON_TEXT.SAVE}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    ) : (
                        <div className='d-flex  border-bottom justify-content-around '>
                            <div className='d-flex flex-column mb-3 input-set '>
                                <label> {EDIT.FORM_FIELD.NAME}</label>
                                <span>{data?.account?.name}</span>
                            </div>
                            <div className='d-flex flex-column mb-3 input-set w-25'>
                                <label> {EDIT.FORM_FIELD.EMAIL}</label>
                                <span>{data?.account?.email ? data?.account?.email : '-'}</span>
                            </div>
                            <div className='d-flex flex-column mb-3 input-set w-25'>
                                <label> {EDIT.FORM_FIELD.PHONE}</label>
                                <span>
                                    {data?.account?.phone
                                        ? formatPhoneToUSFormat(data?.account?.phone)
                                        : '-'}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

PersonalInfoBox.propTypes = {
    data: PropTypes.object,
    debouncedApiCall: PropTypes.func,
};
