import React from 'react';
import PropTypes from 'prop-types';
import { DashboardContent } from '../Dashboard/DashboardContent';
import { CsmListing } from '../CsmListing';
import { CustomerListing } from '../CustomerListing';
import { ROUTES } from '../../../constants';
import { DocumentCategoryListing } from '../DocumentCategoryListing';
import { AdminListing } from '../AdminListing';
import { DocumentListing } from '../DocumentListing';
import { DocumentRequestView } from '../DocumentRequest/DocumentRequestView';
import { ScoreCardReportAdmin } from '../ScoreCardReportAdmin';
import { NewsFeed } from '../NewsFeed';
import { Notification } from '../../shared';
import { AdminScoreCardListing } from '../ScoreCardReportAdmin/AdminScoreCardListing';
import { EditProfile } from '../../shared';
import { RegulatoryIssue } from '../RegulatoryIssues';
import { EditCustomer } from '../EditCustomer';
import { CSMHiringClientOrder } from '../EditCustomer/CSMHiringClientOrder';
import { DigitalSafetyLibrary } from '../DigitalSafetyLibrary';
import LegendPage from '../../shared/common/LegendPage';

const RenderDashboard = (selectedTab) => {
    switch (selectedTab) {
        case ROUTES.ADMIN.DASHBOARD:
            return <DashboardContent />;
        case ROUTES.ADMIN.DOCUMENT_CATEGORY:
            return <DocumentCategoryListing />;
        case ROUTES.ADMIN.DOCUMENTS:
            return <DocumentListing />;
        case ROUTES.ADMIN.ADMIN:
            return <AdminListing />;
        case ROUTES.ADMIN.CSM:
            return <CsmListing />;
        case ROUTES.ADMIN.CUSTOMER:
            return <CustomerListing />;
        case ROUTES.ADMIN.CUSTOMER_EDIT:
            return <EditCustomer />;
        case ROUTES.ADMIN.DOCUMENT_REQUEST:
            return <DocumentRequestView />;
        case ROUTES.ADMIN.ADMIN_SCORE_CARD_LISTING:
            return <AdminScoreCardListing />;
        case ROUTES.ADMIN.SCORE_CARD_REPORT_ADMIN:
            return <ScoreCardReportAdmin />;
        case ROUTES.ADMIN.NEWS_FEED_ADMIN:
            return <NewsFeed />;
        case ROUTES.ADMIN.NOTIFICATION:
            return <Notification />;
        case ROUTES.ADMIN.EDIT_PROFILE:
            return <EditProfile />;
        case ROUTES.ADMIN.ADMIN_REGULATORY_ISSUE:
            return <RegulatoryIssue />;
        case ROUTES.ADMIN.CUSTOMER_HIRING_CLIENT_ORDER:
            return <CSMHiringClientOrder />;
        case ROUTES.ADMIN.DIGITAL_SAFETY_LIBRARY:
            return <DigitalSafetyLibrary />;
        case ROUTES.ADMIN.LEGEND:
            return <LegendPage />;
        default:
            return <DashboardContent />;
    }
};
export const ContentBox = ({ currentRoute }) => {
    return <div className='col-lg-10 ssc-main-content'>{RenderDashboard(currentRoute)}</div>;
};

ContentBox.propTypes = {
    currentRoute: PropTypes.string.isRequired,
};
