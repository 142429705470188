import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ButtonComponent } from './ButtonComponent';
import { GreenArrow } from '../../../assets/svg';

export const ViewAll = ({ className = '', navigateTo = '' }) => {
    const navigate = useNavigate();
    const handleNavigate = () => navigate(navigateTo);

    return (
        <ButtonComponent className={className} clickHandler={handleNavigate}>
            <span>View All</span>
            <GreenArrow />
        </ButtonComponent>
    );
};

ViewAll.propTypes = {
    className: PropTypes.string,
    navigateTo: PropTypes.string,
};
