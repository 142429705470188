import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setLoaderVisibility } from '../../../redux';
import { SCORECARD_REPORT, BUTTONS, SUCCESS, ERROR, MAX_LENGTH, INFO } from '../../../constants';
import { Arrow, SearchIcon } from '../../../assets/svg';
import { popup, sendReport, updatePlatform } from '../../../utils';
import {
    handleHiringClientDropdownFn,
    handleHiringClientSelectFn,
    handleNotesChangeFn,
    handleSaveFn,
    handleSearchChangeFn,
    getHiringClientsById,
} from './ScoreCardsFunctions';
import { ButtonComponent } from '../../shared';
import { useDebounce } from '../../../hooks';

const EditScorecardModal = ({
    accountDetails,
    openEditModal,
    setOpenEditModal,
    selectedData,
    isEdit,
    debouncedApiCall,
    selectedPlatform,
    scorecards,
}) => {
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [accountId, setAccountId] = useState('');
    const [isHiringClientOpen, setIsHiringClientOpen] = useState(false);
    const [selectedHiringClient, setSelectedHiringClient] = useState(null);
    const [notes, setNotes] = useState('');
    const [hiringClients, setHiringClients] = useState([]);
    const [selectedHiringClientGrade, setSelectedHiringClientGrade] = useState(null);

    const handleHiringClientDropdown = handleHiringClientDropdownFn(
        setSearchQuery,
        setIsHiringClientOpen,
    );
    const handleHiringClientSelect = handleHiringClientSelectFn(
        setSearchQuery,
        setSelectedHiringClient,
        setIsHiringClientOpen,
    );
    const handleNotes = handleNotesChangeFn(setNotes);
    const handleSearchChange = handleSearchChangeFn(setSearchQuery);
    const handleHiringClientApi = async () =>
        await getHiringClientsById({
            account_id: accountId || selectedData.account_id,
            platform_id: selectedPlatform.id,
            setHiringClients,
            searchQuery,
        });

    const handleSave = async () => {
        if (selectedHiringClient?.id) {
            const { success, type } = await handleSaveFn({
                isEdit,
                selectedHiringClient,
                selectedPlatform,
                notes,
                selectedData,
                setOpenEditModal,
                debouncedApiCall,
                accountId,
                scorecards,
                selectedHiringClientGrade,
            });
            if (success) {
                dispatch(setLoaderVisibility(true));
                setOpenEditModal(false);

                if (!isEdit && !scorecards.length) {
                    const data = {
                        platform_grade: accountDetails?.grade,
                        account_id: accountDetails?.id,
                    };
                    await updatePlatform({
                        id: accountDetails?.platform_id.toString(),
                        data: data,
                    });
                }
                await debouncedApiCall();
                // Handle report sending with error management
                try {
                    const reportResponse = await sendReport({ id: accountDetails?.id });
                    if (reportResponse.status === 'success') {
                        setTimeout(() => popup('success', INFO.SEND_REPORT_CSM), 1000);
                    }
                } catch (error) {
                    popup('error', error.message || INFO.NO_PRIMARY_USER);
                }

                setTimeout(() => {
                    type === 'create'
                        ? popup('success', SUCCESS.SCORE_CARD_COLUMNS_ADMIN.CREATED)
                        : popup('success', SUCCESS.SCORE_CARD_COLUMNS_ADMIN.UPDATED);
                }, 500);
            }
        } else {
            const err_message = !selectedHiringClient?.id
                ? ERROR.SELECT_HIRING_CLIENT
                : ERROR.UNEXPECTED_ERROR;
            popup('error', err_message);
        }
    };

    const debouncedCall = useDebounce(handleHiringClientApi, 500);

    useEffect(() => {
        if (selectedData) {
            const { hiring_client_id, hiring_client_name, notes, hc_grade } = selectedData;
            setSelectedHiringClient({ name: hiring_client_name, id: hiring_client_id });
            setSelectedHiringClientGrade(hc_grade);
            setNotes(notes);
        }
    }, [selectedData]);

    useEffect(() => {
        setAccountId(accountDetails?.id);
        isHiringClientOpen && !isEdit && debouncedCall();
    }, [isHiringClientOpen, searchQuery]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsHiringClientOpen(false);
        }
    };

    const handleClear = (e) => {
        e.stopPropagation();
        setSelectedHiringClient('');
    };

    return (
        <div
            className={`modal custom-modal-doc-cato fade ${openEditModal ? 'show' : ''}`}
            tabIndex={-1}
            aria-labelledby='exampleModalLabel'
            aria-hidden={!openEditModal}
            role='dialog'
            style={{ display: openEditModal ? 'block' : 'none' }}
        >
            <div className='modal-dialog w-100'>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='head-title-edit'>
                            {isEdit ? SCORECARD_REPORT.EDIT_BUTTON : SCORECARD_REPORT.BUTTON_TEXT}
                        </span>
                        <ButtonComponent
                            clickHandler={() => setOpenEditModal(false)}
                            className='btn-close'
                        ></ButtonComponent>
                    </div>
                    <div className='modal-body p-0 mt-32 mb-32 ssc-scorecard-form'>
                        <div className='mb-4 d-flex align-items-start flex-column'>
                            <label htmlFor=''>{SCORECARD_REPORT.COLUMNS.HIRING_CLIENT.NAME}</label>

                            <div
                                ref={dropdownRef}
                                className={`wrapper position-relative${isHiringClientOpen && !isEdit ? ' active' : ''}`}
                                id='wrapper'
                            >
                                <div
                                    onClick={handleHiringClientDropdown}
                                    className={`select-btn ${isEdit ? 'field-disabled cursor-not-allowed' : ''}`}
                                    id='select-btn'
                                    disabled={isEdit}
                                >
                                    {selectedHiringClient ? (
                                        <span style={{ color: 'var(--col-primary)' }}>
                                            {' '}
                                            {selectedHiringClient?.name}{' '}
                                        </span>
                                    ) : (
                                        <span>
                                            {SCORECARD_REPORT.COLUMNS.HIRING_CLIENT.PLACEHOLDER}{' '}
                                        </span>
                                    )}
                                    {!isEdit && (
                                        <div className=''>
                                            {selectedHiringClient ? (
                                                <button
                                                    type='button'
                                                    className='btn-close w-25 me-1'
                                                    data-bs-dismiss='modal'
                                                    aria-label='Close'
                                                    onClick={handleClear}
                                                />
                                            ) : (
                                                <Arrow />
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className='content position-absolute w-100'>
                                    <div className='search position-relative'>
                                        <div className='search-icon-svg'>
                                            <SearchIcon width={13} height={13} />
                                        </div>
                                        <input
                                            onChange={handleSearchChange}
                                            type='search'
                                            name=''
                                            placeholder='Search'
                                            value={searchQuery}
                                            id=''
                                            className='input-dropdown-set'
                                        />
                                    </div>
                                    <ul className='options mb-0'>
                                        {hiringClients?.map((item, index) => (
                                            <li
                                                key={index}
                                                onClick={() => handleHiringClientSelect(item)}
                                            >
                                                {item.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='mb-4 d-flex align-items-start flex-column'>
                            <label htmlFor=''>{SCORECARD_REPORT.COLUMNS.NOTES.NAME}</label>
                            <textarea
                                onChange={handleNotes}
                                value={notes || ''}
                                name=''
                                id=''
                                cols='20'
                                rows='3'
                                className='add-newsfeed-text w-100'
                                placeholder={SCORECARD_REPORT.COLUMNS.NOTES.PLACEHOLDER}
                            ></textarea>
                        </div>
                        <div className='mb-4 d-flex align-items-start flex-column'>
                            <label htmlFor=''>{SCORECARD_REPORT.COLUMNS.HIRING_CLIENT_GRADE}</label>
                            <input
                                maxLength={MAX_LENGTH.HIRING_CLIENT_GRADE}
                                type='text'
                                name='grade'
                                onChange={(e) => setSelectedHiringClientGrade(e.target.value)}
                                value={selectedHiringClientGrade || ''}
                                className={`add-grade-hc-text w-100`}
                                placeholder={SCORECARD_REPORT.COLUMNS.GRADE.PLACEHOLDER}
                            />
                        </div>
                    </div>

                    <div className='d-flex align-items-center justify-content-end modal-btn'>
                        <ButtonComponent
                            clickHandler={() => setOpenEditModal(false)}
                            className='ssc-secondary-white-btn'
                        >
                            {BUTTONS.CANCEL_BUTTON}
                        </ButtonComponent>

                        <ButtonComponent
                            clickHandler={handleSave}
                            className='ssc-primary-green-btn py-0 w-auto'
                        >
                            {BUTTONS.SAVE_BUTTON}
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditScorecardModal;
EditScorecardModal.propTypes = {
    accountDetails: PropTypes.object,
    openEditModal: PropTypes.bool,
    setOpenEditModal: PropTypes.func,
    selectedItemId: PropTypes.string,
    isEdit: PropTypes.bool,
    setResponse: PropTypes.func,
    debouncedApiCall: PropTypes.func,
    selectedData: PropTypes.object,
    selectedPlatform: PropTypes.object,
    scorecards: PropTypes.array,
};
